export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date or Empty/Nil string is allowed */
  DateOrEmptyString: any;
  /** A properly formatted email address */
  Email: string;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** Represents untyped JSON */
  JSON: object | object[] | string | number | boolean | null;
  /** An object's URL key */
  Key: string;
  /** Non-fractional, signed, whole numeric values representing 1 meter in the range -(2^31) and 2^31 - 1. */
  Meters: any;
  /** Non-fractional, signed, whole numeric values representing 1 minute of time in the range -(2^31) and 2^31 - 1. */
  Minutes: any;
  /** An Int with a value >= 0 */
  NonNegativeInt: number;
  /** An Int with a value > 0 */
  PositiveInt: number;
  /** A rating between 0 and 5 */
  Rating: any;
  /** A valid IANA timezone name */
  Timezone: string;
  /** A nil value */
  Void: void;
};

/** Account */
export type Account = {
  __typename: 'Account';
  active: Scalars['Boolean'];
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  addresses: Array<Address>;
  billingWeek: Maybe<BillingWeekEnum>;
  canDisable: Maybe<Scalars['Boolean']>;
  city: Maybe<Scalars['String']>;
  comments: Array<Comment>;
  consolidateUnprocessedCharges: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentPaymentMethod: Maybe<Scalars['String']>;
  customer: Customer;
  cutOff: Maybe<InvoiceCutOffEnum>;
  default: Scalars['Boolean'];
  defaultContact: CustomerAdmin;
  groupBy: Maybe<InvoiceGroupByEnum>;
  id: Scalars['ID'];
  markupPercent: Maybe<Scalars['Float']>;
  monthlyCutOff: Scalars['Boolean'];
  name: Scalars['String'];
  orders: OrderPage;
  paymentMethodInfoType: Maybe<PaymentMethodInfo>;
  phoneNumber: Maybe<Scalars['String']>;
  rateType: AccountRateTypeEnum;
  rates: Maybe<Array<Rate>>;
  region: Maybe<Region>;
  state: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
  zip: Maybe<Scalars['String']>;
};

/** Account */
export type AccountOrdersArgs = {
  filters: Maybe<OrderFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Autogenerated return type of AccountAttachBankAccount */
export type AccountAttachBankAccountPayload = {
  __typename: 'AccountAttachBankAccountPayload';
  attach: Scalars['Boolean'];
  bankAccount: Maybe<StripeBankAccount>;
};

/** Autogenerated return type of AccountAttachCard */
export type AccountAttachCardPayload = {
  __typename: 'AccountAttachCardPayload';
  attach: Scalars['Boolean'];
};

/** Autogenerated return type of AccountCreate */
export type AccountCreatePayload = {
  __typename: 'AccountCreatePayload';
  account: Account;
};

/** Autogenerated return type of AccountCutOffUpdate */
export type AccountCutOffUpdatePayload = {
  __typename: 'AccountCutOffUpdatePayload';
  account: Account;
};

/** Autogenerated return type of AccountDetachPaymentMethod */
export type AccountDetachPaymentMethodPayload = {
  __typename: 'AccountDetachPaymentMethodPayload';
  detach: Scalars['Boolean'];
};

/** Available filter operations for a Account */
export type AccountFilterSetInput = {
  active?: Maybe<BooleanEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  customers?: Maybe<IdMembershipFilterInput>;
  defaultContacts?: Maybe<IdMembershipFilterInput>;
  name?: Maybe<TextSearchFilterInput>;
  phoneNumber?: Maybe<TextSearchFilterInput>;
  rateType?: Maybe<Array<AccountRateTypeEnum>>;
};

export enum AccountRateTypeEnum {
  MARKUP = 'MARKUP',
  STATIC = 'STATIC',
}

/** Autogenerated return type of AccountSetCurrentPaymentMethod */
export type AccountSetCurrentPaymentMethodPayload = {
  __typename: 'AccountSetCurrentPaymentMethodPayload';
  transaction: Scalars['Boolean'];
};

/** Autogenerated return type of AccountUpdateActive */
export type AccountUpdateActivePayload = {
  __typename: 'AccountUpdateActivePayload';
  account: Account;
};

/** Autogenerated return type of AccountUpdate */
export type AccountUpdatePayload = {
  __typename: 'AccountUpdatePayload';
  account: Account;
};

/** Active Users By Skill */
export type ActiveUsersBySkillReport = {
  __typename: 'ActiveUsersBySkillReport';
  city: Scalars['String'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  jobsWorked: Scalars['Int'];
  lastName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  skill: Scalars['String'];
  state: Scalars['String'];
};

/** Address */
export type Address = {
  __typename: 'Address';
  account: Maybe<Account>;
  active: Maybe<Scalars['Boolean']>;
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  coords: Point;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  jobsCount: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  rateQuote: Maybe<Rate>;
  rateQuotes: Maybe<Array<Rate>>;
  region: Region;
  state: Maybe<Scalars['String']>;
  timezone: Scalars['Timezone'];
  updatedAt: Scalars['ISO8601DateTime'];
  zip: Maybe<Scalars['String']>;
};

/** Address */
export type AddressRateQuoteArgs = {
  selectNearbyRate?: Maybe<Scalars['Boolean']>;
  skillId: Scalars['ID'];
};

/** Address */
export type AddressRateQuotesArgs = {
  selectNearbyRate?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of AddressCreate */
export type AddressCreatePayload = {
  __typename: 'AddressCreatePayload';
  address: Address;
};

/** Autogenerated return type of AddressDelete */
export type AddressDeletePayload = {
  __typename: 'AddressDeletePayload';
  success: Scalars['Boolean'];
};

/** Pagination container for Address */
export type AddressPage = {
  __typename: 'AddressPage';
  items: Array<Address>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of AddressUpdate */
export type AddressUpdatePayload = {
  __typename: 'AddressUpdatePayload';
  address: Address;
};

/** Autogenerated return type of AddressValidate */
export type AddressValidatePayload = {
  __typename: 'AddressValidatePayload';
  address: Address;
};

/** Autogenerated return type of AdminChangePaymentMethod */
export type AdminChangePaymentMethodPayload = {
  __typename: 'AdminChangePaymentMethodPayload';
  account: Account;
};

/** Autogenerated return type of AdminEditNumberWorkersJob */
export type AdminEditNumberWorkersJobPayload = {
  __typename: 'AdminEditNumberWorkersJobPayload';
  job: Job;
};

/** Autogenerated return type of AdminEditRateOfJob */
export type AdminEditRateOfJobPayload = {
  __typename: 'AdminEditRateOfJobPayload';
  job: Job;
};

/** Autogenerated return type of AdminEditTimeOfJob */
export type AdminEditTimeOfJobPayload = {
  __typename: 'AdminEditTimeOfJobPayload';
  job: Job;
};

/** Autogenerated return type of AdminLogin */
export type AdminLoginPayload = {
  __typename: 'AdminLoginPayload';
  accessToken: Scalars['String'];
  customerAdmin: Maybe<CustomerAdmin>;
  disabled: Maybe<Scalars['Boolean']>;
  refreshToken: Scalars['String'];
  resetPassword: Scalars['Boolean'];
  tenantAdmin: Maybe<TenantAdmin>;
};

/** Autogenerated return type of AdminMarkWorkerAsShowed */
export type AdminMarkWorkerAsShowedPayload = {
  __typename: 'AdminMarkWorkerAsShowedPayload';
  jobWorker: JobWorker;
};

/** Agency */
export type Agency = {
  __typename: 'Agency';
  address: Maybe<Address>;
  badges: Array<Badge>;
  candidates: WorkerPage;
  certificates: Array<Certificate>;
  chatUid: Scalars['String'];
  /** Length of time before a shift begins that workers are allowed to check in */
  checkinWindow: Scalars['Minutes'];
  createdAt: Scalars['ISO8601DateTime'];
  customerAdmins: CustomerAdminPage;
  customers: CustomerPage;
  email: Scalars['String'];
  id: Scalars['ID'];
  jobs: JobPage;
  key: Scalars['Key'];
  locationDesc: Maybe<Scalars['String']>;
  logoUrl: Maybe<Scalars['String']>;
  /** Distance to the job location a worker must be within to check in */
  maxCheckinDistance: Scalars['Meters'];
  name: Scalars['String'];
  orders: OrderPage;
  orientationSites: Array<OrientationSite>;
  orientations: Array<Orientation>;
  phoneNumber: Scalars['String'];
  /** Length of lead time that must be given between placing a job and its first shift */
  requestLeadtime: Scalars['Minutes'];
  serviceAreas: Array<ServiceArea>;
  shiftsBetweenDates: Array<Shift>;
  shiftsByDay: Array<Shift>;
  shiftsByWeek: Array<Shift>;
  skillCategories: Array<SkillCategory>;
  tags: TagPage;
  tenant: Tenant;
  uniforms: Array<Uniform>;
  updatedAt: Scalars['ISO8601DateTime'];
  workers: WorkerPage;
};

/** Agency */
export type AgencyCandidatesArgs = {
  filters: Maybe<CandidateFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Agency */
export type AgencyCustomerAdminsArgs = {
  filters: Maybe<CustomerAdminFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Agency */
export type AgencyCustomersArgs = {
  filters: Maybe<CustomerFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
  reverse?: Maybe<Scalars['Boolean']>;
  sortKey?: Maybe<CustomerSortKeyEnum>;
};

/** Agency */
export type AgencyJobsArgs = {
  filters: Maybe<JobFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Agency */
export type AgencyOrdersArgs = {
  filters: Maybe<OrderFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
  reverse?: Maybe<Scalars['Boolean']>;
  sortKey?: Maybe<OrderSortKeyEnum>;
};

/** Agency */
export type AgencyShiftsBetweenDatesArgs = {
  endDate: Scalars['ISO8601Date'];
  filters: Maybe<JobFilterSetInput>;
  startDate: Scalars['ISO8601Date'];
  timeZone?: Maybe<Scalars['String']>;
};

/** Agency */
export type AgencyShiftsByDayArgs = {
  filters: Maybe<JobFilterSetInput>;
  numberOfDays?: Maybe<Scalars['Int']>;
  startDate: Scalars['ISO8601Date'];
  timeZone?: Maybe<Scalars['String']>;
};

/** Agency */
export type AgencyShiftsByWeekArgs = {
  filters: Maybe<JobFilterSetInput>;
  startDate: Scalars['ISO8601Date'];
  timeZone?: Maybe<Scalars['String']>;
};

/** Agency */
export type AgencyTagsArgs = {
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
  query: Maybe<Scalars['String']>;
};

/** Agency */
export type AgencyWorkersArgs = {
  filters: Maybe<WorkerFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Autogenerated return type of AgencyCreate */
export type AgencyCreatePayload = {
  __typename: 'AgencyCreatePayload';
  agency: Agency;
};

/** Autogenerated return type of AgencyUpdate */
export type AgencyUpdatePayload = {
  __typename: 'AgencyUpdatePayload';
  agency: Agency;
};

/** Assessment */
export type Assessment = {
  __typename: 'Assessment';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  options: Scalars['String'];
  optionsEs: Maybe<Scalars['String']>;
  question: Scalars['String'];
  questionEs: Maybe<Scalars['String']>;
  time: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum BackgroundStatusEnum {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  IN_PROGRESS = 'IN_PROGRESS',
  NA = 'NA',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  REQUESTED = 'REQUESTED',
}

/** Badge */
export type Badge = {
  __typename: 'Badge';
  category: BadgeCategoryEnum;
  createdAt: Scalars['ISO8601DateTime'];
  description: Maybe<Scalars['String']>;
  iconUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  points: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum BadgeCategoryEnum {
  CERTIFICATION = 'CERTIFICATION',
  EVENT = 'EVENT',
  SKILL = 'SKILL',
}

/** Autogenerated return type of BadgeCreate */
export type BadgeCreatePayload = {
  __typename: 'BadgeCreatePayload';
  badge: Badge;
};

/** Autogenerated return type of BadgeableAddBadge */
export type BadgeableAddBadgePayload = {
  __typename: 'BadgeableAddBadgePayload';
  tags: Array<Badge>;
};

/** Autogenerated return type of BadgeableChangeBadges */
export type BadgeableChangeBadgesPayload = {
  __typename: 'BadgeableChangeBadgesPayload';
  badges: Array<Badge>;
};

export enum BadgeableEnum {
  WORKER = 'WORKER',
}

/** Autogenerated return type of BadgeableRemoveBadge */
export type BadgeableRemoveBadgePayload = {
  __typename: 'BadgeableRemoveBadgePayload';
  badges: Array<Badge>;
};

export enum BillingWeekEnum {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

/** Tests field for equality to a given value */
export type BooleanEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: Scalars['Boolean'];
};

export type BulkEditTimesheetsInput = {
  reportedBreakMinutes?: Maybe<Scalars['NonNegativeInt']>;
  reportedCheckinAt?: Maybe<Scalars['ISO8601DateTime']>;
  reportedCheckoutAt?: Maybe<Scalars['ISO8601DateTime']>;
  tipAmount?: Maybe<Scalars['NonNegativeInt']>;
};

/** Actors that may cancel a job */
export type CancelUserUnion = CustomerAdmin | TenantAdmin;

/** Candidate */
export type Candidate = {
  __typename: 'Candidate';
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  agency: Maybe<Agency>;
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  backgroundStatus: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  coords: Maybe<Point>;
  createdAt: Scalars['ISO8601DateTime'];
  deniedAt: Maybe<Scalars['ISO8601DateTime']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  gender: Maybe<Scalars['String']>;
  heardFrom: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Maybe<Scalars['Int']>;
  lastName: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  quizCompletedAt: Maybe<Scalars['ISO8601DateTime']>;
  references: Array<CandidateReference>;
  skill: Maybe<Skill>;
  state: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Maybe<Worker>;
  zip: Maybe<Scalars['String']>;
};

/** Available filter operations for a candidate Worker */
export type CandidateFilterSetInput = {
  active?: Maybe<BooleanEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  ownsCar?: Maybe<BooleanEqualityFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  status?: Maybe<CandidateStatusEqualityFilterInput>;
  statusGroup?: Maybe<Array<CandidateStatusGroupingFilterInput>>;
};

/** Candidate reference */
export type CandidateReference = {
  __typename: 'CandidateReference';
  candidate: Maybe<Candidate>;
  city: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  duration: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  employerName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum CandidateStatusEnum {
  APPLIED = 'APPLIED',
  AWAITING_HR = 'AWAITING_HR',
  COMPLETED = 'COMPLETED',
  INTERVIEWING = 'INTERVIEWING',
  ONBOARDED = 'ONBOARDED',
  REJECTED = 'REJECTED',
  START_HIRE = 'START_HIRE',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

/** Tests field for equality to a given CandidateStatus */
export type CandidateStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: CandidateStatusEnum;
};

/** filter fields for grouping to some CandidateStatus */
export type CandidateStatusGroupingFilterInput = {
  value: CandidateStatusEnum;
};

/** Certificate */
export type Certificate = {
  __typename: 'Certificate';
  agency: Agency;
  createdAt: Scalars['ISO8601DateTime'];
  descriptionEn: Maybe<Scalars['String']>;
  descriptionEs: Maybe<Scalars['String']>;
  displayNameEn: Maybe<Scalars['String']>;
  displayNameEs: Maybe<Scalars['String']>;
  gravyTrained: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of ChatGroupSupportCreate */
export type ChatGroupSupportCreatePayload = {
  __typename: 'ChatGroupSupportCreatePayload';
  created: Scalars['Boolean'];
  supportChatGid: Scalars['String'];
};

/** Comment */
export type Comment = {
  __typename: 'Comment';
  actor: CommentableActorUnion;
  body: Maybe<Scalars['String']>;
  commentable: CommentableUnion;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Actors that may post comments */
export type CommentableActorUnion = CustomerAdmin | TenantAdmin;

/** Autogenerated return type of CommentableAddComment */
export type CommentableAddCommentPayload = {
  __typename: 'CommentableAddCommentPayload';
  comments: Array<Comment>;
};

export enum CommentableEnum {
  ACCOUNT = 'ACCOUNT',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  JOB = 'JOB',
  ORDER = 'ORDER',
  WORKER = 'WORKER',
}

/** Types that may be commented on */
export type CommentableUnion =
  | Account
  | Customer
  | CustomerAdmin
  | Job
  | Order
  | Worker;

/** Represents a operator to compare two objects of the same type */
export enum CompareOperatorEnum {
  /** Exactly equal */
  EQ = 'EQ',
  /** Greater-than */
  GT = 'GT',
  /** Greater-than or equal */
  GTEQ = 'GTEQ',
  /** Less-than */
  LT = 'LT',
  /** Less-than or equal */
  LTEQ = 'LTEQ',
  /** Not-equal */
  NOT = 'NOT',
}

/** Represents a payment to a contractor from a company. */
export type ContractorPayment = {
  __typename: 'ContractorPayment';
  checkContractorPaymentId: Scalars['String'];
  contractorPaymentItems: Array<ContractorPaymentItem>;
  id: Scalars['ID'];
  /** The total amount of net pay earned by the contractor. */
  netPay: Maybe<Scalars['String']>;
  /** May be manual or direct_deposit if the contractor has a linked bank account. */
  paymentMethod: Scalars['String'];
  payroll: Payroll;
  /** Status of the contractor payment. */
  status: PayrollItemStatusEnum;
  /** Worker associated with the contractor payment. */
  worker: Maybe<Worker>;
};

/** Represent the set of items associated with a contractor payment. */
export type ContractorPaymentItem = {
  __typename: 'ContractorPaymentItem';
  /** Address where work for this item was performed. */
  address: Maybe<Address>;
  /** Amount earned for this item. */
  amount: Scalars['String'];
  contractorPayment: ContractorPayment;
  /** Readable description of the earning code that will show up on the paystub. */
  description: Maybe<Scalars['String']>;
  /** Type of earning. */
  earningType: PayrollItemEarningEnum;
  /** Number of hours worked for this item */
  hours: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  timesheet: Maybe<Timesheet>;
};

/** Pagination container for ContractorPayment */
export type ContractorPaymentPage = {
  __typename: 'ContractorPaymentPage';
  items: Array<ContractorPayment>;
  pageInfo: PageInfo;
};

/** Actors that may be logged in */
export type CurrentActorUnion = CustomerAdmin | TenantAdmin | Worker;

/** Autogenerated return type of CustomRateCreateBatch */
export type CustomRateCreateBatchPayload = {
  __typename: 'CustomRateCreateBatchPayload';
  allCreated: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

/** Customer */
export type Customer = {
  __typename: 'Customer';
  accounts: Array<Account>;
  addresses: Array<Address>;
  admins: CustomerAdminPage;
  agency: Agency;
  allowedJobType: Maybe<Scalars['Int']>;
  comments: Array<Comment>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  invoiceDueDate: Scalars['String'];
  invoices: InvoicePage;
  jobTaxType: Maybe<Scalars['String']>;
  logoKey: Maybe<Scalars['String']>;
  logoUrl: Maybe<Scalars['String']>;
  ltaAllowed: Maybe<Scalars['Boolean']>;
  markupPercent: Scalars['Float'];
  name: Scalars['String'];
  onboardingStatus: CustomerOnboardingStatusEnum;
  orders: OrderPage;
  rateType: AccountRateTypeEnum;
  status: Scalars['NonNegativeInt'];
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
  workerRelationships: Array<WorkerRelationship>;
};

/** Customer */
export type CustomerAccountsArgs = {
  filters: Maybe<AccountFilterSetInput>;
};

/** Customer */
export type CustomerAdminsArgs = {
  filters: Maybe<CustomerAdminFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Customer */
export type CustomerInvoicesArgs = {
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Customer */
export type CustomerOrdersArgs = {
  filters: Maybe<OrderFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Customer */
export type CustomerWorkerRelationshipsArgs = {
  filters: Maybe<WorkerRelationshipFilterSetInput>;
};

/** Customer admin */
export type CustomerAdmin = {
  __typename: 'CustomerAdmin';
  acceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  active: Scalars['Boolean'];
  avatarKey: Maybe<Scalars['String']>;
  avatarUrl: Maybe<Scalars['String']>;
  chatUid: Scalars['String'];
  comments: Array<Comment>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  department: Maybe<Scalars['String']>;
  devices: Array<Device>;
  id: Scalars['ID'];
  role: Scalars['Int'];
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Autogenerated return type of CustomerAdminChangeAvatar */
export type CustomerAdminChangeAvatarPayload = {
  __typename: 'CustomerAdminChangeAvatarPayload';
  customerAdmin: CustomerAdmin;
};

/** Available filter operations for a CustomerAdmin */
export type CustomerAdminFilterSetInput = {
  acceptedAt?: Maybe<DateTimeCompareFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<IdEqualityFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  tags?: Maybe<IdMembershipFilterInput>;
};

/** Autogenerated return type of CustomerAdminLogin */
export type CustomerAdminLoginPayload = {
  __typename: 'CustomerAdminLoginPayload';
  accessToken: Scalars['String'];
  customerAdmin: CustomerAdmin;
  refreshToken: Scalars['String'];
};

/** Pagination container for CustomerAdmin */
export type CustomerAdminPage = {
  __typename: 'CustomerAdminPage';
  items: Array<CustomerAdmin>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of CustomerAdminResendInvitation */
export type CustomerAdminResendInvitationPayload = {
  __typename: 'CustomerAdminResendInvitationPayload';
  result: Scalars['Boolean'];
};

/** Autogenerated return type of CustomerAdminUpdateProfile */
export type CustomerAdminUpdateProfilePayload = {
  __typename: 'CustomerAdminUpdateProfilePayload';
  customerAdmin: CustomerAdmin;
};

/** Autogenerated return type of CustomerChangeLogo */
export type CustomerChangeLogoPayload = {
  __typename: 'CustomerChangeLogoPayload';
  customer: Customer;
};

/** Autogenerated return type of CustomerCreate */
export type CustomerCreatePayload = {
  __typename: 'CustomerCreatePayload';
  customer: Customer;
};

/** Available filter operations for a Customer */
export type CustomerFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeRangeFilterInput>;
  createdAtRange?: Maybe<DateTimeRangeFilterInput>;
  jobTaxType?: Maybe<Array<TaxTypeEnum>>;
  ltaAllowed?: Maybe<BooleanEqualityFilterInput>;
  onboardingStatus?: Maybe<Array<CustomerOnboardingStatusEnum>>;
  query?: Maybe<TextSearchFilterInput>;
  regionIds?: Maybe<Array<Scalars['ID']>>;
  states?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['NonNegativeInt']>>;
  tags?: Maybe<TaggedFilterInput>;
  updatedAt?: Maybe<DateTimeCompareFilterInput>;
  updatedAtRange?: Maybe<DateTimeRangeFilterInput>;
};

/** Autogenerated return type of CustomerInviteAdmin */
export type CustomerInviteAdminPayload = {
  __typename: 'CustomerInviteAdminPayload';
  customerAdmin: CustomerAdmin;
};

/** Customer list report */
export type CustomerListReport = {
  __typename: 'CustomerListReport';
  accountAgeDays: Scalars['Int'];
  accountId: Scalars['ID'];
  accountName: Scalars['String'];
  accountRepEmail: Scalars['String'];
  accountRepName: Scalars['String'];
  accountRepPhone: Scalars['String'];
  city: Scalars['String'];
  clientUrl: Scalars['String'];
  customerId: Scalars['ID'];
  customerName: Scalars['String'];
  latestOrder: Maybe<Scalars['String']>;
  ordersFiveMonthsAgo: Maybe<Scalars['Int']>;
  ordersFourMonthsAgo: Maybe<Scalars['Int']>;
  ordersLastMonth: Maybe<Scalars['Int']>;
  ordersSixMonthsAgo: Maybe<Scalars['Int']>;
  ordersThisMonth: Maybe<Scalars['Int']>;
  ordersThreeMonthsAgo: Maybe<Scalars['Int']>;
  ordersTwoMonthsAgo: Maybe<Scalars['Int']>;
  state: Scalars['String'];
  totalJobs: Scalars['Int'];
  zip: Scalars['String'];
};

export enum CustomerOnboardingStatusEnum {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

/** Pagination container for Customer */
export type CustomerPage = {
  __typename: 'CustomerPage';
  items: Array<Customer>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of CustomerRelateWorker */
export type CustomerRelateWorkerPayload = {
  __typename: 'CustomerRelateWorkerPayload';
  workerRelationship: WorkerRelationship;
};

/** Autogenerated return type of CustomerSignup */
export type CustomerSignupPayload = {
  __typename: 'CustomerSignupPayload';
  customer: Maybe<Customer>;
  success: Scalars['Boolean'];
};

export enum CustomerSortKeyEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
}

/** Autogenerated return type of CustomerUpdateJobTaxType */
export type CustomerUpdateJobTaxTypePayload = {
  __typename: 'CustomerUpdateJobTaxTypePayload';
  customer: Customer;
};

/** Autogenerated return type of CustomerUpdate */
export type CustomerUpdatePayload = {
  __typename: 'CustomerUpdatePayload';
  customer: Customer;
};

/** Tests an operation against a static value */
export type DateTimeCompareFilterInput = {
  op: CompareOperatorEnum;
  value: Scalars['ISO8601DateTime'];
};

/** Tests inclusion between a given inclusive range */
export type DateTimeRangeFilterInput = {
  from: Scalars['ISO8601DateTime'];
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: Maybe<Scalars['Boolean']>;
  to: Scalars['ISO8601DateTime'];
};

export enum DefaultDueDateEnum {
  INVOICE_DUE_10_DAYS = 'INVOICE_DUE_10_DAYS',
  INVOICE_DUE_15_DAYS = 'INVOICE_DUE_15_DAYS',
  INVOICE_DUE_30_DAYS = 'INVOICE_DUE_30_DAYS',
  INVOICE_DUE_45_DAYS = 'INVOICE_DUE_45_DAYS',
  INVOICE_DUE_60_DAYS = 'INVOICE_DUE_60_DAYS',
}

/** Default Rate */
export type DefaultRate = {
  __typename: 'DefaultRate';
  city: Maybe<Scalars['String']>;
  costRate: Maybe<Scalars['PositiveInt']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  payRate: Maybe<Scalars['PositiveInt']>;
  region: Maybe<Region>;
  skill: Maybe<Skill>;
  state: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  zip: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DefaultRateCreate */
export type DefaultRateCreatePayload = {
  __typename: 'DefaultRateCreatePayload';
  defaultRate: DefaultRate;
};

/** Autogenerated return type of DefaultRateUpdate */
export type DefaultRateUpdatePayload = {
  __typename: 'DefaultRateUpdatePayload';
  defaultRate: DefaultRate;
};

/** Defined boundry */
export type DefinedBoundry = {
  __typename: 'DefinedBoundry';
  area: MultiPolygon;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  metadata: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Pagination container for DefinedBoundry */
export type DefinedBoundryPage = {
  __typename: 'DefinedBoundryPage';
  items: Array<DefinedBoundry>;
  pageInfo: PageInfo;
};

/** Device */
export type Device = {
  __typename: 'Device';
  client: Maybe<Scalars['String']>;
  clientVersion: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  expiresAt: Scalars['Int'];
  id: Scalars['ID'];
  ip: Maybe<Scalars['String']>;
  lastActivityAt: Maybe<Scalars['ISO8601DateTime']>;
  lastIssued: Scalars['String'];
  lastIssuedAt: Scalars['ISO8601DateTime'];
  notificationsStatus: Maybe<Scalars['Boolean']>;
  refreshToken: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  userAgent: Maybe<Scalars['String']>;
};

export enum DurationEnum {
  FROM_0_TO_6_MONTHS = 'FROM_0_TO_6_MONTHS',
  FROM_1_TO_3_YEARS = 'FROM_1_TO_3_YEARS',
  FROM_6_TO_12_MONTHS = 'FROM_6_TO_12_MONTHS',
  MORE_THAN_3_YEARS = 'MORE_THAN_3_YEARS',
}

export enum EmailActionEnum {
  ACTIVE_USERS_REPORT = 'ACTIVE_USERS_REPORT',
  CHECKR_INVITATION = 'CHECKR_INVITATION',
  EMAIL_CHANGE = 'EMAIL_CHANGE',
  EMAIL_VALIDATED = 'EMAIL_VALIDATED',
  EMPLOYEE_TIMESHEETS_REPORT = 'EMPLOYEE_TIMESHEETS_REPORT',
  INVITE_CLIENT_ADMIN = 'INVITE_CLIENT_ADMIN',
  INVITE_CUSTOMER_ADMIN = 'INVITE_CUSTOMER_ADMIN',
  INVOICE_PAYMENT_INTENT_FAILED = 'INVOICE_PAYMENT_INTENT_FAILED',
  INVOICE_PAYMENT_INTENT_SUCCESSFUL = 'INVOICE_PAYMENT_INTENT_SUCCESSFUL',
  JOBS_DROPPED_BY_WORKER = 'JOBS_DROPPED_BY_WORKER',
  JOB_CANCELLED = 'JOB_CANCELLED',
  JOB_DROPPED_BY_WORKER = 'JOB_DROPPED_BY_WORKER',
  JOB_ORDERED = 'JOB_ORDERED',
  MONITOR_CLIENT_SIGNUP = 'MONITOR_CLIENT_SIGNUP',
  PAYROLL_JOB_FAILED = 'PAYROLL_JOB_FAILED',
  PAYROLL_JOURNAL_REPORT = 'PAYROLL_JOURNAL_REPORT',
  PERSONALIZED = 'PERSONALIZED',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  RECOVER_WEB_PASSWORD = 'RECOVER_WEB_PASSWORD',
  REFERRALS = 'REFERRALS',
  RESEND_TOKEN = 'RESEND_TOKEN',
  RESET_WORKER_PASSWORD = 'RESET_WORKER_PASSWORD',
  WORKER_ONBOARDING = 'WORKER_ONBOARDING',
  WORKER_VALIDATE_EMAIL = 'WORKER_VALIDATE_EMAIL',
  WORKER_WELCOME = 'WORKER_WELCOME',
}

/** Event */
export type Event = {
  __typename: 'Event';
  action: EventActionEnum;
  createdAt: Scalars['ISO8601DateTime'];
  cursor: Scalars['String'];
  data: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  jti: Maybe<Scalars['String']>;
  occurredAt: Scalars['Int'];
  subjects: Array<EventSubject>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  uuid: Scalars['String'];
};

export enum EventActionEnum {
  ACCOUNT_CREATE = 'ACCOUNT_CREATE',
  ACCOUNT_SET_CURRENT_PAYMENT_METHOD = 'ACCOUNT_SET_CURRENT_PAYMENT_METHOD',
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  ADDRESS_CREATE = 'ADDRESS_CREATE',
  ADDRESS_DELETE = 'ADDRESS_DELETE',
  ADDRESS_UPDATE = 'ADDRESS_UPDATE',
  ADDRESS_VALIDATE = 'ADDRESS_VALIDATE',
  ADD_CANDIDATE_SKILL = 'ADD_CANDIDATE_SKILL',
  ADMIN_RESET_WORKER_PASSWORD = 'ADMIN_RESET_WORKER_PASSWORD',
  AGENCY_CREATE = 'AGENCY_CREATE',
  AGENCY_UPDATE = 'AGENCY_UPDATE',
  COMMENTABLE_ADD_COMMENT = 'COMMENTABLE_ADD_COMMENT',
  COMPLETE_CANDIDATE_QUIZ = 'COMPLETE_CANDIDATE_QUIZ',
  CREATE_CANDIDATE_REFERENCE = 'CREATE_CANDIDATE_REFERENCE',
  CUSTOMER_ADMIN_CHANGE_AVATAR = 'CUSTOMER_ADMIN_CHANGE_AVATAR',
  CUSTOMER_ADMIN_PAYMENT_METHOD = 'CUSTOMER_ADMIN_PAYMENT_METHOD',
  CUSTOMER_ADMIN_RESEND_INVITATION = 'CUSTOMER_ADMIN_RESEND_INVITATION',
  CUSTOMER_ADMIN_UPDATE_PROFILE = 'CUSTOMER_ADMIN_UPDATE_PROFILE',
  CUSTOMER_CHANGE_LOGO = 'CUSTOMER_CHANGE_LOGO',
  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CUSTOMER_INVITE_ADMIN = 'CUSTOMER_INVITE_ADMIN',
  CUSTOMER_RELATE_WORKER = 'CUSTOMER_RELATE_WORKER',
  CUSTOMER_UNRELATE_WORKER = 'CUSTOMER_UNRELATE_WORKER',
  CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
  CUSTOM_RATE_CREATE_BATCH = 'CUSTOM_RATE_CREATE_BATCH',
  DEFAULT_RATE_CREATE = 'DEFAULT_RATE_CREATE',
  DEFAULT_RATE_UPDATE = 'DEFAULT_RATE_UPDATE',
  DEVICE_UPDATE_NOTIFICATIONS = 'DEVICE_UPDATE_NOTIFICATIONS',
  INVOICE_PAYMENT_INTENT_CREATE = 'INVOICE_PAYMENT_INTENT_CREATE',
  INVOICE_PAYMENT_INTENT_UPDATE = 'INVOICE_PAYMENT_INTENT_UPDATE',
  JOB_CANCEL = 'JOB_CANCEL',
  JOB_CANCEL_BATCH = 'JOB_CANCEL_BATCH',
  JOB_DISMISS_WORKER = 'JOB_DISMISS_WORKER',
  JOB_EDIT_NUMBER_WORKERS = 'JOB_EDIT_NUMBER_WORKERS',
  JOB_EDIT_TIME = 'JOB_EDIT_TIME',
  JOB_HIRE_WORKER = 'JOB_HIRE_WORKER',
  JOB_HOLD = 'JOB_HOLD',
  JOB_MATCHING_WORKER = 'JOB_MATCHING_WORKER',
  JOB_OFFERS_REJECT = 'JOB_OFFERS_REJECT',
  JOB_OFFER_ACCEPT = 'JOB_OFFER_ACCEPT',
  JOB_OFFER_CREATE = 'JOB_OFFER_CREATE',
  JOB_OFFER_GROUP_ACCEPT = 'JOB_OFFER_GROUP_ACCEPT',
  JOB_OFFER_REJECT = 'JOB_OFFER_REJECT',
  JOB_PUBLISH = 'JOB_PUBLISH',
  JOB_REQUESTS_CREATE = 'JOB_REQUESTS_CREATE',
  JOB_REQUEST_ACCEPT = 'JOB_REQUEST_ACCEPT',
  JOB_REQUEST_CANCEL = 'JOB_REQUEST_CANCEL',
  JOB_REQUEST_CREATE = 'JOB_REQUEST_CREATE',
  JOB_REQUEST_REJECT = 'JOB_REQUEST_REJECT',
  JOB_UNPUBLISH = 'JOB_UNPUBLISH',
  JOB_UPDATE = 'JOB_UPDATE',
  ORDER_CREATE = 'ORDER_CREATE',
  RATE_CANCEL = 'RATE_CANCEL',
  RATE_CREATE = 'RATE_CREATE',
  RATE_DELETE = 'RATE_DELETE',
  RATE_UPDATE = 'RATE_UPDATE',
  REFERRAL_CREATE = 'REFERRAL_CREATE',
  SERVICE_AREA_CREATE = 'SERVICE_AREA_CREATE',
  SERVICE_AREA_UPDATE = 'SERVICE_AREA_UPDATE',
  SIGNUP_CANDIDATE = 'SIGNUP_CANDIDATE',
  SKILL_CATEGORY_CREATE = 'SKILL_CATEGORY_CREATE',
  SKILL_CATEGORY_UPDATE = 'SKILL_CATEGORY_UPDATE',
  SKILL_CREATE = 'SKILL_CREATE',
  SKILL_REQUEST_ACCEPT = 'SKILL_REQUEST_ACCEPT',
  SKILL_REQUEST_REJECT = 'SKILL_REQUEST_REJECT',
  SKILL_UPDATE = 'SKILL_UPDATE',
  STRIPE_ATTACH_PAYMENT_METHOD = 'STRIPE_ATTACH_PAYMENT_METHOD',
  STRIPE_CREATE_INVOICE_ITEM = 'STRIPE_CREATE_INVOICE_ITEM',
  STRIPE_DELETE_INVOICE = 'STRIPE_DELETE_INVOICE',
  STRIPE_DELETE_INVOICE_ITEM = 'STRIPE_DELETE_INVOICE_ITEM',
  STRIPE_FINALIZE_INVOICE = 'STRIPE_FINALIZE_INVOICE',
  STRIPE_GET_PAYMENT_METHOD = 'STRIPE_GET_PAYMENT_METHOD',
  STRIPE_UPDATE_INVOICE = 'STRIPE_UPDATE_INVOICE',
  STRIPE_UPDATE_INVOICE_ITEM = 'STRIPE_UPDATE_INVOICE_ITEM',
  STRIPE_VOID_INVOICE = 'STRIPE_VOID_INVOICE',
  TENANT_ADMIN_CHANGE_AVATAR = 'TENANT_ADMIN_CHANGE_AVATAR',
  TENANT_ADMIN_CREATE = 'TENANT_ADMIN_CREATE',
  TENANT_ADMIN_EDIT_JOB_RATES = 'TENANT_ADMIN_EDIT_JOB_RATES',
  TENANT_ADMIN_EDIT_JOB_TIMES = 'TENANT_ADMIN_EDIT_JOB_TIMES',
  TENANT_ADMIN_RESEND_INVITATION = 'TENANT_ADMIN_RESEND_INVITATION',
  TENANT_UPDATE = 'TENANT_UPDATE',
  TIMESHEET_APPROVE = 'TIMESHEET_APPROVE',
  TIMESHEET_CHECKIN = 'TIMESHEET_CHECKIN',
  TIMESHEET_CHECKOUT = 'TIMESHEET_CHECKOUT',
  TIMESHEET_CREATE = 'TIMESHEET_CREATE',
  TIMESHEET_REJECT = 'TIMESHEET_REJECT',
  TIMESHEET_UNAPPROVE = 'TIMESHEET_UNAPPROVE',
  TIMESHEET_UNREJECT = 'TIMESHEET_UNREJECT',
  TIMESHEET_UPDATE = 'TIMESHEET_UPDATE',
  UNLINK_INVOICE = 'UNLINK_INVOICE',
  UPDATE_CANDIDATE_PROFILE = 'UPDATE_CANDIDATE_PROFILE',
  USER_CHANGE_ACTIVE = 'USER_CHANGE_ACTIVE',
  USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD',
  USER_LOGOUT_ALL = 'USER_LOGOUT_ALL',
  USER_RESET_PASSWORD = 'USER_RESET_PASSWORD',
  USER_UPDATE = 'USER_UPDATE',
  VERIFY_CANDIDATE = 'VERIFY_CANDIDATE',
  WILLO_WORKER_INTERVIEW_UPDATE = 'WILLO_WORKER_INTERVIEW_UPDATE',
  WORKER_ACCOUNT_DISABLE = 'WORKER_ACCOUNT_DISABLE',
  WORKER_ADD_CERTIFICATE = 'WORKER_ADD_CERTIFICATE',
  WORKER_ADD_REFERENCE = 'WORKER_ADD_REFERENCE',
  WORKER_ADD_REFERENCES = 'WORKER_ADD_REFERENCES',
  WORKER_ADD_SKILL = 'WORKER_ADD_SKILL',
  WORKER_ASSIGN_JOB_WORKPLACE = 'WORKER_ASSIGN_JOB_WORKPLACE',
  WORKER_CHANGE_ACTIVE = 'WORKER_CHANGE_ACTIVE',
  WORKER_CHANGE_ADDRESS = 'WORKER_CHANGE_ADDRESS',
  WORKER_CHANGE_AVATAR = 'WORKER_CHANGE_AVATAR',
  WORKER_CREATE = 'WORKER_CREATE',
  WORKER_CREATE_SUPPORT_CHAT = 'WORKER_CREATE_SUPPORT_CHAT',
  WORKER_DISABLE = 'WORKER_DISABLE',
  WORKER_DROP_JOB = 'WORKER_DROP_JOB',
  WORKER_INTERVIEW_CREATE = 'WORKER_INTERVIEW_CREATE',
  WORKER_INTERVIEW_UPDATE = 'WORKER_INTERVIEW_UPDATE',
  WORKER_JURISDICTION_CANCEL = 'WORKER_JURISDICTION_CANCEL',
  WORKER_JURISDICTION_REQUEST = 'WORKER_JURISDICTION_REQUEST',
  WORKER_JURISDICTION_UPDATE = 'WORKER_JURISDICTION_UPDATE',
  WORKER_ONBOARD = 'WORKER_ONBOARD',
  WORKER_REMOVE_JOB_WORKPLACEE = 'WORKER_REMOVE_JOB_WORKPLACEE',
  WORKER_REMOVE_SKILL = 'WORKER_REMOVE_SKILL',
  WORKER_REQUEST_SKILL = 'WORKER_REQUEST_SKILL',
  WORKER_SIGNUP = 'WORKER_SIGNUP',
  WORKER_SUBMIT_APPLICATION = 'WORKER_SUBMIT_APPLICATION',
  WORKER_UPDATE_APPLICANT = 'WORKER_UPDATE_APPLICANT',
  WORKER_UPDATE_BACKGROUND_CHECK = 'WORKER_UPDATE_BACKGROUND_CHECK',
  WORKER_UPDATE_CANDIDATE = 'WORKER_UPDATE_CANDIDATE',
  WORKER_UPDATE_ONBOARDING_STATUS = 'WORKER_UPDATE_ONBOARDING_STATUS',
  WORKER_UPDATE_PROFILE = 'WORKER_UPDATE_PROFILE',
  WORKER_UPDATE_TAX_TYPE = 'WORKER_UPDATE_TAX_TYPE',
  WORKER_UPDATE_WILLO_INTERVIEW = 'WORKER_UPDATE_WILLO_INTERVIEW',
  WORKER_VERIFY = 'WORKER_VERIFY',
}

/** Event subject */
export type EventSubject = {
  __typename: 'EventSubject';
  createdAt: Scalars['ISO8601DateTime'];
  event: Maybe<Event>;
  id: Scalars['ID'];
  object: EventSubjectObject;
  role: Maybe<SubjectRoleEnum>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum EventSubjectEnum {
  ACCOUNT = 'ACCOUNT',
  ADDRESS = 'ADDRESS',
  AGENCY = 'AGENCY',
  CANDIDATE = 'CANDIDATE',
  CANDIDATE_REFERENCE = 'CANDIDATE_REFERENCE',
  CUSTOMER = 'CUSTOMER',
  DEVICE = 'DEVICE',
  JOB = 'JOB',
  JOB_OFFER = 'JOB_OFFER',
  JOB_WORKER = 'JOB_WORKER',
  ORDER = 'ORDER',
  ORIENTATION = 'ORIENTATION',
  ORIENTATION_SITE = 'ORIENTATION_SITE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  RATE = 'RATE',
  SERVICE_AREA = 'SERVICE_AREA',
  SKILL = 'SKILL',
  SKILL_CATEGORY = 'SKILL_CATEGORY',
  TAG = 'TAG',
  TAGGING = 'TAGGING',
  TENANT = 'TENANT',
  TIMESHEET = 'TIMESHEET',
  USER = 'USER',
  WORKER = 'WORKER',
  WORKER_SKILL = 'WORKER_SKILL',
}

/** The underlying object of an EventSubject */
export type EventSubjectObject = {
  __typename: 'EventSubjectObject';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  type: EventSubjectEnum;
};

/** Fetch Rates */
export type FetchRates = {
  __typename: 'FetchRates';
  createdAt: Scalars['ISO8601DateTime'];
  defaultRates: Maybe<Array<DefaultRate>>;
  rates: Maybe<Array<Rate>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum FileTypeEnum {
  AVATAR = 'AVATAR',
  ICON = 'ICON',
  LOGO = 'LOGO',
}

export enum FillStatusEnum {
  ALL = 'ALL',
  FILLED = 'FILLED',
  UNFILLED = 'UNFILLED',
}

export enum GenderEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum I9StatusEnum {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  EVERIFY_IN_PROGRESS = 'EVERIFY_IN_PROGRESS',
  IN_PROGRESS = 'IN_PROGRESS',
  NA = 'NA',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  PENDING = 'PENDING',
  PENDING_EVERIFY = 'PENDING_EVERIFY',
  PENDING_SECTION2 = 'PENDING_SECTION2',
  PROSPECT = 'PROSPECT',
  REJECTED = 'REJECTED',
  TENTATIVE_NC = 'TENTATIVE_NC',
}

/** I9 Form */
export type I9form = {
  __typename: 'I9form';
  b2bStatus: Maybe<Scalars['String']>;
  formi9RequestId: Scalars['Int'];
  formi9ResultId: Scalars['Int'];
  id: Scalars['ID'];
  processStatus: Scalars['String'];
  requestId: Scalars['Int'];
  resultId: Scalars['Int'];
  status: Scalars['String'];
};

/** Tests field for equality to a given value */
export type IdEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: Scalars['ID'];
};

/** Tests field for inclusion in a list */
export type IdMembershipFilterInput = {
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: Maybe<Scalars['Boolean']>;
  value: Array<Scalars['ID']>;
};

/** Tests an operation against a static value */
export type IntCompareFilterInput = {
  op: CompareOperatorEnum;
  value: Scalars['Int'];
};

export type InterviewAnswerInput = {
  answer: Scalars['String'];
  interviewQuestionId: Scalars['ID'];
};

/** Interview Question */
export type InterviewQuestion = {
  __typename: 'InterviewQuestion';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  placeholder: Scalars['String'];
  position: Scalars['Int'];
  question: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum InterviewStatusEnum {
  INTERVIEW_DECLINED = 'INTERVIEW_DECLINED',
  INTERVIEW_PASSED = 'INTERVIEW_PASSED',
  INTERVIEW_REQUESTED = 'INTERVIEW_REQUESTED',
  INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
  INTERVIEW_TO_REVIEW = 'INTERVIEW_TO_REVIEW',
}

/** An Invoice */
export type Invoice = {
  __typename: 'Invoice';
  account: Account;
  amountDue: Scalars['String'];
  autoApprove: Scalars['Boolean'];
  created: Scalars['String'];
  createdDatetime: Scalars['ISO8601DateTime'];
  customer: Customer;
  defaultContactEmail: Scalars['String'];
  deleted: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  dueDate: Maybe<Scalars['String']>;
  dueDateDatetime: Maybe<Scalars['ISO8601DateTime']>;
  hostedInvoiceUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceFinalizedAt: Maybe<Scalars['ISO8601DateTime']>;
  invoicePdf: Maybe<Scalars['String']>;
  invoiceUid: Scalars['String'];
  name: Scalars['String'];
  number: Maybe<Scalars['String']>;
  status: StripeInvoiceStatusEnum;
  stripeCustomerId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
  subtotal: Scalars['String'];
  total: Scalars['String'];
};

export enum InvoiceCutOffEnum {
  BIWEEKLY = 'BIWEEKLY',
  DAILY = 'DAILY',
  JOB = 'JOB',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

/** Available filter operations for a Invoice */
export type InvoiceFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  query?: Maybe<TextSearchFilterInput>;
};

/** Autogenerated return type of InvoiceGenerate */
export type InvoiceGeneratePayload = {
  __typename: 'InvoiceGeneratePayload';
  invoice: Invoice;
};

export enum InvoiceGroupByEnum {
  LOCATION = 'LOCATION',
  NONE = 'NONE',
  SKILL = 'SKILL',
}

/** An Invoice Item */
export type InvoiceItem = {
  __typename: 'InvoiceItem';
  address: Maybe<Scalars['String']>;
  amount: Scalars['String'];
  billRate: Maybe<Scalars['NonNegativeInt']>;
  cancelled: Maybe<Scalars['Boolean']>;
  currency: Scalars['String'];
  date: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Maybe<Scalars['String']>;
  invoice: Maybe<Scalars['String']>;
  isEdited: Maybe<Scalars['Boolean']>;
  isHoliday: Maybe<Scalars['Boolean']>;
  isOvertime: Maybe<Scalars['Boolean']>;
  jobId: Maybe<Scalars['ID']>;
  netTime: Maybe<Scalars['String']>;
  netTimeMinutes: Maybe<Scalars['NonNegativeInt']>;
  order: Maybe<Scalars['String']>;
  quantity: Scalars['String'];
  shortDescription: Scalars['String'];
  skill: Maybe<Skill>;
  stripeCustomerId: Scalars['String'];
  timesheet: Maybe<Scalars['String']>;
  timesheetId: Maybe<Scalars['ID']>;
  timesheetInvoice: Maybe<Invoice>;
  tip: Maybe<Scalars['NonNegativeInt']>;
  unitAmount: Scalars['String'];
  unitAmountDecimal: Maybe<Scalars['String']>;
  worker: Maybe<Worker>;
  workerTimesheet: Maybe<Timesheet>;
};

/** Pagination container for Invoice */
export type InvoicePage = {
  __typename: 'InvoicePage';
  items: Array<Invoice>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of InvoicePay */
export type InvoicePayPayload = {
  __typename: 'InvoicePayPayload';
  success: Scalars['Boolean'];
};

export enum InvoiceSortKeyEnum {
  BILLING_ACCOUNT = 'BILLING_ACCOUNT',
  CREATED_ON = 'CREATED_ON',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  DUE_DATE = 'DUE_DATE',
  ID = 'ID',
  STATUS = 'STATUS',
}

/** Autogenerated return type of InvoiceTimesheetsPdf */
export type InvoiceTimesheetsPdfPayload = {
  __typename: 'InvoiceTimesheetsPdfPayload';
  pdfCreated: UploadUrl;
};

/** Invoicing Report */
export type InvoicingReport = {
  __typename: 'InvoicingReport';
  companyName: Scalars['String'];
  companyNumber: Scalars['String'];
  invoiceDate: Maybe<Scalars['String']>;
  invoiceNumber: Maybe<Scalars['String']>;
  invoicePaid: Maybe<Scalars['String']>;
  invoiceStatus: Maybe<Scalars['String']>;
  invoiced: Scalars['String'];
  jobDate: Scalars['String'];
  openInvoice: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  unapprovedHours: Scalars['Float'];
};

/** Job */
export type Job = {
  __typename: 'Job';
  account: Account;
  activeJobRequests: Array<JobRequest>;
  activeJobWorkers: Array<JobWorker>;
  address: Address;
  addressInstructions: Maybe<Scalars['String']>;
  agency: Agency;
  approvalStatus: JobApprovalStatusEnum;
  autoAcceptRequests: Scalars['Boolean'];
  cancelUser: Maybe<CancelUserUnion>;
  cancelled: Scalars['Boolean'];
  cancelledAt: Maybe<Scalars['ISO8601DateTime']>;
  chatGid: Maybe<Scalars['String']>;
  checkinEnabled: Scalars['Boolean'];
  checkinWorkerCount: Maybe<Scalars['NonNegativeInt']>;
  comments: Array<Comment>;
  contact: CustomerAdmin;
  contactInstructions: Maybe<Scalars['String']>;
  costRate: Scalars['PositiveInt'];
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  filled: Scalars['Boolean'];
  firstShiftStartAt: Scalars['ISO8601DateTime'];
  groupId: Maybe<Scalars['String']>;
  groupJobs: Maybe<Array<Job>>;
  hasNoShow: Scalars['Boolean'];
  hiredWorkersCount: Scalars['NonNegativeInt'];
  id: Scalars['ID'];
  inactiveJobWorkers: Array<JobWorker>;
  instructions: Maybe<Scalars['String']>;
  isHoliday: Scalars['Boolean'];
  isRateLock: Maybe<Scalars['Boolean']>;
  jobHoldBy: Maybe<Scalars['Int']>;
  jobHoldOn: Maybe<Scalars['ISO8601DateTime']>;
  jobOffers: Array<JobOffer>;
  jobOffersPendingToAccept: Maybe<Array<Job>>;
  jobRequests: Array<JobRequest>;
  jobStatus: Maybe<Scalars['Int']>;
  jobType: JobTypeEnum;
  jobWorkers: Array<JobWorker>;
  jobsAvailable: Maybe<Scalars['Boolean']>;
  lastShiftEndAt: Scalars['ISO8601DateTime'];
  mandatoryBreakTime: Maybe<Scalars['Minutes']>;
  markupPercent: Maybe<Scalars['Float']>;
  minBillRate: Scalars['PositiveInt'];
  minPayRate: Scalars['PositiveInt'];
  order: Order;
  orderId: Maybe<Scalars['String']>;
  originalCostRate: Scalars['PositiveInt'];
  originalPayRate: Scalars['PositiveInt'];
  payRate: Scalars['PositiveInt'];
  postedAt: Maybe<Scalars['ISO8601DateTime']>;
  published: Scalars['Boolean'];
  publishedOrInvited: Scalars['Boolean'];
  quantity: Scalars['PositiveInt'];
  rate: Rate;
  reason: Maybe<Scalars['String']>;
  sameWorkerAllShifts: Scalars['Boolean'];
  shifts: Array<Shift>;
  skill: Skill;
  status: JobStatusEnum;
  tags: Array<Tag>;
  taxType: Maybe<TaxTypeEnum>;
  timesheets: Array<Timesheet>;
  uniform: Uniform;
  uniformInstructions: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userCancelId: Maybe<Scalars['ID']>;
  workerGroupJobs: Maybe<Array<Job>>;
};

/** Job */
export type JobJobOffersPendingToAcceptArgs = {
  workerId: Scalars['ID'];
};

/** Job */
export type JobTimesheetsArgs = {
  filters: Maybe<TimesheetFilterSetInput>;
};

/** Job */
export type JobWorkerGroupJobsArgs = {
  workerId: Scalars['ID'];
};

/** Autogenerated return type of JobAddSubscriber */
export type JobAddSubscriberPayload = {
  __typename: 'JobAddSubscriberPayload';
  agency: Agency;
  job: Job;
  order: Order;
  shift: Shift;
};

export enum JobApplicationEnum {
  ACCEPTED = 'ACCEPTED',
  ALL = 'ALL',
  REJECTED = 'REJECTED',
}

/** Tests field for equality to a given Job Application Status */
export type JobApplicationEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: JobApplicationEnum;
};

export enum JobApprovalStatusEnum {
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  NA = 'NA',
  NO_SHOW = 'NO_SHOW',
  NO_TIMESHEET = 'NO_TIMESHEET',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  TIMESHEET_UNFINISHED = 'TIMESHEET_UNFINISHED',
}

/** Autogenerated return type of JobCancelBatch */
export type JobCancelBatchPayload = {
  __typename: 'JobCancelBatchPayload';
  order: Order;
};

/** Autogenerated return type of JobCancel */
export type JobCancelPayload = {
  __typename: 'JobCancelPayload';
  cancelUser: User;
  reason: Scalars['String'];
};

export enum JobDashboardStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  NO_SHOW = 'NO_SHOW',
}

/** Available filter operations for a Job */
export type JobFilterSetInput = {
  accounts?: Maybe<IdMembershipFilterInput>;
  addresses?: Maybe<IdMembershipFilterInput>;
  agencies?: Maybe<IdMembershipFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<DateTimeCompareFilterInput>;
  contacts?: Maybe<IdMembershipFilterInput>;
  costRate?: Maybe<IntCompareFilterInput>;
  customerIds?: Maybe<Array<Scalars['ID']>>;
  customers?: Maybe<IdMembershipFilterInput>;
  description?: Maybe<TextSearchFilterInput>;
  fillStatus?: Maybe<FillStatusEnum>;
  firstShiftStartAt?: Maybe<DateTimeCompareFilterInput>;
  hiredWorkers?: Maybe<IntCompareFilterInput>;
  instructions?: Maybe<TextSearchFilterInput>;
  jobDashboardStatus?: Maybe<Array<JobDashboardStatusEnum>>;
  jobStatus?: Maybe<JobStatusEqualityFilterInput>;
  lastShiftEndAt?: Maybe<DateTimeCompareFilterInput>;
  orders?: Maybe<IdMembershipFilterInput>;
  payRate?: Maybe<IntCompareFilterInput>;
  posted?: Maybe<Scalars['Boolean']>;
  postedAt?: Maybe<DateTimeCompareFilterInput>;
  quantity?: Maybe<IntCompareFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  rates?: Maybe<IdMembershipFilterInput>;
  regionIds?: Maybe<Array<Scalars['ID']>>;
  skillIds?: Maybe<Array<Scalars['ID']>>;
  skills?: Maybe<IdMembershipFilterInput>;
  states?: Maybe<Array<Scalars['String']>>;
  surge?: Maybe<BooleanEqualityFilterInput>;
  timelineStatus?: Maybe<TimelineStatusEnum>;
  unfilledWorkers?: Maybe<IntCompareFilterInput>;
  uniforms?: Maybe<IdMembershipFilterInput>;
  visibility?: Maybe<VisibilityStatusEnum>;
};

/** Autogenerated return type of JobHireWorker */
export type JobHireWorkerPayload = {
  __typename: 'JobHireWorkerPayload';
  job: Job;
  jobWorker: JobWorker;
};

/** Job history */
export type JobHistory = {
  __typename: 'JobHistory';
  createdBy: Maybe<User>;
  jobId: Maybe<Scalars['ID']>;
  ratesUpdates: Maybe<Array<Event>>;
};

export type JobInput = {
  addressId: Scalars['ID'];
  addressInstructions?: Maybe<Scalars['String']>;
  allShiftsSameWorker?: Maybe<Scalars['Boolean']>;
  autoAcceptRequests?: Maybe<Scalars['Boolean']>;
  contactId: Scalars['ID'];
  contactInstructions?: Maybe<Scalars['String']>;
  costRate?: Maybe<Scalars['PositiveInt']>;
  groupId?: Maybe<Scalars['Int']>;
  hireWorkers?: Maybe<Array<Scalars['ID']>>;
  instructions?: Maybe<Scalars['String']>;
  inviteWorkers?: Maybe<Array<Scalars['ID']>>;
  isHoliday?: Maybe<Scalars['Boolean']>;
  jobType?: Maybe<JobTypeEnum>;
  mandatoryBreakTime?: Maybe<Scalars['Minutes']>;
  originalCostRate?: Maybe<Scalars['PositiveInt']>;
  originalPayRate?: Maybe<Scalars['PositiveInt']>;
  payRate?: Maybe<Scalars['PositiveInt']>;
  publish?: Maybe<Scalars['Boolean']>;
  publishIn?: Maybe<Scalars['Minutes']>;
  quantity: Scalars['PositiveInt'];
  rateId?: Maybe<Scalars['ID']>;
  shifts: Array<ShiftInput>;
  skillId: Scalars['ID'];
  uniformId: Scalars['ID'];
  uniformInstructions?: Maybe<Scalars['String']>;
  visibility?: Maybe<VisibilityStatusEnum>;
};

/** Job offer */
export type JobOffer = {
  __typename: 'JobOffer';
  acceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  costRate: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  job: Job;
  payRate: Scalars['Int'];
  rejectedAt: Maybe<Scalars['ISO8601DateTime']>;
  source: Maybe<Scalars['String']>;
  surge: Scalars['Boolean'];
  token: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

/** Autogenerated return type of JobOfferCreate */
export type JobOfferCreatePayload = {
  __typename: 'JobOfferCreatePayload';
  jobOffer: JobOffer;
};

/** Autogenerated return type of JobOfferGroupCreate */
export type JobOfferGroupCreatePayload = {
  __typename: 'JobOfferGroupCreatePayload';
  status: Scalars['Boolean'];
};

/** A group of jobs for an order */
export type JobOffersGroup = {
  __typename: 'JobOffersGroup';
  createdAt: Scalars['ISO8601DateTime'];
  firstPayRate: Scalars['PositiveInt'];
  lastShiftEndAt: Scalars['ISO8601DateTime'];
  maxEstimatedPay: Scalars['PositiveInt'];
  maxMandatoryBreakTime: Scalars['PositiveInt'];
  maxPayRate: Scalars['PositiveInt'];
  maxWorkMinutes: Scalars['PositiveInt'];
  minEstimatedPay: Scalars['PositiveInt'];
  minMandatoryBreakTime: Scalars['PositiveInt'];
  minPayRate: Scalars['PositiveInt'];
  minWorkMinutes: Scalars['PositiveInt'];
  nextJobId: Scalars['ID'];
  nextJobOffer: JobOffer;
  nextShiftStartAt: Scalars['ISO8601DateTime'];
  orderId: Scalars['ID'];
  shiftCount: Scalars['PositiveInt'];
  totalEstimatedPay: Scalars['PositiveInt'];
  updatedAt: Scalars['ISO8601DateTime'];
  workerId: Scalars['ID'];
};

/** Pagination container for Job */
export type JobPage = {
  __typename: 'JobPage';
  items: Array<Job>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of JobPublish */
export type JobPublishPayload = {
  __typename: 'JobPublishPayload';
  job: Job;
};

/** Autogenerated return type of JobRemoveSubscriber */
export type JobRemoveSubscriberPayload = {
  __typename: 'JobRemoveSubscriberPayload';
  agency: Agency;
};

/** Job request */
export type JobRequest = {
  __typename: 'JobRequest';
  acceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  job: Job;
  rejectedAt: Maybe<Scalars['ISO8601DateTime']>;
  status: JobRequestStatusEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

/** Autogenerated return type of JobRequestAccept */
export type JobRequestAcceptPayload = {
  __typename: 'JobRequestAcceptPayload';
  job: Job;
  jobWorker: JobWorker;
};

/** Autogenerated return type of JobRequestReject */
export type JobRequestRejectPayload = {
  __typename: 'JobRequestRejectPayload';
  job: Job;
  jobRequest: JobRequest;
};

export enum JobRequestStatusEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum JobStatusEnum {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  UPCOMING = 'UPCOMING',
}

/** Tests field for equality to a given JobStatus */
export type JobStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: JobStatusEnum;
};

/** Job Subscriber */
export type JobSubscriber = {
  __typename: 'JobSubscriber';
  agency: Agency;
  agencyId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  job: Job;
  jobId: Scalars['ID'];
  order: Order;
  orderId: Scalars['ID'];
  shift: Shift;
  shiftId: Scalars['ID'];
  subscriberEmail: Scalars['String'];
  tenantId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum JobTypeEnum {
  GIG = 'GIG',
  LTA = 'LTA',
}

/** Autogenerated return type of JobUnpublish */
export type JobUnpublishPayload = {
  __typename: 'JobUnpublishPayload';
  job: Job;
};

/** Autogenerated return type of JobUpdate */
export type JobUpdatePayload = {
  __typename: 'JobUpdatePayload';
  job: Job;
};

/** Job worker */
export type JobWorker = {
  __typename: 'JobWorker';
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  checkInStatus: JobWorkerCheckInStatusEnum;
  costRate: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  dismissReason: Maybe<Scalars['String']>;
  dismissedAt: Maybe<Scalars['ISO8601DateTime']>;
  dismissedBy: Maybe<User>;
  dropReason: Maybe<Scalars['String']>;
  droppedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  job: Job;
  jobs: JobPage;
  noShowMarkedAt: Maybe<Scalars['ISO8601DateTime']>;
  noShowMarkedBy: Maybe<User>;
  order: Order;
  payRate: Scalars['Int'];
  paydate: Maybe<Scalars['ISO8601DateTime']>;
  showed: Maybe<Scalars['Boolean']>;
  status: JobWorkerStatusEnum;
  timesheets: Array<Timesheet>;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

/** Job worker */
export type JobWorkerJobsArgs = {
  filters: Maybe<JobFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

export enum JobWorkerCheckInStatusEnum {
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  NOT_CHECKED_IN = 'NOT_CHECKED_IN',
  NO_SHOW = 'NO_SHOW',
}

/** Available filter operations for a JobWorker */
export type JobWorkerFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
  breakMinutes?: Maybe<IntCompareFilterInput>;
  checkinAt?: Maybe<DateTimeCompareFilterInput>;
  checkoutAt?: Maybe<DateTimeCompareFilterInput>;
  costRate?: Maybe<IntCompareFilterInput>;
  currentJobs?: Maybe<BooleanEqualityFilterInput>;
  dismissed?: Maybe<Scalars['Boolean']>;
  dropped?: Maybe<Scalars['Boolean']>;
  firstShiftStartAt?: Maybe<DateTimeCompareFilterInput>;
  jobApplication?: Maybe<JobApplicationEqualityFilterInput>;
  jobStatus?: Maybe<JobStatusEqualityFilterInput>;
  lastShiftEndAt?: Maybe<DateTimeCompareFilterInput>;
  minutes?: Maybe<IntCompareFilterInput>;
  overtimeMinutes?: Maybe<IntCompareFilterInput>;
  payRate?: Maybe<IntCompareFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  rating?: Maybe<IntCompareFilterInput>;
  reportedEndAt?: Maybe<DateTimeCompareFilterInput>;
  reportedStartAt?: Maybe<DateTimeCompareFilterInput>;
  status?: Maybe<JobWorkerStatusEqualityFilterInput>;
  surge?: Maybe<BooleanEqualityFilterInput>;
  tipAmount?: Maybe<IntCompareFilterInput>;
};

/** Pagination container for JobWorker */
export type JobWorkerPage = {
  __typename: 'JobWorkerPage';
  items: Array<JobWorker>;
  pageInfo: PageInfo;
};

export enum JobWorkerStatusEnum {
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  DISMISSED = 'DISMISSED',
  DROPPED = 'DROPPED',
  EXPIRED = 'EXPIRED',
  HIRED = 'HIRED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
}

/** Tests field for equality to a given JobStatus */
export type JobWorkerStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: JobWorkerStatusEnum;
};

/** Worker interview */
export type Jurisdiction = {
  __typename: 'Jurisdiction';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  state: Scalars['String'];
  tenant: Tenant;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum LanguageEnum {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
}

/** A spatial multi-polygon */
export type MultiPolygon = {
  __typename: 'MultiPolygon';
  polygons: Array<Polygon>;
};

export type MultiPolygonInput = {
  polygons: Array<PolygonInput>;
};

/** The mutation root of this schema */
export type Mutation = {
  __typename: 'Mutation';
  /** Attach a bank account to a billing account */
  accountAttachBankAccount: AccountAttachBankAccountPayload;
  /** Attach a credit/debit card to a customer billing account */
  accountAttachCard: AccountAttachCardPayload;
  /** Create an account */
  accountCreate: AccountCreatePayload;
  /** Update account cut off duration */
  accountCutOffUpdate: AccountCutOffUpdatePayload;
  /** Detach a payment method from a customer billing account */
  accountDetachPaymentMethod: AccountDetachPaymentMethodPayload;
  /** Set the current payment method for a billing account */
  accountSetCurrentPaymentMethod: AccountSetCurrentPaymentMethodPayload;
  /** Update an account */
  accountUpdate: AccountUpdatePayload;
  /** Enable/Re-enable account */
  accountUpdateActive: AccountUpdateActivePayload;
  /** Create an address */
  addressCreate: AddressCreatePayload;
  /** Delete an address */
  addressDelete: AddressDeletePayload;
  /** Update an address */
  addressUpdate: AddressUpdatePayload;
  /** Validate an address and create Check Workplace if valid */
  addressValidate: AddressValidatePayload;
  /** Change the payment method for an invoice. For QA purposes. */
  adminChangePaymentMethod: AdminChangePaymentMethodPayload;
  /** Edit number workers of a job posted */
  adminEditNumberWorkersJob: AdminEditNumberWorkersJobPayload;
  /** Edit pay/cost rate of a job posted */
  adminEditRateOfJob: AdminEditRateOfJobPayload;
  /** Edit date and time of a job posted */
  adminEditTimeOfJob: AdminEditTimeOfJobPayload;
  /** Authenticate either a tenant admin or a customer admin */
  adminLogin: AdminLoginPayload;
  /** Mark worker as showed. */
  adminMarkWorkerAsShowed: AdminMarkWorkerAsShowedPayload;
  /** Reset a worker password */
  adminResetWorkerPassword: Maybe<Scalars['Void']>;
  /** Create an agency */
  agencyCreate: AgencyCreatePayload;
  /** Update an agency */
  agencyUpdate: AgencyUpdatePayload;
  /** Create a new badge */
  badgeCreate: BadgeCreatePayload;
  /** Add a badge to a badgeable model */
  badgeableAddBadge: BadgeableAddBadgePayload;
  /** Change the badges on a badgeable model */
  badgeableChangeBadges: BadgeableChangeBadgesPayload;
  /** Remove a badge from a badgeable model */
  badgeableRemoveBadge: BadgeableRemoveBadgePayload;
  /** Create a chat group with GravyWork support staff */
  chatGroupSupportCreate: ChatGroupSupportCreatePayload;
  /** Create a chat service auth token */
  chatServiceAuthenticate: Scalars['String'];
  /** Create a chat service auth token for agency support */
  chatServiceAuthenticateAgency: Scalars['String'];
  /** Add a comment to a commentable target */
  commentableAddComment: CommentableAddCommentPayload;
  /** Create custom rates to all addresses from a customer by zip code. */
  customRateCreateBatch: CustomRateCreateBatchPayload;
  /** Change a customer admin's avatar */
  customerAdminChangeAvatar: CustomerAdminChangeAvatarPayload;
  /** Authenticate a customer admin */
  customerAdminLogin: CustomerAdminLoginPayload;
  /** Resend an invitation to an user who has not logged to be a customer admin */
  customerAdminResendInvitation: CustomerAdminResendInvitationPayload;
  /** Update a customer admin's profile */
  customerAdminUpdateProfile: CustomerAdminUpdateProfilePayload;
  /** Change a customer's avatar */
  customerChangeLogo: CustomerChangeLogoPayload;
  /** Create a customer */
  customerCreate: CustomerCreatePayload;
  /** Invite a customer admin */
  customerInviteAdmin: CustomerInviteAdminPayload;
  /** Create a worker relationship for this customer */
  customerRelateWorker: CustomerRelateWorkerPayload;
  /** Sign up a customer and send the invitation for its customer admin */
  customerSignup: CustomerSignupPayload;
  /** Delete a worker relationship for this customer */
  customerUnrelateWorker: Maybe<Scalars['Void']>;
  /** Update a customer */
  customerUpdate: CustomerUpdatePayload;
  /** Update a customer job tax type */
  customerUpdateJobTaxType: CustomerUpdateJobTaxTypePayload;
  /** Create a rate */
  defaultRateCreate: DefaultRateCreatePayload;
  /** Update a default rate */
  defaultRateUpdate: DefaultRateUpdatePayload;
  /** Log out of an active session */
  deviceLogout: Maybe<Scalars['Void']>;
  /** Log out from all sessions */
  deviceLogoutAll: Maybe<Scalars['Void']>;
  /** Update the FCM token of current device */
  deviceUpdateFcmToken: Maybe<Scalars['Void']>;
  /** Create an earning code */
  earningCodeCreate: Scalars['String'];
  /** Generate invoices manually */
  invoiceGenerate: InvoiceGeneratePayload;
  /** Pay an Invoice */
  invoicePay: InvoicePayPayload;
  /** Create a pdf with invoice timesheets */
  invoiceTimesheetsPdf: InvoiceTimesheetsPdfPayload;
  /** Add a subscriber to a job */
  jobAddSubscriber: JobAddSubscriberPayload;
  /** Cancel job */
  jobCancel: JobCancelPayload;
  /** Cancel batch job */
  jobCancelBatch: JobCancelBatchPayload;
  /** Dismiss a worker from a job */
  jobDismissWorker: Maybe<Scalars['Void']>;
  /** Hire a worker for a job */
  jobHireWorker: JobHireWorkerPayload;
  /** Create a job offer */
  jobOfferCreate: JobOfferCreatePayload;
  /** Create a group of job offer */
  jobOfferGroupCreate: JobOfferGroupCreatePayload;
  /** Post job to workers */
  jobPublish: JobPublishPayload;
  /** Add a subscriber to a job */
  jobRemoveSubscriber: JobRemoveSubscriberPayload;
  /** Accept a job request */
  jobRequestAccept: JobRequestAcceptPayload;
  /** Reject a job request */
  jobRequestReject: JobRequestRejectPayload;
  /** Remove job from workers board */
  jobUnpublish: JobUnpublishPayload;
  /** Update job details */
  jobUpdate: JobUpdatePayload;
  /** Create an order */
  orderCreate: OrderCreatePayload;
  /** Attach a bank account to a customer admin */
  paymentBankAccountAttach: PaymentBankAccountAttachPayload;
  /** Detach a bank account from a customer admin */
  paymentBankAccountDetach: PaymentBankAccountDetachPayload;
  /** Updates the status of a payment intent to processing and saves the corresponding mandate record */
  paymentIntentAchProcessUpdate: Scalars['Void'];
  /** Create a record of a payment intent to pay an invoice */
  paymentIntentCreate: PaymentIntentCreatePayload;
  /** Attach a credit/debit card to a customer admin */
  paymentMethodAttach: PaymentMethodAttachPayload;
  /** Save the current payment method */
  paymentMethodCurrent: PaymentMethodCurrentPayload;
  /** Detach a payment method from a customer admin */
  paymentMethodDetach: PaymentMethodDetachPayload;
  /** Approves a payroll. */
  payrollApprove: PayrollApprovePayload;
  /** Creates a payroll. */
  payrollCreate: PayrollCreatePayload;
  /** Deletes a payroll (You may delete payrolls only when they are in the draft status). */
  payrollDelete: Maybe<Scalars['Void']>;
  /** Cancel a rate */
  rateCancel: RateCancelPayload;
  /** Create a rate (Deprecated) */
  rateCreate: RateCreatePayload;
  /** Delete a rate */
  rateDelete: RateDeletePayload;
  /** Update a rate */
  rateUpdate: RateUpdatePayload;
  /** Create a referral */
  referralCreate: ReferralCreatePayload;
  /** Send referrals and personalized emails */
  sendMail: Scalars['Void'];
  /** Send checkin notification manually */
  sendManualCheckinNotification: Scalars['Void'];
  /** Create a service area */
  serviceAreaCreate: ServiceAreaCreatePayload;
  /** Update a service area */
  serviceAreaUpdate: ServiceAreaUpdatePayload;
  /** Create a skill category */
  skillCategoryCreate: SkillCategoryCreatePayload;
  /** Update a skill category */
  skillCategoryUpdate: SkillCategoryUpdatePayload;
  /** Create a skill */
  skillCreate: SkillCreatePayload;
  /** Accept a skill request */
  skillRequestAccept: SkillRequestAcceptPayload;
  /** Reject a skill request */
  skillRequestReject: SkillRequestRejectPayload;
  /** Update a skill */
  skillUpdate: SkillUpdatePayload;
  /** Finalize an invoice to change status from Draft to Open */
  stripeInvoiceFinalize: StripeInvoiceFinalizePayload;
  /** Create a new invoice item for an invoice */
  stripeInvoiceItemCreate: StripeInvoiceItemCreatePayload;
  /** Deletes an invoice item */
  stripeInvoiceItemDelete: StripeInvoiceItemDeletePayload;
  /** Updates an invoice item */
  stripeInvoiceItemUpdate: StripeInvoiceItemUpdatePayload;
  /** Mark an invoice as paid manually */
  stripeInvoiceMarkAsPaid: StripeInvoiceMarkAsPaidPayload;
  /** Update a Draft Stripe invoice */
  stripeInvoiceUpdate: StripeInvoiceUpdatePayload;
  /** Mark a finalized invoice as void and recreate it without adding the manual added items */
  stripeInvoiceVoid: StripeInvoiceVoidPayload;
  /** Create GravyWork invoice again recreate it without adding the manual added items */
  stripeUnlinkInvoice: StripeUnlinkInvoicePayload;
  /** Create a new surge rate */
  surgeRatesCreate: SurgeRatesCreatePayload;
  /** Delete a Surge Rate */
  surgeRatesDelete: SurgeRatesDeletePayload;
  /** Create a new surge rate */
  surgeRatesUpdate: SurgeRatesUpdatePayload;
  /** Switch app_approve for invoice auto finish */
  switchAutoApprove: SwitchAutoApprovePayload;
  /** Create a new tag */
  tagCreate: TagCreatePayload;
  /** Add a tag to a taggable model */
  taggableAddTag: TaggableAddTagPayload;
  /** Change the tags on a taggable model */
  taggableChangeTags: TaggableChangeTagsPayload;
  /** Remove a tag from a taggable model */
  taggableRemoveTag: TaggableRemoveTagPayload;
  /** Change a tenant admin's avatar */
  tenantAdminChangeAvatar: TenantAdminChangeAvatarPayload;
  /** Invite a new tenant admin */
  tenantAdminInvite: TenantAdminInvitePayload;
  /** Resend an invitation to an user to be customer admin */
  tenantAdminResendInvitation: TenantAdminResendInvitationPayload;
  /** Update a tenant */
  tenantUpdate: TenantUpdatePayload;
  /** Approve a timesheet and make calculations for invoice and payroll */
  timesheetApprove: TimesheetApprovePayload;
  /** Create a new timesheet comment */
  timesheetCommentsAdd: TimesheetCommentsAddPayload;
  /** Create a timesheet from scratch */
  timesheetCreate: TimesheetCreatePayload;
  /** Reject a timesheet */
  timesheetReject: TimesheetRejectPayload;
  /** Update a timesheet tip */
  timesheetTipUpdate: TimesheetTipUpdatePayload;
  /** Unnaprove a timesheet and reset the approved time. */
  timesheetUnapprove: TimesheetUnapprovePayload;
  /** Unreject a timesheet */
  timesheetUnreject: TimesheetUnrejectPayload;
  /** Update a timesheet */
  timesheetUpdate: TimesheetUpdatePayload;
  /** Edit, Approve, Unapprove, Reject and Unreject the timesheets in bulk */
  timesheetsBulkEdit: TimesheetsBulkEditPayload;
  /** reset a password of an authenticated user */
  userAuthResetPassword: UserAuthResetPasswordPayload;
  /** Change an user's active status */
  userChangeActive: UserChangeActivePayload;
  /** Change current user password */
  userChangePassword: Maybe<Scalars['Void']>;
  /** Allow to reset a password of an user and receive it by email */
  userForgotPassword: Maybe<Scalars['Void']>;
  /** Send a URL with a token to a user without validated email */
  userResendToken: Maybe<Scalars['Void']>;
  /** Reset password of user matching the given token */
  userResetPassword: Maybe<Scalars['Void']>;
  /** Update an user */
  userUpdate: UserUpdatePayload;
  /** Validates the address on check */
  validateAddress: ValidateAddressPayload;
  /** Add a certificate to a worker */
  workerAddCertificate: WorkerAddCertificatePayload;
  /** Create a worker reference */
  workerAddReference: WorkerAddReferencePayload;
  /** Save an array of work references */
  workerAddReferenceList: WorkerAddReferenceListPayload;
  /** Add a skill to a worker */
  workerAddSkill: WorkerAddSkillPayload;
  /** Manually approve a worker's I9 form. For QA purposes. */
  workerApproveFormI9: WorkerApproveFormI9Payload;
  /** Assign a job workplace to a worker */
  workerAssignJobWorkplace: WorkerAssignJobWorkplacePayload;
  /** Update a worker's active status */
  workerChangeActive: WorkerChangeActivePayload;
  /** Change a worker's address */
  workerChangeAddress: WorkerChangeAddressPayload;
  /** Change a worker's avatar */
  workerChangeAvatar: WorkerChangeAvatarPayload;
  /** Manually mark closed I9 form for a worker. For QA purposes. */
  workerClosedFormI9: WorkerClosedFormI9Payload;
  /** Create a worker */
  workerCreate: WorkerCreatePayload;
  /** Create an interview at Willo to a worker */
  workerCreateWilloInterview: WorkerCreateWilloInterviewPayload;
  /** Disable a worker is available by tenant admin */
  workerDisable: WorkerDisablePayload;
  /** Manually disapprove a worker's I9 form. For QA purposes. */
  workerDisapproveFormI9: WorkerDisapproveFormI9Payload;
  /** Enable a worker is available by tenant admin */
  workerEnable: WorkerEnablePayload;
  /** Create an interview for an applicant worker */
  workerInterviewCreate: WorkerInterviewCreatePayload;
  /** Update an interview for an applicant worker */
  workerInterviewUpdate: WorkerInterviewUpdatePayload;
  /** Evaluate an interview (Video Questionnaire) for an applicant worker */
  workerInterviewUpdateV2: WorkerInterviewUpdateV2Payload;
  /** Authenticate a worker */
  workerLogin: WorkerLoginPayload;
  /** Worker Onboard */
  workerOnboard: WorkerOnboardPayload;
  /** Set prospect status to a worker's I9 form. For QA purposes */
  workerProspectFormI9: WorkerProspectFormI9Payload;
  /** Remove a certificate from a worker */
  workerRemoveCertificate: WorkerRemoveCertificatePayload;
  /** Remove a worker reference */
  workerRemoveReference: Maybe<Scalars['Void']>;
  /** Remove a skill from a worker */
  workerRemoveSkill: WorkerRemoveSkillPayload;
  /** Resend a Checkr invitation to a worker */
  workerResendCheckrInvitation: WorkerResendCheckrInvitationPayload;
  /** Worker self signup */
  workerSignup: WorkerSignupPayload;
  /** Manually mark as TENTATIVE_NC I9 form for a worker. For QA purposes. */
  workerTentativeNcFormI9: WorkerTentativeNcFormI9Payload;
  /** Update an applicant worker */
  workerUpdateApplicant: WorkerUpdateApplicantPayload;
  /** Update a worker's background check status */
  workerUpdateBackgroundCheck: WorkerUpdateBackgroundCheckPayload;
  /** Update a candidate worker */
  workerUpdateCandidate: WorkerUpdateCandidatePayload;
  /** Modify the onboarding status if the worker scheduled an interview */
  workerUpdateCheckInterview: WorkerUpdateCheckInterviewPayload;
  /** Update a worker's profile */
  workerUpdateProfile: WorkerUpdateProfilePayload;
  /** Update worker's rating */
  workerUpdateRating: WorkerUpdateRatingPayload;
  /** Update a worker's status */
  workerUpdateStatus: WorkerUpdateStatusPayload;
  /** Update a worker's tax type */
  workerUpdateTaxType: WorkerUpdateTaxTypePayload;
  /** Modify the onboarding status if the worker completed their interview */
  workerUpdateWilloInterview: WorkerUpdateWilloInterviewPayload;
  /** Verify worker mobile code */
  workerVerify: WorkerVerifyPayload;
};

/** The mutation root of this schema */
export type MutationAccountAttachBankAccountArgs = {
  accountId: Scalars['ID'];
  bankAccountId: Scalars['ID'];
  publicToken: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationAccountAttachCardArgs = {
  accountId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationAccountCreateArgs = {
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  billingWeek: Maybe<BillingWeekEnum>;
  city: Scalars['String'];
  consolidateUnprocessedCharges: Maybe<Scalars['Boolean']>;
  customerId: Scalars['ID'];
  cutOff: Maybe<InvoiceCutOffEnum>;
  defaultContactId: Scalars['ID'];
  groupBy: Maybe<InvoiceGroupByEnum>;
  markupPercent?: Maybe<Scalars['Float']>;
  monthlyCutOff: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  rateType: AccountRateTypeEnum;
  state: Scalars['String'];
  zip: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationAccountCutOffUpdateArgs = {
  accountId: Scalars['ID'];
  billingWeek: Maybe<BillingWeekEnum>;
  consolidateUnprocessedCharges: Maybe<Scalars['Boolean']>;
  cutOff: Maybe<InvoiceCutOffEnum>;
  groupBy: Maybe<InvoiceGroupByEnum>;
  monthlyCutOff: Maybe<Scalars['Boolean']>;
};

/** The mutation root of this schema */
export type MutationAccountDetachPaymentMethodArgs = {
  accountId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
  paymentMethodType: PaymentMethodEnum;
};

/** The mutation root of this schema */
export type MutationAccountSetCurrentPaymentMethodArgs = {
  accountId: Scalars['ID'];
  paymentMethodId: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationAccountUpdateArgs = {
  accountId: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  defaultContactId: Scalars['ID'];
  markupPercent: Maybe<Scalars['Float']>;
  name: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zip: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationAccountUpdateActiveArgs = {
  accountId: Scalars['ID'];
  active: Scalars['Boolean'];
};

/** The mutation root of this schema */
export type MutationAddressCreateArgs = {
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  coords: PointInput;
  customerId: Scalars['ID'];
  name: Scalars['String'];
  neighborhood: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zip: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationAddressDeleteArgs = {
  addressId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationAddressUpdateArgs = {
  addressId: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  coords: PointInput;
  name: Scalars['String'];
  neighborhood: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zip: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationAddressValidateArgs = {
  addressId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationAdminChangePaymentMethodArgs = {
  invoiceId: Scalars['ID'];
  paymentMethodToken: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationAdminEditNumberWorkersJobArgs = {
  jobId: Scalars['ID'];
  numberWorkers: Scalars['Int'];
};

/** The mutation root of this schema */
export type MutationAdminEditRateOfJobArgs = {
  costRate: Maybe<Scalars['PositiveInt']>;
  isRateLock?: Maybe<Scalars['Boolean']>;
  jobId: Scalars['ID'];
  markupPercent?: Maybe<Scalars['Float']>;
  originalCostRate: Maybe<Scalars['PositiveInt']>;
  originalPayRate: Maybe<Scalars['PositiveInt']>;
  payRate: Maybe<Scalars['PositiveInt']>;
};

/** The mutation root of this schema */
export type MutationAdminEditTimeOfJobArgs = {
  firstShiftStartAt: Scalars['ISO8601DateTime'];
  jobId: Scalars['ID'];
  lastShiftEndAt: Scalars['ISO8601DateTime'];
  mandatoryBreakTime: Maybe<Scalars['Minutes']>;
  timeZoneOffset: Maybe<Scalars['Float']>;
};

/** The mutation root of this schema */
export type MutationAdminLoginArgs = {
  email: Scalars['Email'];
  fcmToken: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tenantId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationAdminMarkWorkerAsShowedArgs = {
  jobWorkerId: Scalars['ID'];
  showed: Scalars['Boolean'];
};

/** The mutation root of this schema */
export type MutationAdminResetWorkerPasswordArgs = {
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationAgencyCreateArgs = {
  key: Scalars['Key'];
  name: Scalars['String'];
  tenantId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationAgencyUpdateArgs = {
  agencyId: Scalars['ID'];
  name: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationBadgeCreateArgs = {
  agencyId: Scalars['ID'];
  category: Maybe<BadgeCategoryEnum>;
  description: Maybe<Scalars['String']>;
  name: Scalars['String'];
  points: Maybe<Scalars['Int']>;
};

/** The mutation root of this schema */
export type MutationBadgeableAddBadgeArgs = {
  badgeableId: Scalars['ID'];
  badgeableType: BadgeableEnum;
  tagId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationBadgeableChangeBadgesArgs = {
  addBadgeIds: Maybe<Array<Scalars['PositiveInt']>>;
  badgeableId: Scalars['ID'];
  badgeableType: BadgeableEnum;
  removeBadgeIds: Maybe<Array<Scalars['PositiveInt']>>;
};

/** The mutation root of this schema */
export type MutationBadgeableRemoveBadgeArgs = {
  badgeId: Scalars['ID'];
  badgeableId: Scalars['ID'];
  badgeableType: BadgeableEnum;
};

/** The mutation root of this schema */
export type MutationChatGroupSupportCreateArgs = {
  chatUid: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationChatServiceAuthenticateAgencyArgs = {
  agencyId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationCommentableAddCommentArgs = {
  body: Scalars['String'];
  commentableId: Scalars['ID'];
  commentableType: CommentableEnum;
};

/** The mutation root of this schema */
export type MutationCustomRateCreateBatchArgs = {
  accountId: Scalars['ID'];
  agencyId: Scalars['ID'];
  baseCost: Scalars['PositiveInt'];
  basePay: Scalars['PositiveInt'];
  level: Scalars['PositiveInt'];
  serviceAreaId: Scalars['ID'];
  skillId: Scalars['ID'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationCustomerAdminChangeAvatarArgs = {
  avatarKey: Scalars['String'];
  customerAdminId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationCustomerAdminLoginArgs = {
  customerId: Scalars['ID'];
  email: Scalars['Email'];
  fcmToken: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationCustomerAdminResendInvitationArgs = {
  userId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationCustomerAdminUpdateProfileArgs = {
  customerAdminId: Scalars['ID'];
  department: Maybe<Scalars['String']>;
  email: Maybe<Scalars['Email']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationCustomerChangeLogoArgs = {
  customerId: Scalars['ID'];
  logoKey: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationCustomerCreateArgs = {
  agencyId: Scalars['ID'];
  name: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationCustomerInviteAdminArgs = {
  customerId: Scalars['ID'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationCustomerRelateWorkerArgs = {
  comments: Maybe<Scalars['String']>;
  customerId: Scalars['ID'];
  kind: RelationshipKindEnum;
  reason: Maybe<Scalars['String']>;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationCustomerSignupArgs = {
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  agencyId: Scalars['ID'];
  city: Scalars['String'];
  coords: PointInput;
  customerName: Scalars['String'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  neighborhood: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  referral: Maybe<ReferralEnum>;
  state: Scalars['String'];
  zip: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationCustomerUnrelateWorkerArgs = {
  customerId: Scalars['ID'];
  kind: Maybe<RelationshipKindEnum>;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationCustomerUpdateArgs = {
  customerId: Scalars['ID'];
  invoiceDueDate: Maybe<DefaultDueDateEnum>;
  jobTaxType: Maybe<TaxTypeEnum>;
  ltaAllowed: Maybe<Scalars['Boolean']>;
  markupPercent?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationCustomerUpdateJobTaxTypeArgs = {
  customerId: Scalars['ID'];
  jobTaxType: TaxTypeEnum;
};

/** The mutation root of this schema */
export type MutationDefaultRateCreateArgs = {
  costRate: Maybe<Scalars['PositiveInt']>;
  payRate: Maybe<Scalars['PositiveInt']>;
  skillId: Scalars['ID'];
  tenantId: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationDefaultRateUpdateArgs = {
  costRate: Maybe<Scalars['PositiveInt']>;
  defaultRate: Scalars['ID'];
  payRate: Maybe<Scalars['PositiveInt']>;
};

/** The mutation root of this schema */
export type MutationDeviceLogoutAllArgs = {
  userId: Maybe<Scalars['ID']>;
};

/** The mutation root of this schema */
export type MutationDeviceUpdateFcmTokenArgs = {
  fcmToken: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationEarningCodeCreateArgs = {
  name: Scalars['String'];
  skillId: Scalars['ID'];
  type: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationInvoiceGenerateArgs = {
  accountId: Scalars['ID'];
  customerId: Scalars['ID'];
  timesheetIds: Array<Scalars['ID']>;
};

/** The mutation root of this schema */
export type MutationInvoicePayArgs = {
  invoice: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationInvoiceTimesheetsPdfArgs = {
  invoiceId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationJobAddSubscriberArgs = {
  agencyId: Scalars['ID'];
  jobId: Scalars['ID'];
  orderId: Scalars['ID'];
  shiftId: Scalars['ID'];
  subscriberEmail: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationJobCancelArgs = {
  jobId: Scalars['ID'];
  reason: Scalars['String'];
  userId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationJobCancelBatchArgs = {
  orderId: Scalars['ID'];
  reason: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationJobDismissWorkerArgs = {
  jobWorkerId: Scalars['ID'];
  reason: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationJobHireWorkerArgs = {
  forceHire?: Maybe<Scalars['Boolean']>;
  jobId: Scalars['ID'];
  payRate: Maybe<Scalars['PositiveInt']>;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationJobOfferCreateArgs = {
  costRate: Maybe<Scalars['PositiveInt']>;
  forceInvite?: Maybe<Scalars['Boolean']>;
  jobId: Scalars['ID'];
  payRate: Maybe<Scalars['PositiveInt']>;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationJobOfferGroupCreateArgs = {
  costRate: Maybe<Scalars['PositiveInt']>;
  jobIds: Array<Scalars['ID']>;
  payRate: Maybe<Scalars['PositiveInt']>;
  workerIds: Maybe<Array<Scalars['ID']>>;
};

/** The mutation root of this schema */
export type MutationJobPublishArgs = {
  jobId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationJobRemoveSubscriberArgs = {
  agencyId: Scalars['ID'];
  jobSubscriberId: Scalars['Int'];
  tenantId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationJobRequestAcceptArgs = {
  jobRequestId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationJobRequestRejectArgs = {
  jobRequestId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationJobUnpublishArgs = {
  jobId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationJobUpdateArgs = {
  addressId: Scalars['ID'];
  addressInstructions: Scalars['String'];
  contactId: Scalars['ID'];
  contactInstructions: Scalars['String'];
  instructions: Scalars['String'];
  jobId: Scalars['ID'];
  uniformId: Scalars['ID'];
  uniformInstructions: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationOrderCreateArgs = {
  accountId: Scalars['ID'];
  jobs: Array<JobInput>;
};

/** The mutation root of this schema */
export type MutationPaymentBankAccountAttachArgs = {
  accountId: Scalars['ID'];
  customerAdminId: Scalars['ID'];
  publicToken: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationPaymentBankAccountDetachArgs = {
  bankAccountId: Scalars['ID'];
  customerAdminId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationPaymentIntentAchProcessUpdateArgs = {
  clientSecret: Scalars['String'];
  invoiceId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationPaymentIntentCreateArgs = {
  invoiceId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationPaymentMethodAttachArgs = {
  customerAdminId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationPaymentMethodCurrentArgs = {
  customerAdminId: Scalars['ID'];
  paymentMethodId: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationPaymentMethodDetachArgs = {
  customerAdminId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationPayrollApproveArgs = {
  payrollId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationPayrollCreateArgs = {
  payday: Maybe<Scalars['ISO8601Date']>;
  weekDay: Scalars['ISO8601Date'];
};

/** The mutation root of this schema */
export type MutationPayrollDeleteArgs = {
  payrollId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationRateCancelArgs = {
  rateId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationRateCreateArgs = {
  accountId: Scalars['ID'];
  addressId: Scalars['ID'];
  agencyId: Scalars['ID'];
  baseCost: Scalars['PositiveInt'];
  basePay: Scalars['PositiveInt'];
  level: Scalars['PositiveInt'];
  serviceAreaId: Scalars['ID'];
  skillId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationRateDeleteArgs = {
  rateId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationRateUpdateArgs = {
  baseCost: Maybe<Scalars['PositiveInt']>;
  basePay: Maybe<Scalars['PositiveInt']>;
  rateId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationReferralCreateArgs = {
  prospectEmail: Scalars['String'];
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationSendMailArgs = {
  action: EmailActionEnum;
  body: Maybe<Scalars['String']>;
  emails: Array<Scalars['Email']>;
  link: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationSendManualCheckinNotificationArgs = {
  jobWorkerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationServiceAreaCreateArgs = {
  agencyId: Scalars['ID'];
  geometry: MultiPolygonInput;
  name: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationServiceAreaUpdateArgs = {
  name: Scalars['String'];
  serviceAreaId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationSkillCategoryCreateArgs = {
  agencyId: Scalars['ID'];
  iconUrl: Scalars['String'];
  name: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationSkillCategoryUpdateArgs = {
  name: Scalars['String'];
  skillCategoryId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationSkillCreateArgs = {
  iconUrl: Scalars['String'];
  name: Scalars['String'];
  skillCategoryId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationSkillRequestAcceptArgs = {
  skillRequestId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationSkillRequestRejectArgs = {
  skillRequestId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationSkillUpdateArgs = {
  name: Scalars['String'];
  skillId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceFinalizeArgs = {
  invoiceId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceItemCreateArgs = {
  amount: Scalars['String'];
  description: Scalars['String'];
  invoiceId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceItemDeleteArgs = {
  invoiceItemId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceItemUpdateArgs = {
  amount: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  invoiceItemId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceMarkAsPaidArgs = {
  invoiceId: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceUpdateArgs = {
  description: Maybe<Scalars['String']>;
  dueDate: Maybe<Scalars['ISO8601DateTime']>;
  invoiceId: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationStripeInvoiceVoidArgs = {
  invoiceId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationStripeUnlinkInvoiceArgs = {
  invoiceId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationSurgeRatesCreateArgs = {
  date: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  rate: Scalars['PositiveInt'];
};

/** The mutation root of this schema */
export type MutationSurgeRatesDeleteArgs = {
  surgeRateId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationSurgeRatesUpdateArgs = {
  date: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  rate: Maybe<Scalars['PositiveInt']>;
  surgeRateId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationSwitchAutoApproveArgs = {
  invoiceId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTagCreateArgs = {
  agencyId: Scalars['ID'];
  name: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationTaggableAddTagArgs = {
  tagId: Scalars['ID'];
  taggableId: Scalars['ID'];
  taggableType: TaggableEnum;
};

/** The mutation root of this schema */
export type MutationTaggableChangeTagsArgs = {
  addTagIds: Maybe<Array<Scalars['PositiveInt']>>;
  removeTagIds: Maybe<Array<Scalars['PositiveInt']>>;
  taggableId: Scalars['ID'];
  taggableType: TaggableEnum;
};

/** The mutation root of this schema */
export type MutationTaggableRemoveTagArgs = {
  tagId: Scalars['ID'];
  taggableId: Scalars['ID'];
  taggableType: TaggableEnum;
};

/** The mutation root of this schema */
export type MutationTenantAdminChangeAvatarArgs = {
  avatarKey: Scalars['String'];
  tenantAdminId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTenantAdminInviteArgs = {
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  roleId: UserRoleEnum;
  tenantId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTenantAdminResendInvitationArgs = {
  userId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTenantUpdateArgs = {
  name: Scalars['String'];
  tenantId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTimesheetApproveArgs = {
  approvedBreakMinutes: Scalars['NonNegativeInt'];
  approvedCheckinAt: Scalars['ISO8601DateTime'];
  approvedCheckoutAt: Scalars['ISO8601DateTime'];
  ratingComment: Maybe<Scalars['String']>;
  timesheetId: Scalars['ID'];
  tipAmount: Maybe<Scalars['NonNegativeInt']>;
};

/** The mutation root of this schema */
export type MutationTimesheetCommentsAddArgs = {
  body: Scalars['String'];
  timesheetId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTimesheetCreateArgs = {
  jobId: Scalars['ID'];
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTimesheetRejectArgs = {
  ratingComment: Maybe<Scalars['String']>;
  timesheetId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTimesheetTipUpdateArgs = {
  timesheetId: Scalars['ID'];
  tipAmount: Scalars['NonNegativeInt'];
};

/** The mutation root of this schema */
export type MutationTimesheetUnapproveArgs = {
  timesheetId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTimesheetUnrejectArgs = {
  timesheetId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationTimesheetUpdateArgs = {
  ratingComment: Maybe<Scalars['String']>;
  reportedBreakMinutes: Scalars['NonNegativeInt'];
  reportedCheckinAt: Scalars['ISO8601DateTime'];
  reportedCheckoutAt: Scalars['ISO8601DateTime'];
  timesheetId: Scalars['ID'];
  tipAmount: Maybe<Scalars['NonNegativeInt']>;
};

/** The mutation root of this schema */
export type MutationTimesheetsBulkEditArgs = {
  action: TimesheetsBulkEditActionEnum;
  jobId: Scalars['ID'];
  jobWorkerIds: Array<Scalars['ID']>;
  timesheetsInfo: Maybe<BulkEditTimesheetsInput>;
};

/** The mutation root of this schema */
export type MutationUserAuthResetPasswordArgs = {
  password: Scalars['String'];
  repeatedPassword: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationUserChangeActiveArgs = {
  active: Scalars['Boolean'];
  userId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationUserChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationUserForgotPasswordArgs = {
  app: TypeAppEnum;
  email: Scalars['Email'];
  platform: Maybe<SmartphoneTypeEnum>;
};

/** The mutation root of this schema */
export type MutationUserResendTokenArgs = {
  userId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationUserResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationUserUpdateArgs = {
  email: Maybe<Scalars['Email']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  roleId: UserRoleEnum;
  userId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationValidateAddressArgs = {
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  agencyId: Maybe<Scalars['ID']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationWorkerAddCertificateArgs = {
  certificateId: Scalars['ID'];
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerAddReferenceArgs = {
  city: Scalars['String'];
  duration: DurationEnum;
  email: Maybe<Scalars['Email']>;
  employerName: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  title: Scalars['String'];
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerAddReferenceListArgs = {
  references: Array<ReferenceInput>;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerAddSkillArgs = {
  skillId: Scalars['ID'];
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerApproveFormI9Args = {
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerAssignJobWorkplaceArgs = {
  jobId: Scalars['ID'];
  workerId: Maybe<Scalars['ID']>;
};

/** The mutation root of this schema */
export type MutationWorkerChangeActiveArgs = {
  active: Scalars['Boolean'];
  reason: Maybe<Scalars['String']>;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerChangeAddressArgs = {
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  coords: PointInput;
  state: Scalars['String'];
  workerId: Scalars['ID'];
  zip: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationWorkerChangeAvatarArgs = {
  avatarKey: Scalars['String'];
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerClosedFormI9Args = {
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerCreateArgs = {
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  agencyId: Scalars['ID'];
  city: Scalars['String'];
  coords: PointInput;
  dateOfBirth: Maybe<Scalars['ISO8601DateTime']>;
  email: Scalars['Email'];
  firstName: Scalars['String'];
  gender: GenderEnum;
  language: LanguageEnum;
  lastName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  ownsCar: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
  secondaryLanguage: Maybe<LanguageEnum>;
  skillIds: Maybe<Array<Scalars['ID']>>;
  state: Scalars['String'];
  zip: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationWorkerDisableArgs = {
  reason: Maybe<Scalars['String']>;
  reenableDate: Maybe<Scalars['ISO8601Date']>;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerDisapproveFormI9Args = {
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerEnableArgs = {
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerInterviewCreateArgs = {
  answers: Array<InterviewAnswerInput>;
  date: Scalars['ISO8601DateTime'];
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerInterviewUpdateArgs = {
  answers: Maybe<Array<InterviewAnswerInput>>;
  date: Maybe<Scalars['ISO8601DateTime']>;
  status: Maybe<InterviewStatusEnum>;
  workerInterviewId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerInterviewUpdateV2Args = {
  status: InterviewStatusEnum;
  willoWorkerInterviewId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerLoginArgs = {
  agencyId: Scalars['ID'];
  email: Scalars['Email'];
  fcmToken: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

/** The mutation root of this schema */
export type MutationWorkerOnboardArgs = {
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerProspectFormI9Args = {
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerRemoveCertificateArgs = {
  certificateId: Scalars['ID'];
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerRemoveReferenceArgs = {
  workerReferenceId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerRemoveSkillArgs = {
  skillId: Scalars['ID'];
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerResendCheckrInvitationArgs = {
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerSignupArgs = {
  agencyId: Scalars['ID'];
  email: Scalars['Email'];
  fcmToken: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  language: LanguageEnum;
  lastName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  password: Scalars['String'];
  zipCode: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationWorkerTentativeNcFormI9Args = {
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateApplicantArgs = {
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  candidateStatus: CandidateStatusEnum;
  city: Maybe<Scalars['String']>;
  dateOfBirth: Maybe<Scalars['DateOrEmptyString']>;
  email: Maybe<Scalars['Email']>;
  firstName: Maybe<Scalars['String']>;
  hasSsn: Maybe<Scalars['Boolean']>;
  language: LanguageEnum;
  lastName: Maybe<Scalars['String']>;
  middleName: Maybe<Scalars['String']>;
  ownsCar: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
  secondaryLanguage: Maybe<LanguageEnum>;
  skillIds: Array<Scalars['ID']>;
  smartphoneType: SmartphoneTypeEnum;
  state: Maybe<Scalars['String']>;
  taxType: TaxTypeEnum;
  usCitizenOrWorkPermit: Maybe<Scalars['Boolean']>;
  workerId: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationWorkerUpdateBackgroundCheckArgs = {
  backgroundStatus: BackgroundStatusEnum;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateCandidateArgs = {
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  candidateStatus: CandidateStatusEnum;
  city: Maybe<Scalars['String']>;
  dateOfBirth: Maybe<Scalars['ISO8601DateTime']>;
  email: Scalars['Email'];
  firstName: Scalars['String'];
  language: Maybe<LanguageEnum>;
  lastName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  secondaryLanguage: Maybe<LanguageEnum>;
  smartphoneType: Maybe<SmartphoneTypeEnum>;
  state: Maybe<Scalars['String']>;
  workerId: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
};

/** The mutation root of this schema */
export type MutationWorkerUpdateCheckInterviewArgs = {
  email: Scalars['Email'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateProfileArgs = {
  dateOfBirth: Scalars['ISO8601DateTime'];
  email: Maybe<Scalars['Email']>;
  firstName: Scalars['String'];
  gender: Maybe<GenderEnum>;
  hasSsn: Maybe<Scalars['Boolean']>;
  heardFrom: Maybe<Scalars['String']>;
  language: Maybe<LanguageEnum>;
  lastName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  ownsCar: Maybe<Scalars['Boolean']>;
  secondaryLanguage: Maybe<LanguageEnum>;
  smartphoneType: Maybe<SmartphoneTypeEnum>;
  usCitizenOrWorkPermit: Maybe<Scalars['Boolean']>;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateRatingArgs = {
  body: Maybe<Scalars['String']>;
  rating: Maybe<Scalars['Rating']>;
  timesheetId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateStatusArgs = {
  i9Status: Maybe<I9StatusEnum>;
  interviewStatus: Maybe<InterviewStatusEnum>;
  onboardingStatus: Maybe<OnboardingStatusEnum>;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateTaxTypeArgs = {
  hasSsn: Maybe<Scalars['Boolean']>;
  taxType: TaxTypeEnum;
  workerId: Scalars['ID'];
};

/** The mutation root of this schema */
export type MutationWorkerUpdateWilloInterviewArgs = {
  email: Scalars['Email'];
};

/** The mutation root of this schema */
export type MutationWorkerVerifyArgs = {
  code: Scalars['String'];
};

export enum OnboardingStatusEnum {
  DEFAULT_STATUS = 'DEFAULT_STATUS',
  INTERVIEW_DECLINED = 'INTERVIEW_DECLINED',
  INTERVIEW_PASSED = 'INTERVIEW_PASSED',
  INTERVIEW_REQUESTED = 'INTERVIEW_REQUESTED',
  INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED',
  ONBOARDING_IN_PROGRESS = 'ONBOARDING_IN_PROGRESS',
  PENDING_BACKGROUND_CHECK = 'PENDING_BACKGROUND_CHECK',
  PENDING_DOCUMENTS = 'PENDING_DOCUMENTS',
  PENDING_FORMI9 = 'PENDING_FORMI9',
  PENDING_PAYMENT_METHOD = 'PENDING_PAYMENT_METHOD',
  PENDING_PHONE_VERIFY = 'PENDING_PHONE_VERIFY',
  PENDING_PROFILE_COMPLETITION = 'PENDING_PROFILE_COMPLETITION',
  PENDING_SSN = 'PENDING_SSN',
  PENDING_TAX_WITHHOLDING = 'PENDING_TAX_WITHHOLDING',
  PENDING_WORKPLACE = 'PENDING_WORKPLACE',
}

/** Order */
export type Order = {
  __typename: 'Order';
  account: Account;
  agency: Agency;
  cancelledAt: Maybe<Scalars['ISO8601DateTime']>;
  comments: Array<Comment>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  endAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  jobs: Array<Job>;
  jobsCount: Scalars['PositiveInt'];
  markupPercent: Maybe<Scalars['Float']>;
  startAt: Scalars['ISO8601DateTime'];
  status: OrderStatusEnum;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
  uuid: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OrderCreate */
export type OrderCreatePayload = {
  __typename: 'OrderCreatePayload';
  order: Order;
  workersHired: Maybe<Scalars['String']>;
};

/** Available filter operations for a Order */
export type OrderFilterSetInput = {
  account?: Maybe<IdEqualityFilterInput>;
  agency?: Maybe<IdEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<IdEqualityFilterInput>;
  endAt?: Maybe<DateTimeCompareFilterInput>;
  jobsCount?: Maybe<IntCompareFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  startAt?: Maybe<DateTimeCompareFilterInput>;
  status?: Maybe<OrderStatusEqualityFilterInput>;
  tags?: Maybe<IdMembershipFilterInput>;
  uuid?: Maybe<TextSearchFilterInput>;
};

/** Pagination container for Order */
export type OrderPage = {
  __typename: 'OrderPage';
  items: Array<Order>;
  pageInfo: PageInfo;
};

export enum OrderSortKeyEnum {
  CREATED_AT = 'CREATED_AT',
  END_AT = 'END_AT',
  ID = 'ID',
  JOBS_COUNT = 'JOBS_COUNT',
  START_AT = 'START_AT',
}

export enum OrderStatusEnum {
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  NA = 'NA',
  NO_SHOW = 'NO_SHOW',
  NO_TIMESHEET = 'NO_TIMESHEET',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  TIMESHEET_UNFINISHED = 'TIMESHEET_UNFINISHED',
}

/** Field for equality to a given Order Status */
export type OrderStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: OrderStatusEnum;
};

/** Orientation */
export type Orientation = {
  __typename: 'Orientation';
  agency: Maybe<Agency>;
  candidates: Array<Candidate>;
  createdAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  seats: Scalars['Int'];
  seatsRemaining: Scalars['Int'];
  site: Maybe<OrientationSite>;
  startAt: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Orientation site */
export type OrientationSite = {
  __typename: 'OrientationSite';
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  agency: Maybe<Agency>;
  city: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  maxSeats: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  orientations: Array<Orientation>;
  state: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  zip: Maybe<Scalars['String']>;
};

/** Page metadata */
export type PageInfo = {
  __typename: 'PageInfo';
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Autogenerated return type of PaymentBankAccountAttach */
export type PaymentBankAccountAttachPayload = {
  __typename: 'PaymentBankAccountAttachPayload';
  attach: Scalars['Boolean'];
};

/** Autogenerated return type of PaymentBankAccountDetach */
export type PaymentBankAccountDetachPayload = {
  __typename: 'PaymentBankAccountDetachPayload';
  detach: Scalars['Boolean'];
};

/** Autogenerated return type of PaymentIntentCreate */
export type PaymentIntentCreatePayload = {
  __typename: 'PaymentIntentCreatePayload';
  clientSecret: Scalars['String'];
};

/** Autogenerated return type of PaymentMethodAttach */
export type PaymentMethodAttachPayload = {
  __typename: 'PaymentMethodAttachPayload';
  attach: Scalars['Boolean'];
};

/** Autogenerated return type of PaymentMethodCurrent */
export type PaymentMethodCurrentPayload = {
  __typename: 'PaymentMethodCurrentPayload';
  transaction: Scalars['Boolean'];
};

/** Autogenerated return type of PaymentMethodDetach */
export type PaymentMethodDetachPayload = {
  __typename: 'PaymentMethodDetachPayload';
  detach: Scalars['Boolean'];
};

export enum PaymentMethodEnum {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CARD = 'CARD',
}

/** Current payment methods in stripe */
export type PaymentMethodInfo = {
  __typename: 'PaymentMethodInfo';
  bankAccounts: Array<StripeBankAccount>;
  cards: Array<StripePaymentMethod>;
  currentPaymentMethod: Maybe<Scalars['String']>;
};

/** Represents a company 's pay obligations for a payroll period. */
export type Payroll = {
  __typename: 'Payroll';
  /** Datetime by which the payroll must be approved. */
  approvalDeadline: Scalars['ISO8601DateTime'];
  /** Datetime at which the payroll was approved. */
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  checkPayrollId: Scalars['String'];
  contractorPayments: Maybe<ContractorPaymentPage>;
  id: Scalars['ID'];
  /** Frequency by which employees get paid. */
  payFrequency: Scalars['String'];
  /** Date on which employees will be paid for the payroll. */
  payday: Scalars['ISO8601Date'];
  payrollItems: Maybe<PayrollItemPage>;
  /** Indicates whether the payroll is a regular or off-cycle payroll. */
  payrollType: Scalars['String'];
  /** End of the date range that the payroll covers. */
  periodEnd: Scalars['ISO8601Date'];
  /** Start of the date range that the payroll covers. */
  periodStart: Scalars['ISO8601Date'];
  /** Status of the payroll in its lifecycle. */
  status: PayrollStatusEnum;
  totals: PayrollTotals;
};

/** Represents a company 's pay obligations for a payroll period. */
export type PayrollContractorPaymentsArgs = {
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Represents a company 's pay obligations for a payroll period. */
export type PayrollPayrollItemsArgs = {
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Autogenerated return type of PayrollApprove */
export type PayrollApprovePayload = {
  __typename: 'PayrollApprovePayload';
  payroll: Payroll;
};

/** Autogenerated return type of PayrollCreate */
export type PayrollCreatePayload = {
  __typename: 'PayrollCreatePayload';
  payroll: Payroll;
};

/** Represents a payment to an employee from a company. */
export type PayrollItem = {
  __typename: 'PayrollItem';
  checkPayrollItemId: Scalars['String'];
  deductions: Maybe<Array<PostTaxDeduction>>;
  earnings: Array<PayrollItemEarning>;
  id: Scalars['ID'];
  /** The total amount of net pay earned by the employee for this payroll item. */
  netPay: Maybe<Scalars['String']>;
  /** May be manual or direct_deposit if the employee has a linked bank account. */
  paymentMethod: Scalars['String'];
  payroll: Payroll;
  /** Status of the payroll item. */
  status: PayrollItemStatusEnum;
  taxes: Array<PayrollItemTax>;
  /** Worker associated with the payroll item. */
  worker: Maybe<Worker>;
};

/** Represent the set of earning object associated with the payroll item. */
export type PayrollItemEarning = {
  __typename: 'PayrollItemEarning';
  /** Address where work for this earning was performed. */
  address: Maybe<Address>;
  /** Amount earned for this earning. */
  amount: Scalars['String'];
  /** Readable description of the earning code that will show up on the paystub. */
  description: Maybe<Scalars['String']>;
  /** Type of earning. */
  earningType: PayrollItemEarningEnum;
  /** Number of hours worked for this earning */
  hours: Maybe<Scalars['Float']>;
  payrollItem: Maybe<PayrollItem>;
  timesheet: Maybe<Timesheet>;
};

export enum PayrollItemEarningEnum {
  BONUS = 'BONUS',
  CASH_TIPS = 'CASH_TIPS',
  COMMISSION = 'COMMISSION',
  GROUP_TERM_LIFE = 'GROUP_TERM_LIFE',
  HOURLY = 'HOURLY',
  OTHER_IMPUTED = 'OTHER_IMPUTED',
  OVERTIME = 'OVERTIME',
  PAYCHECK_TIPS = 'PAYCHECK_TIPS',
  PTO = 'PTO',
  REGULAR = 'REGULAR',
  SICK = 'SICK',
}

/** Pagination container for PayrollItem */
export type PayrollItemPage = {
  __typename: 'PayrollItemPage';
  items: Array<PayrollItem>;
  pageInfo: PageInfo;
};

export enum PayrollItemStatusEnum {
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

export type PayrollItemTax = {
  __typename: 'PayrollItemTax';
  amount: Scalars['String'];
  checkTaxId: Scalars['String'];
  description: Scalars['String'];
  payer: Scalars['String'];
};

/** Pagination container for Payroll */
export type PayrollPage = {
  __typename: 'PayrollPage';
  items: Array<Payroll>;
  pageInfo: PageInfo;
};

export enum PayrollStatusEnum {
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
}

/** Represents the total payroll item amounts for a given pay period. */
export type PayrollTotals = {
  __typename: 'PayrollTotals';
  /** Total cash amount that Check will withdraw from the company's account for the payroll after it is approved. */
  cashRequirement: Scalars['String'];
  /** Total amount of company benefit contributions. */
  companyBenefits: Scalars['String'];
  /** Total amount of company tax liability. */
  companyTaxes: Scalars['String'];
  /** Total amount of all employee benefit contributions. */
  employeeBenefits: Scalars['String'];
  /**
   * Total amount of all employee gross pay, including taxable and non-taxable
   * components. This is the sum of gross_pay for all payroll items.
   */
  employeeGross: Scalars['String'];
  /**
   * Total amount that should be paid from the company to its employees, excluding
   * employee taxes, employee benefit contributions, and imputed income. This is
   * the sum of net_pay for all payroll items.
   */
  employeeNet: Scalars['String'];
  /** Total amount of all employee reimbursements. */
  employeeReimbursements: Scalars['String'];
  /** Total amount of all employee tax liability. */
  employeeTaxes: Scalars['String'];
  /** Total amount that the company owes to employees, contractors, tax agencies, and benefits providers. */
  liability: Scalars['String'];
  /** Total amount of all employee post-tax deductions. */
  postTaxDeductions: Scalars['String'];
};

/** A lat/long point */
export type Point = {
  __typename: 'Point';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PointInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** A spatial polygon */
export type Polygon = {
  __typename: 'Polygon';
  rings: Array<PolygonRing>;
};

export type PolygonInput = {
  rings: Array<PolygonRingInput>;
};

/** A ring of lat/long points */
export type PolygonRing = {
  __typename: 'PolygonRing';
  points: Array<Point>;
};

export type PolygonRingInput = {
  points: Array<PointInput>;
};

export type PostTaxDeduction = {
  __typename: 'PostTaxDeduction';
  amount: Scalars['String'];
  checkPtdId: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  ptdType: Scalars['String'];
};

/** The query root of this schema */
export type Query = {
  __typename: 'Query';
  /** Get account */
  account: Account;
  /** Check if a client name already exists */
  accountNameDuplicate: Scalars['Boolean'];
  /** Get all payment methods for a billing account */
  accountPaymentMethods: PaymentMethodInfo;
  /** Get accounts of customer */
  accounts: Array<Account>;
  /** Get Active Region States */
  activeRegionState: Array<Scalars['String']>;
  /** Active User List Report */
  activeUserListReport: Scalars['Boolean'];
  /** Active users by skill report */
  activeUsersBySkillReport: Array<ActiveUsersBySkillReport>;
  /** Get address */
  address: Address;
  /** List addresses */
  addresses: AddressPage;
  /** List agencies */
  agencies: Array<Agency>;
  /** Get agency */
  agency: Agency;
  /** Get agency by key */
  agencyByKey: Agency;
  /** Get all jobs from a worker account */
  allJobsWorker: Array<JobWorker>;
  /** Returns a list of payrolls. */
  allPayrolls: PayrollPage;
  /** Get candidate */
  candidate: Candidate;
  /** Get candidate by token */
  candidateByToken: Candidate;
  /** Get current actor */
  currentActor: CurrentActorUnion;
  /** Get current device */
  currentDevice: Device;
  /** Get current tenant admin */
  currentTenantAdmin: TenantAdmin;
  /** Get customer */
  customer: Customer;
  /** Get customer admin */
  customerAdmin: CustomerAdmin;
  /** Get all payment methods from a customer admin */
  customerAdminPaymentMethod: PaymentMethodInfo;
  /** Get customer invite */
  customerInvite: Maybe<CustomerAdmin>;
  /** Customer List Report */
  customerListReport: Maybe<Array<CustomerListReport>>;
  /** Check if a client name already exists */
  customerNameDuplicate: Scalars['Boolean'];
  /** Get default rate */
  defaultRate: DefaultRate;
  /** List default rates */
  defaultRates: Array<DefaultRate>;
  /** List default rates */
  defaultRatesBySkill: DefaultRate;
  /** List pre-defined boundries to build sevice areas */
  definedBoundries: DefinedBoundryPage;
  /** List eligible workers for a job */
  eligibleWorkers: WorkerPage;
  /** Check if a user with the specified email exists */
  existingUser: Scalars['Boolean'];
  /** Fetch default/nearest rates */
  fetchRates: FetchRates;
  /** Get timesheets to generate invoice */
  generateInvoiceTimesheets: TimesheetPage;
  /** Get interview question */
  interviewQuestion: InterviewQuestion;
  /** List interview questions */
  interviewQuestions: Array<InterviewQuestion>;
  /** Get address */
  invoice: Invoice;
  /** Invoicing Report */
  invoicingReport: Maybe<Array<InvoicingReport>>;
  /** Get job */
  job: Job;
  /** Get job */
  jobHireableWorkers: Maybe<Array<Worker>>;
  /** Get job history */
  jobHistory: JobHistory;
  /** Get job subscribers */
  jobSubscriber: Array<JobSubscriber>;
  /** Get job worker */
  jobWorker: JobWorker;
  /** Returns a list of items from an Stripe Invoice. */
  listStripeInvoiceDetail: StripeInvoiceDetail;
  /** Returns a list of invoices from Stripe. */
  listStripeInvoices: InvoicePage;
  /** Returns a list of surge rates. */
  listSurgeRates: SurgeRatePage;
  /** Get order */
  order: Order;
  /** Returns the specified payroll. */
  payroll: Payroll;
  /** Returns the specified payroll item. */
  payrollItem: PayrollItem;
  /** Payroll journal information */
  payrollJournalReport: Scalars['Boolean'];
  /** Returns a preview of an approved version of the input payroll. */
  payrollPreview: Payroll;
  /** Get a Plaid Link Token */
  plaidLinkToken: Scalars['String'];
  /** Get rate */
  rate: Rate;
  /** Get rate quote */
  rateQuote: Maybe<Rate>;
  /** Get Date Ranges */
  rateRange: Array<RateRange>;
  /** Returns the regions of the user */
  region: Array<Region>;
  /** Revenue Report */
  revenueReport: Maybe<Array<RevenueReport>>;
  /** Get service area */
  serviceArea: ServiceArea;
  /** Get skill */
  skill: Skill;
  /** Get skill category */
  skillCategory: SkillCategory;
  /** Get surge rate by date */
  surgeRateByDate: SurgeRate;
  /** Get surge rates from given date */
  surgeRatesFromDate: Array<SurgeRate>;
  /** Get tenant */
  tenant: Tenant;
  /** Get tenant by key */
  tenantByKey: Maybe<Tenant>;
  /** Get timesheet */
  timesheet: Timesheet;
  /** Timesheet Report */
  timesheetReport: Scalars['String'];
  /** Get a URL to upload files to */
  uploadUrl: UploadUrl;
  /** Get worker interview (Video Questionnaire) */
  willoWorkerInterview: Maybe<WilloWorkerInterview>;
  /** Get worker */
  worker: Worker;
  /** Get worker interview */
  workerInterview: Maybe<WorkerInterview>;
  /** Get signature requests for one workerId from TenantAdmin */
  workerSignatureRequestsTenant: Array<WorkerSignatureRequest>;
  /** List workers */
  workers: WorkerPage;
};

/** The query root of this schema */
export type QueryAccountArgs = {
  accountId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAccountNameDuplicateArgs = {
  clientName: Scalars['String'];
};

/** The query root of this schema */
export type QueryAccountPaymentMethodsArgs = {
  accountId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAccountsArgs = {
  customerId: Scalars['ID'];
  skillIds?: Maybe<Array<Scalars['ID']>>;
};

/** The query root of this schema */
export type QueryActiveRegionStateArgs = {
  tenantId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryActiveUsersBySkillReportArgs = {
  skills: Array<Scalars['Int']>;
};

/** The query root of this schema */
export type QueryAddressArgs = {
  addressId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAgencyArgs = {
  agencyId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAgencyByKeyArgs = {
  key: Scalars['Key'];
  tenantId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAllJobsWorkerArgs = {
  workerId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAllPayrollsArgs = {
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
  tenantId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryCandidateArgs = {
  candidateId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryCandidateByTokenArgs = {
  token: Scalars['String'];
};

/** The query root of this schema */
export type QueryCustomerArgs = {
  customerId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryCustomerAdminArgs = {
  customerAdminId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryCustomerAdminPaymentMethodArgs = {
  customerAdminId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryCustomerInviteArgs = {
  inviteCode: Scalars['String'];
};

/** The query root of this schema */
export type QueryCustomerNameDuplicateArgs = {
  clientName: Scalars['String'];
};

/** The query root of this schema */
export type QueryDefaultRateArgs = {
  defaultRateId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryDefaultRatesArgs = {
  addressId: Maybe<Scalars['ID']>;
};

/** The query root of this schema */
export type QueryDefaultRatesBySkillArgs = {
  addressId: Maybe<Scalars['ID']>;
  skillId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryDefinedBoundriesArgs = {
  query: Maybe<Scalars['String']>;
  within: Maybe<PolygonInput>;
};

/** The query root of this schema */
export type QueryEligibleWorkersArgs = {
  jobId: Scalars['ID'];
  search: Maybe<Scalars['String']>;
};

/** The query root of this schema */
export type QueryExistingUserArgs = {
  email: Scalars['Email'];
};

/** The query root of this schema */
export type QueryFetchRatesArgs = {
  accountId: Scalars['ID'];
  addressId: Scalars['ID'];
  skillId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryGenerateInvoiceTimesheetsArgs = {
  customerId: Scalars['ID'];
  filters: Maybe<TimesheetFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** The query root of this schema */
export type QueryInterviewQuestionArgs = {
  interviewQuestionId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryInvoiceArgs = {
  invoice: Scalars['ID'];
};

/** The query root of this schema */
export type QueryInvoicingReportArgs = {
  from: Maybe<Scalars['ISO8601DateTime']>;
  to: Maybe<Scalars['ISO8601DateTime']>;
};

/** The query root of this schema */
export type QueryJobArgs = {
  jobId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryJobHireableWorkersArgs = {
  filters: Maybe<WorkerFilterSetInput>;
  jobId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryJobHistoryArgs = {
  jobId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryJobSubscriberArgs = {
  job: Scalars['Int'];
};

/** The query root of this schema */
export type QueryJobWorkerArgs = {
  jobWorkerId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryListStripeInvoiceDetailArgs = {
  invoiceId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryListStripeInvoicesArgs = {
  customerId: Maybe<Scalars['String']>;
  filters: Maybe<InvoiceFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
  reverse?: Maybe<Scalars['Boolean']>;
  sortKey?: Maybe<InvoiceSortKeyEnum>;
};

/** The query root of this schema */
export type QueryListSurgeRatesArgs = {
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** The query root of this schema */
export type QueryOrderArgs = {
  orderId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryPayrollArgs = {
  payrollId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryPayrollItemArgs = {
  payrollItemId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryPayrollJournalReportArgs = {
  payrollId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryPayrollPreviewArgs = {
  payrollId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryRateArgs = {
  rateId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryRateQuoteArgs = {
  accountId: Scalars['ID'];
  point: PointInput;
  skillId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryRateRangeArgs = {
  addressId: Maybe<Scalars['ID']>;
  skillId: Maybe<Scalars['ID']>;
};

/** The query root of this schema */
export type QueryRegionArgs = {
  agencyId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryRevenueReportArgs = {
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
};

/** The query root of this schema */
export type QueryServiceAreaArgs = {
  serviceAreaId: Scalars['ID'];
};

/** The query root of this schema */
export type QuerySkillArgs = {
  skillId: Scalars['ID'];
};

/** The query root of this schema */
export type QuerySkillCategoryArgs = {
  skillCategoryId: Scalars['ID'];
};

/** The query root of this schema */
export type QuerySurgeRateByDateArgs = {
  date: Scalars['ISO8601Date'];
};

/** The query root of this schema */
export type QuerySurgeRatesFromDateArgs = {
  fromDate: Scalars['ISO8601Date'];
};

/** The query root of this schema */
export type QueryTenantArgs = {
  tenantId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryTenantByKeyArgs = {
  key: Scalars['Key'];
};

/** The query root of this schema */
export type QueryTimesheetArgs = {
  timesheetId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryTimesheetReportArgs = {
  payPeriod: Maybe<Scalars['ISO8601Date']>;
};

/** The query root of this schema */
export type QueryUploadUrlArgs = {
  fileType: FileTypeEnum;
  filename: Scalars['String'];
};

/** The query root of this schema */
export type QueryWilloWorkerInterviewArgs = {
  typeInterview: WilloWorkerInterviewStatusEnum;
  workerId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryWorkerArgs = {
  workerId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryWorkerInterviewArgs = {
  workerId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryWorkerSignatureRequestsTenantArgs = {
  type: Maybe<SignatureRequestTypeEnum>;
  workerId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryWorkersArgs = {
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Rate */
export type Rate = {
  __typename: 'Rate';
  account: Maybe<Account>;
  active: Maybe<Scalars['Boolean']>;
  address: Maybe<Address>;
  agency: Maybe<Agency>;
  baseCost: Scalars['Int'];
  basePay: Scalars['Int'];
  cost: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  level: Maybe<Scalars['Int']>;
  overtimeFactor: Maybe<Scalars['Int']>;
  pay: Scalars['Int'];
  serviceArea: ServiceArea;
  skill: Maybe<Skill>;
  surgeCost: Maybe<Scalars['Int']>;
  surgePay: Maybe<Scalars['Int']>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Rate */
export type RateCostArgs = {
  overtime: Maybe<Scalars['Boolean']>;
  surge: Maybe<Scalars['Boolean']>;
};

/** Rate */
export type RatePayArgs = {
  overtime: Maybe<Scalars['Boolean']>;
  surge: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RateCancel */
export type RateCancelPayload = {
  __typename: 'RateCancelPayload';
  rate: Rate;
};

/** Autogenerated return type of RateCreate */
export type RateCreatePayload = {
  __typename: 'RateCreatePayload';
  rate: Rate;
};

/** Autogenerated return type of RateDelete */
export type RateDeletePayload = {
  __typename: 'RateDeletePayload';
  deleted: Scalars['Boolean'];
};

/** Date Range Type */
export type RateRange = {
  __typename: 'RateRange';
  maximum: Scalars['Float'];
  minimum: Scalars['Float'];
};

/** Autogenerated return type of RateUpdate */
export type RateUpdatePayload = {
  __typename: 'RateUpdatePayload';
  rate: Rate;
};

export type ReferenceInput = {
  city: Scalars['String'];
  duration: DurationEnum;
  email?: Maybe<Scalars['Email']>;
  employerName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Referral */
export type Referral = {
  __typename: 'Referral';
  clickedAt: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  onboardedAt: Maybe<Scalars['ISO8601DateTime']>;
  prospectEmail: Scalars['String'];
  sentAt: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Maybe<Worker>;
};

/** Autogenerated return type of ReferralCreate */
export type ReferralCreatePayload = {
  __typename: 'ReferralCreatePayload';
  referral: Referral;
};

export enum ReferralEnum {
  CRAIGLIST = 'CRAIGLIST',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  INDEED = 'INDEED',
  INSTAGRAM = 'INSTAGRAM',
  OTHER = 'OTHER',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
}

/** Region Type */
export type Region = {
  __typename: 'Region';
  /** Unique identifier for the region */
  id: Scalars['ID'];
  /** Indicates if the region is active */
  isActive: Scalars['Boolean'];
  /** job tax type */
  jobTaxType: Maybe<TaxTypeEnum>;
  /** Name of the region */
  name: Maybe<Scalars['String']>;
  /** Zipcodes associated with the region */
  regionZipcodes: Maybe<Array<RegionZipcode>>;
};

export type RegionZipcode = {
  __typename: 'RegionZipcode';
  /** The region associated with the zipcode */
  region: Region;
  /** Zipcode data associated with the region */
  zipcodeData: ZipcodeData;
};

export enum RelationshipKindEnum {
  BLOCKED = 'BLOCKED',
  FAVORITED = 'FAVORITED',
  LTA_WORKERS = 'LTA_WORKERS',
}

/** Revenue Report */
export type RevenueReport = {
  __typename: 'RevenueReport';
  company: Scalars['String'];
  netProfit: Scalars['Float'];
  period: Scalars['String'];
  region: Scalars['String'];
  totalHours: Scalars['Float'];
  totalPayrollExpense: Scalars['Float'];
  totalRevenue: Scalars['Float'];
};

export enum SearchRadiusEnum {
  FIFTEEN_MILES = 'FIFTEEN_MILES',
  FIVE_MILES = 'FIVE_MILES',
  TEN_MILES = 'TEN_MILES',
  THIRTY_MILES = 'THIRTY_MILES',
  TWENTY_FIVE_MILES = 'TWENTY_FIVE_MILES',
  TWENTY_MILES = 'TWENTY_MILES',
}

/** Service area */
export type ServiceArea = {
  __typename: 'ServiceArea';
  agency: Maybe<Agency>;
  createdAt: Scalars['ISO8601DateTime'];
  geometry: MultiPolygon;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  rates: Array<Rate>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of ServiceAreaCreate */
export type ServiceAreaCreatePayload = {
  __typename: 'ServiceAreaCreatePayload';
  serviceArea: ServiceArea;
};

/** Autogenerated return type of ServiceAreaUpdate */
export type ServiceAreaUpdatePayload = {
  __typename: 'ServiceAreaUpdatePayload';
  serviceArea: ServiceArea;
};

/** Shift */
export type Shift = {
  __typename: 'Shift';
  createdAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  job: Job;
  startAt: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ShiftInput = {
  endAt: Scalars['ISO8601DateTime'];
  startAt: Scalars['ISO8601DateTime'];
  timeZoneOffset?: Maybe<Scalars['Float']>;
};

export enum SignatureRequestStatusEnum {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export enum SignatureRequestTypeEnum {
  ONBOARDING_DOCS = 'ONBOARDING_DOCS',
}

/** Skill */
export type Skill = {
  __typename: 'Skill';
  agency: Agency;
  assessment: Scalars['Boolean'];
  category: Maybe<SkillCategory>;
  createdAt: Scalars['ISO8601DateTime'];
  descriptionEn: Maybe<Scalars['String']>;
  descriptionEs: Maybe<Scalars['String']>;
  displayNameEn: Maybe<Scalars['String']>;
  displayNameEs: Maybe<Scalars['String']>;
  gravyTrained: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Skill category */
export type SkillCategory = {
  __typename: 'SkillCategory';
  agency: Maybe<Agency>;
  createdAt: Scalars['ISO8601DateTime'];
  descriptionEn: Maybe<Scalars['String']>;
  descriptionEs: Maybe<Scalars['String']>;
  displayNameEn: Maybe<Scalars['String']>;
  displayNameEs: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  skills: Array<Skill>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of SkillCategoryCreate */
export type SkillCategoryCreatePayload = {
  __typename: 'SkillCategoryCreatePayload';
  skillCategory: SkillCategory;
};

/** Autogenerated return type of SkillCategoryUpdate */
export type SkillCategoryUpdatePayload = {
  __typename: 'SkillCategoryUpdatePayload';
  skillCategory: SkillCategory;
};

/** Autogenerated return type of SkillCreate */
export type SkillCreatePayload = {
  __typename: 'SkillCreatePayload';
  skill: Skill;
};

/** Skill request */
export type SkillRequest = {
  __typename: 'SkillRequest';
  assessment: Maybe<Array<Assessment>>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  skill: Skill;
  status: SkillRequestStatusEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

/** Autogenerated return type of SkillRequestAccept */
export type SkillRequestAcceptPayload = {
  __typename: 'SkillRequestAcceptPayload';
  skillRequest: SkillRequest;
};

/** Autogenerated return type of SkillRequestReject */
export type SkillRequestRejectPayload = {
  __typename: 'SkillRequestRejectPayload';
  skillRequest: SkillRequest;
};

export enum SkillRequestStatusEnum {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

/** Autogenerated return type of SkillUpdate */
export type SkillUpdatePayload = {
  __typename: 'SkillUpdatePayload';
  skill: Skill;
};

export enum SmartphoneTypeEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  OTHER = 'OTHER',
}

/** A Stripe Bank Account object */
export type StripeBankAccount = {
  __typename: 'StripeBankAccount';
  accountHolderName: Maybe<Scalars['String']>;
  accountHolderType: Maybe<Scalars['String']>;
  accountType: Maybe<Scalars['String']>;
  bankName: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  customer: Scalars['String'];
  fingerprint: Scalars['String'];
  id: Scalars['String'];
  last4: Scalars['String'];
  routingNumber: Scalars['String'];
  status: Scalars['String'];
};

/** A Card */
export type StripeCard = {
  __typename: 'StripeCard';
  brand: Scalars['String'];
  country: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['String'];
  fingerprint: Scalars['String'];
  funding: Scalars['String'];
  holderName: Maybe<Scalars['String']>;
  last4: Scalars['String'];
};

/** An Invoice */
export type StripeInvoice = {
  __typename: 'StripeInvoice';
  amountDue: Scalars['String'];
  created: Scalars['String'];
  createdDatetime: Scalars['ISO8601DateTime'];
  customer: Scalars['String'];
  customerEmail: Scalars['Email'];
  description: Maybe<Scalars['String']>;
  dueDate: Maybe<Scalars['String']>;
  dueDateDatetime: Maybe<Scalars['ISO8601DateTime']>;
  hostedInvoiceUrl: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoicePdf: Maybe<Scalars['String']>;
  status: StripeInvoiceStatusEnum;
  subtotal: Scalars['String'];
  total: Scalars['String'];
};

/** Invoice Detail */
export type StripeInvoiceDetail = {
  __typename: 'StripeInvoiceDetail';
  invoice: Invoice;
  invoiceItems: Maybe<Array<InvoiceItem>>;
};

/** Autogenerated return type of StripeInvoiceFinalize */
export type StripeInvoiceFinalizePayload = {
  __typename: 'StripeInvoiceFinalizePayload';
  invoice: Invoice;
};

/** Autogenerated return type of StripeInvoiceItemCreate */
export type StripeInvoiceItemCreatePayload = {
  __typename: 'StripeInvoiceItemCreatePayload';
  invoiceItem: Maybe<InvoiceItem>;
};

/** Autogenerated return type of StripeInvoiceItemDelete */
export type StripeInvoiceItemDeletePayload = {
  __typename: 'StripeInvoiceItemDeletePayload';
  deleted: Scalars['Boolean'];
};

/** Autogenerated return type of StripeInvoiceItemUpdate */
export type StripeInvoiceItemUpdatePayload = {
  __typename: 'StripeInvoiceItemUpdatePayload';
  invoiceItem: Maybe<InvoiceItem>;
};

/** Autogenerated return type of StripeInvoiceMarkAsPaid */
export type StripeInvoiceMarkAsPaidPayload = {
  __typename: 'StripeInvoiceMarkAsPaidPayload';
  invoice: StripeInvoice;
};

export enum StripeInvoiceStatusEnum {
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  OPEN = 'OPEN',
  OUTSTANDING = 'OUTSTANDING',
  PAID = 'PAID',
  PAST_DUE = 'PAST_DUE',
  PENDING = 'PENDING',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID',
}

/** Autogenerated return type of StripeInvoiceUpdate */
export type StripeInvoiceUpdatePayload = {
  __typename: 'StripeInvoiceUpdatePayload';
  invoice: Maybe<Invoice>;
};

/** Autogenerated return type of StripeInvoiceVoid */
export type StripeInvoiceVoidPayload = {
  __typename: 'StripeInvoiceVoidPayload';
  invoice: Invoice;
};

/** A payment method */
export type StripePaymentMethod = {
  __typename: 'StripePaymentMethod';
  card: StripeCard;
  created: Scalars['Int'];
  customer: Scalars['String'];
  id: Scalars['String'];
};

/** Autogenerated return type of StripeUnlinkInvoice */
export type StripeUnlinkInvoicePayload = {
  __typename: 'StripeUnlinkInvoicePayload';
  invoice: Invoice;
};

export enum SubjectRoleEnum {
  CHILD = 'CHILD',
  PARENT = 'PARENT',
  TARGET = 'TARGET',
}

/** Surge Rate */
export type SurgeRate = {
  __typename: 'SurgeRate';
  createdAt: Scalars['ISO8601DateTime'];
  date: Scalars['ISO8601Date'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  rate: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Pagination container for SurgeRate */
export type SurgeRatePage = {
  __typename: 'SurgeRatePage';
  items: Array<SurgeRate>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of SurgeRatesCreate */
export type SurgeRatesCreatePayload = {
  __typename: 'SurgeRatesCreatePayload';
  surgeRates: SurgeRate;
};

/** Autogenerated return type of SurgeRatesDelete */
export type SurgeRatesDeletePayload = {
  __typename: 'SurgeRatesDeletePayload';
  deleted: Scalars['Boolean'];
};

/** Autogenerated return type of SurgeRatesUpdate */
export type SurgeRatesUpdatePayload = {
  __typename: 'SurgeRatesUpdatePayload';
  surgeRate: SurgeRate;
};

/** Autogenerated return type of SwitchAutoApprove */
export type SwitchAutoApprovePayload = {
  __typename: 'SwitchAutoApprovePayload';
  invoice: Invoice;
};

/** Tag */
export type Tag = {
  __typename: 'Tag';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of TagCreate */
export type TagCreatePayload = {
  __typename: 'TagCreatePayload';
  tag: Tag;
};

/** Pagination container for Tag */
export type TagPage = {
  __typename: 'TagPage';
  items: Array<Tag>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of TaggableAddTag */
export type TaggableAddTagPayload = {
  __typename: 'TaggableAddTagPayload';
  tags: Array<Tag>;
};

/** Autogenerated return type of TaggableChangeTags */
export type TaggableChangeTagsPayload = {
  __typename: 'TaggableChangeTagsPayload';
  tags: Array<Tag>;
};

export enum TaggableEnum {
  ACCOUNT = 'ACCOUNT',
  CUSTOMER = 'CUSTOMER',
  JOB = 'JOB',
  ORDER = 'ORDER',
  RATE = 'RATE',
  SERVICE_AREA = 'SERVICE_AREA',
  WORKER = 'WORKER',
}

/** Autogenerated return type of TaggableRemoveTag */
export type TaggableRemoveTagPayload = {
  __typename: 'TaggableRemoveTagPayload';
  tags: Array<Tag>;
};

/** Tests model for inclusion of ALL given tags */
export type TaggedFilterInput = {
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: Maybe<Scalars['Boolean']>;
  tagIds: Array<Scalars['ID']>;
};

export enum TaxTypeEnum {
  ALL = 'ALL',
  TAX_1099 = 'TAX_1099',
  TAX_W2 = 'TAX_W2',
}

/** Field for equality to a given Tax Type */
export type TaxTypeEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: TaxTypeEnum;
};

/** A tenant */
export type Tenant = {
  __typename: 'Tenant';
  admins: Array<TenantAdmin>;
  agencies: Array<Agency>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  key: Scalars['Key'];
  locationDesc: Maybe<Scalars['String']>;
  logoUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A tenant */
export type TenantAdminsArgs = {
  filters: Maybe<TenantAdminFilterSetInput>;
};

/** Tenant admin */
export type TenantAdmin = {
  __typename: 'TenantAdmin';
  avatarKey: Maybe<Scalars['String']>;
  avatarUrl: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  pendingCustomers: Scalars['Int'];
  role: Scalars['Int'];
  tenant: Tenant;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Autogenerated return type of TenantAdminChangeAvatar */
export type TenantAdminChangeAvatarPayload = {
  __typename: 'TenantAdminChangeAvatarPayload';
  tenantAdmin: TenantAdmin;
};

/** Available filter operations for a TenantAdmin */
export type TenantAdminFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  query?: Maybe<TextSearchFilterInput>;
};

/** Autogenerated return type of TenantAdminInvite */
export type TenantAdminInvitePayload = {
  __typename: 'TenantAdminInvitePayload';
  tenantAdmin: TenantAdmin;
};

/** Autogenerated return type of TenantAdminResendInvitation */
export type TenantAdminResendInvitationPayload = {
  __typename: 'TenantAdminResendInvitationPayload';
  result: Scalars['Boolean'];
};

/** Autogenerated return type of TenantUpdate */
export type TenantUpdatePayload = {
  __typename: 'TenantUpdatePayload';
  tenant: Tenant;
};

/** Tests field for inclusion of a given substring */
export type TextSearchFilterInput = {
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: Maybe<Scalars['Boolean']>;
  /** Sets the testing mode */
  mode?: Maybe<TextSearchModeEnum>;
  value: Scalars['String'];
};

/** Represents a mode of searching text for a substring */
export enum TextSearchModeEnum {
  /** String contains */
  CONTAINS = 'CONTAINS',
  /** String starts with */
  PREFIX = 'PREFIX',
  /** String ends with */
  SUFFIX = 'SUFFIX',
}

export enum TimelineStatusEnum {
  PAST = 'PAST',
  UPCOMING = 'UPCOMING',
}

/** Timesheet */
export type Timesheet = {
  __typename: 'Timesheet';
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  approvedBreakMinutes: Scalars['Minutes'];
  approvedCheckinAt: Maybe<Scalars['ISO8601DateTime']>;
  approvedCheckoutAt: Maybe<Scalars['ISO8601DateTime']>;
  approvedMinutes: Scalars['Minutes'];
  checkinAt: Maybe<Scalars['ISO8601DateTime']>;
  checkinCoords: Maybe<Point>;
  checkoutAt: Maybe<Scalars['ISO8601DateTime']>;
  checkoutCoords: Maybe<Point>;
  costRate: Scalars['NonNegativeInt'];
  createdAt: Scalars['ISO8601DateTime'];
  holdByInvoiceId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  invoiceItems: Maybe<Array<InvoiceItem>>;
  job: Job;
  jobWorker: JobWorker;
  netApprovedTime: Scalars['Minutes'];
  payRate: Scalars['NonNegativeInt'];
  rating: Maybe<Scalars['NonNegativeInt']>;
  ratingComment: Maybe<Scalars['String']>;
  rejectedAt: Maybe<Scalars['ISO8601DateTime']>;
  reportComment: Maybe<Scalars['String']>;
  reportedBreakMinutes: Scalars['Minutes'];
  reportedCheckinAt: Maybe<Scalars['ISO8601DateTime']>;
  reportedCheckoutAt: Maybe<Scalars['ISO8601DateTime']>;
  shift: Shift;
  status: TimesheetStatusEnum;
  timesheetComments: Array<TimesheetComment>;
  tipAmount: Maybe<Scalars['NonNegativeInt']>;
  updatedAt: Scalars['ISO8601DateTime'];
  updatedBy: Maybe<User>;
  updatedById: Maybe<Scalars['Int']>;
  updatedByType: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TimesheetApprove */
export type TimesheetApprovePayload = {
  __typename: 'TimesheetApprovePayload';
  timesheet: Timesheet;
};

/** Timesheet Comment */
export type TimesheetComment = {
  __typename: 'TimesheetComment';
  actor: Maybe<User>;
  actorType: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  timesheet: Maybe<Timesheet>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of TimesheetCommentsAdd */
export type TimesheetCommentsAddPayload = {
  __typename: 'TimesheetCommentsAddPayload';
  timesheet: Timesheet;
  timesheetComments: TimesheetComment;
};

/** Autogenerated return type of TimesheetCreate */
export type TimesheetCreatePayload = {
  __typename: 'TimesheetCreatePayload';
  timesheet: Timesheet;
};

/** Available filter operations for a Timesheet */
export type TimesheetFilterSetInput = {
  account?: Maybe<IdEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  approvedCheckinAt?: Maybe<DateTimeRangeFilterInput>;
  invoiceStatus?: Maybe<TimesheetInvoiceStatusEnum>;
  status?: Maybe<TimesheetStatusEqualityFilterInput>;
};

export enum TimesheetInvoiceStatusEnum {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  NOT_INVOICED = 'NOT_INVOICED',
}

/** Pagination container for Timesheet */
export type TimesheetPage = {
  __typename: 'TimesheetPage';
  items: Array<Timesheet>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of TimesheetReject */
export type TimesheetRejectPayload = {
  __typename: 'TimesheetRejectPayload';
  timesheet: Timesheet;
};

export enum TimesheetStatusEnum {
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

/** Tests field for equality to a given TimesheetStatus */
export type TimesheetStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: TimesheetStatusEnum;
};

/** Autogenerated return type of TimesheetTipUpdate */
export type TimesheetTipUpdatePayload = {
  __typename: 'TimesheetTipUpdatePayload';
  timesheet: Timesheet;
};

/** Autogenerated return type of TimesheetUnapprove */
export type TimesheetUnapprovePayload = {
  __typename: 'TimesheetUnapprovePayload';
  timesheet: Timesheet;
};

/** Autogenerated return type of TimesheetUnreject */
export type TimesheetUnrejectPayload = {
  __typename: 'TimesheetUnrejectPayload';
  timesheet: Timesheet;
};

/** Autogenerated return type of TimesheetUpdate */
export type TimesheetUpdatePayload = {
  __typename: 'TimesheetUpdatePayload';
  timesheet: Timesheet;
};

export enum TimesheetsBulkEditActionEnum {
  APPROVE = 'APPROVE',
  EDIT = 'EDIT',
  EDIT_AND_APPROVE = 'EDIT_AND_APPROVE',
  REJECT = 'REJECT',
  UN_APPROVE = 'UN_APPROVE',
  UN_REJECT = 'UN_REJECT',
}

/** Autogenerated return type of TimesheetsBulkEdit */
export type TimesheetsBulkEditPayload = {
  __typename: 'TimesheetsBulkEditPayload';
  message: Scalars['String'];
};

export enum TypeAppEnum {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
}

/** Uniform */
export type Uniform = {
  __typename: 'Uniform';
  agency: Maybe<Agency>;
  createdAt: Scalars['ISO8601DateTime'];
  defaultInstructions: Scalars['String'];
  defaultInstructionsEs: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameEs: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Upload URL */
export type UploadUrl = {
  __typename: 'UploadURL';
  key: Scalars['String'];
  url: Scalars['String'];
};

/** A user */
export type User = {
  __typename: 'User';
  active: Scalars['Boolean'];
  allowedPlatform: Scalars['Int'];
  channelId: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  dateOfBirth: Maybe<Scalars['ISO8601DateTime']>;
  email: Scalars['String'];
  emailConfirmed: Maybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  language: LanguageEnum;
  lastName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  resetPassword: Maybe<Scalars['Boolean']>;
  roleId: UserRoleEnum;
  secondaryLanguage: Maybe<LanguageEnum>;
  smartphoneType: Maybe<SmartphoneTypeEnum>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of UserAuthResetPassword */
export type UserAuthResetPasswordPayload = {
  __typename: 'UserAuthResetPasswordPayload';
  result: Scalars['Boolean'];
  user: User;
};

/** Autogenerated return type of UserChangeActive */
export type UserChangeActivePayload = {
  __typename: 'UserChangeActivePayload';
  user: User;
};

export enum UserRoleEnum {
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  HELPDESK_ADMIN = 'HELPDESK_ADMIN',
  TENANT_ADMIN = 'TENANT_ADMIN',
  WORKER = 'WORKER',
}

/** Autogenerated return type of UserUpdate */
export type UserUpdatePayload = {
  __typename: 'UserUpdatePayload';
  user: User;
};

/** Autogenerated return type of ValidateAddress */
export type ValidateAddressPayload = {
  __typename: 'ValidateAddressPayload';
  error: Maybe<Scalars['String']>;
  region: Maybe<Region>;
  success: Scalars['Boolean'];
  suggestedAlternative: Maybe<Address>;
};

export enum VisibilityStatusEnum {
  ALL = 'ALL',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

/** Willo Worker interview */
export type WilloWorkerInterview = {
  __typename: 'WilloWorkerInterview';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  interviewerKey: Scalars['String'];
  invitationLink: Scalars['String'];
  showcaseLink: Maybe<Scalars['String']>;
  stage: Maybe<InterviewStatusEnum>;
  typeInterview: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum WilloWorkerInterviewStatusEnum {
  ONBOARDING = 'ONBOARDING',
}

/** Worker */
export type Worker = {
  __typename: 'Worker';
  active: Maybe<Scalars['Boolean']>;
  activeCertificates: Array<Certificate>;
  activeSkills: Array<Skill>;
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  agency: Agency;
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  avatarKey: Maybe<Scalars['String']>;
  avatarUrl: Maybe<Scalars['String']>;
  averageRating: Scalars['Float'];
  backgroundStatus: Maybe<BackgroundStatusEnum>;
  badges: Array<Badge>;
  candidateStatus: CandidateStatusEnum;
  candidateStatusUpdatedAt: Maybe<Scalars['ISO8601DateTime']>;
  chatUid: Scalars['String'];
  checkEmployeeId: Maybe<Scalars['String']>;
  checkWorkplaceId: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  comments: Array<Comment>;
  complianceId: Maybe<Scalars['ID']>;
  coords: Maybe<Point>;
  createdAt: Scalars['ISO8601DateTime'];
  deactivatedAt: Maybe<Scalars['ISO8601DateTime']>;
  deactivatedBy: Maybe<WorkerDeactivatedTypeEnum>;
  deactivatedByUser: Maybe<User>;
  defaultDistance: SearchRadiusEnum;
  deniedAt: Maybe<Scalars['ISO8601DateTime']>;
  disableReason: Maybe<Scalars['String']>;
  formi9Restartable: Scalars['Boolean'];
  gender: GenderEnum;
  hasSsn: Scalars['Boolean'];
  heardFrom: Maybe<Scalars['String']>;
  i9Status: Maybe<I9StatusEnum>;
  i9form: Maybe<I9form>;
  id: Scalars['ID'];
  interviewStatus: Maybe<InterviewStatusEnum>;
  jobOffersGroup: Maybe<Array<JobOffersGroup>>;
  jobWorkers: JobWorkerPage;
  jobsDropped: Scalars['NonNegativeInt'];
  jobsFailed: Scalars['NonNegativeInt'];
  jobsRatingCount: Scalars['NonNegativeInt'];
  jobsWorked: Scalars['NonNegativeInt'];
  migrated: Maybe<Scalars['Boolean']>;
  noShow: Maybe<Scalars['NonNegativeInt']>;
  onboardingDocsStatus: Maybe<SignatureRequestStatusEnum>;
  onboardingStatus: Maybe<OnboardingStatusEnum>;
  ownsCar: Scalars['Boolean'];
  pendingJobOffers: Array<JobOffer>;
  pendingJobRequests: Array<JobRequest>;
  readyToWork: Scalars['Boolean'];
  reenableDate: Maybe<Scalars['ISO8601DateTime']>;
  references: Array<WorkerReference>;
  referrals: Array<Referral>;
  rejectedJobOffers: Maybe<Array<JobOffer>>;
  shiftsWorked: Scalars['NonNegativeInt'];
  skillRequests: Array<SkillRequest>;
  skills: Array<Skill>;
  state: Maybe<Scalars['String']>;
  status: WorkerStatusEnum;
  tags: Array<Tag>;
  taxType: TaxTypeEnum;
  tcAcceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  timezone: Scalars['Timezone'];
  updatedAt: Scalars['ISO8601DateTime'];
  usCitizenOrWorkPermit: Scalars['Boolean'];
  user: User;
  verificationCode: Maybe<Scalars['String']>;
  verifiedAt: Maybe<Scalars['ISO8601DateTime']>;
  workerJurisdictions: Maybe<Array<Jurisdiction>>;
  workerSkills: Array<WorkerSkill>;
  zip: Maybe<Scalars['String']>;
};

/** Worker */
export type WorkerJobWorkersArgs = {
  filters: Maybe<JobWorkerFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Autogenerated return type of WorkerAddCertificate */
export type WorkerAddCertificatePayload = {
  __typename: 'WorkerAddCertificatePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerAddReferenceList */
export type WorkerAddReferenceListPayload = {
  __typename: 'WorkerAddReferenceListPayload';
  saved: Scalars['Boolean'];
};

/** Autogenerated return type of WorkerAddReference */
export type WorkerAddReferencePayload = {
  __typename: 'WorkerAddReferencePayload';
  reference: WorkerReference;
};

/** Autogenerated return type of WorkerAddSkill */
export type WorkerAddSkillPayload = {
  __typename: 'WorkerAddSkillPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerApproveFormI9 */
export type WorkerApproveFormI9Payload = {
  __typename: 'WorkerApproveFormI9Payload';
  worker: Worker;
};

/** Autogenerated return type of WorkerAssignJobWorkplace */
export type WorkerAssignJobWorkplacePayload = {
  __typename: 'WorkerAssignJobWorkplacePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerChangeActive */
export type WorkerChangeActivePayload = {
  __typename: 'WorkerChangeActivePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerChangeAddress */
export type WorkerChangeAddressPayload = {
  __typename: 'WorkerChangeAddressPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerChangeAvatar */
export type WorkerChangeAvatarPayload = {
  __typename: 'WorkerChangeAvatarPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerClosedFormI9 */
export type WorkerClosedFormI9Payload = {
  __typename: 'WorkerClosedFormI9Payload';
  worker: Worker;
};

/** Autogenerated return type of WorkerCreate */
export type WorkerCreatePayload = {
  __typename: 'WorkerCreatePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerCreateWilloInterview */
export type WorkerCreateWilloInterviewPayload = {
  __typename: 'WorkerCreateWilloInterviewPayload';
  link: Scalars['String'];
};

export enum WorkerDeactivatedTypeEnum {
  ADMIN_DEACTIVATED = 'ADMIN_DEACTIVATED',
  FNC_DEACTIVATED = 'FNC_DEACTIVATED',
  WORKER_DEACTIVATED = 'WORKER_DEACTIVATED',
}

/** Autogenerated return type of WorkerDisable */
export type WorkerDisablePayload = {
  __typename: 'WorkerDisablePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerDisapproveFormI9 */
export type WorkerDisapproveFormI9Payload = {
  __typename: 'WorkerDisapproveFormI9Payload';
  worker: Worker;
};

/** Autogenerated return type of WorkerEnable */
export type WorkerEnablePayload = {
  __typename: 'WorkerEnablePayload';
  worker: Worker;
};

/** Available filter operations for a hired Worker */
export type WorkerFilterSetInput = {
  active?: Maybe<BooleanEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  chatUid?: Maybe<Scalars['String']>;
  coords?: Maybe<PointInput>;
  gender?: Maybe<Scalars['String']>;
  hired?: Maybe<Scalars['Boolean']>;
  jobsDropped?: Maybe<IntCompareFilterInput>;
  jobsFailed?: Maybe<IntCompareFilterInput>;
  jobsWorked?: Maybe<IntCompareFilterInput>;
  jurisdiction?: Maybe<Array<Scalars['String']>>;
  ownsCar?: Maybe<BooleanEqualityFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  relationship?: Maybe<WorkerRelationshipFilterInput>;
  taxType?: Maybe<TaxTypeEqualityFilterInput>;
  tcAcceptedAt?: Maybe<DateTimeCompareFilterInput>;
  workerCertificates?: Maybe<Array<IntCompareFilterInput>>;
  workerSkills?: Maybe<Array<IntCompareFilterInput>>;
};

/** Worker interview */
export type WorkerInterview = {
  __typename: 'WorkerInterview';
  createdAt: Scalars['ISO8601DateTime'];
  date: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  status: WorkerInterviewStatusEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
  workerInterviewAnswers: Array<WorkerInterviewAnswer>;
};

/** Worker interview answer */
export type WorkerInterviewAnswer = {
  __typename: 'WorkerInterviewAnswer';
  answer: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  interviewQuestion: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of WorkerInterviewCreate */
export type WorkerInterviewCreatePayload = {
  __typename: 'WorkerInterviewCreatePayload';
  workerInterview: WorkerInterview;
};

export enum WorkerInterviewStatusEnum {
  NOT_PASSED = 'NOT_PASSED',
  PASSED = 'PASSED',
}

/** Autogenerated return type of WorkerInterviewUpdate */
export type WorkerInterviewUpdatePayload = {
  __typename: 'WorkerInterviewUpdatePayload';
  workerInterview: WorkerInterview;
};

/** Autogenerated return type of WorkerInterviewUpdateV2 */
export type WorkerInterviewUpdateV2Payload = {
  __typename: 'WorkerInterviewUpdateV2Payload';
  willoWorkerInterview: WilloWorkerInterview;
};

/** Autogenerated return type of WorkerLogin */
export type WorkerLoginPayload = {
  __typename: 'WorkerLoginPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  worker: Worker;
};

/** Autogenerated return type of WorkerOnboard */
export type WorkerOnboardPayload = {
  __typename: 'WorkerOnboardPayload';
  worker: Worker;
};

/** Pagination container for Worker */
export type WorkerPage = {
  __typename: 'WorkerPage';
  items: Array<Worker>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of WorkerProspectFormI9 */
export type WorkerProspectFormI9Payload = {
  __typename: 'WorkerProspectFormI9Payload';
  worker: Worker;
};

/** Worker reference */
export type WorkerReference = {
  __typename: 'WorkerReference';
  city: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  duration: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  employerName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Maybe<Worker>;
};

/** Worker relationship */
export type WorkerRelationship = {
  __typename: 'WorkerRelationship';
  comments: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  id: Scalars['ID'];
  kind: RelationshipKindEnum;
  reason: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

/** filter workers between a relationship with a customer */
export type WorkerRelationshipFilterInput = {
  customerId: Scalars['ID'];
  kind: RelationshipKindEnum;
};

/** Available filter operations for a Worker Relationship */
export type WorkerRelationshipFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  jurisdiction?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of WorkerRemoveCertificate */
export type WorkerRemoveCertificatePayload = {
  __typename: 'WorkerRemoveCertificatePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerRemoveSkill */
export type WorkerRemoveSkillPayload = {
  __typename: 'WorkerRemoveSkillPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerResendCheckrInvitation */
export type WorkerResendCheckrInvitationPayload = {
  __typename: 'WorkerResendCheckrInvitationPayload';
  worker: Worker;
};

/** Represents a HelloSign Signature Request for a Worker */
export type WorkerSignatureRequest = {
  __typename: 'WorkerSignatureRequest';
  documentUrl: Maybe<Scalars['String']>;
  hellosignId: Scalars['String'];
  id: Scalars['ID'];
  requestStatus: Maybe<SignatureRequestStatusEnum>;
  requestType: SignatureRequestTypeEnum;
  tenantId: Scalars['ID'];
  workerId: Scalars['ID'];
};

/** Autogenerated return type of WorkerSignup */
export type WorkerSignupPayload = {
  __typename: 'WorkerSignupPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  region: Maybe<Region>;
  worker: Worker;
};

/** Worker skills */
export type WorkerSkill = {
  __typename: 'WorkerSkill';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  jobsWorked: Maybe<Scalars['Int']>;
  skill: Skill;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

export enum WorkerStatusEnum {
  CANDIDATE = 'CANDIDATE',
  HIRED = 'HIRED',
}

/** Autogenerated return type of WorkerTentativeNcFormI9 */
export type WorkerTentativeNcFormI9Payload = {
  __typename: 'WorkerTentativeNcFormI9Payload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateApplicant */
export type WorkerUpdateApplicantPayload = {
  __typename: 'WorkerUpdateApplicantPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateBackgroundCheck */
export type WorkerUpdateBackgroundCheckPayload = {
  __typename: 'WorkerUpdateBackgroundCheckPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateCandidate */
export type WorkerUpdateCandidatePayload = {
  __typename: 'WorkerUpdateCandidatePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateCheckInterview */
export type WorkerUpdateCheckInterviewPayload = {
  __typename: 'WorkerUpdateCheckInterviewPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateProfile */
export type WorkerUpdateProfilePayload = {
  __typename: 'WorkerUpdateProfilePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateRating */
export type WorkerUpdateRatingPayload = {
  __typename: 'WorkerUpdateRatingPayload';
  timesheet: Timesheet;
};

/** Autogenerated return type of WorkerUpdateStatus */
export type WorkerUpdateStatusPayload = {
  __typename: 'WorkerUpdateStatusPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateTaxType */
export type WorkerUpdateTaxTypePayload = {
  __typename: 'WorkerUpdateTaxTypePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerUpdateWilloInterview */
export type WorkerUpdateWilloInterviewPayload = {
  __typename: 'WorkerUpdateWilloInterviewPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerVerify */
export type WorkerVerifyPayload = {
  __typename: 'WorkerVerifyPayload';
  worker: Worker;
};

export type ZipcodeData = {
  __typename: 'ZipcodeData';
  /** Unique identifier for the state */
  stateId: Scalars['String'];
  /** zip field */
  zip: Scalars['String'];
};

export type DeviceLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type DeviceLogoutMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'deviceLogout'
>;

export type RemoveWorkerReferenceMutationVariables = Exact<{
  workerReferenceId: Scalars['ID'];
}>;

export type RemoveWorkerReferenceMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'workerRemoveReference'
>;

export type GetPlaidLinkTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlaidLinkTokenQuery = { __typename: 'Query' } & Pick<
  Query,
  'plaidLinkToken'
>;

export type GetWorkerForJobTenantQueryVariables = Exact<{
  filters: Maybe<WorkerFilterSetInput>;
}>;

export type GetWorkerForJobTenantQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      workers: { __typename: 'WorkerPage' } & {
        items: Array<
          { __typename: 'Worker' } & Pick<
            Worker,
            'id' | 'onboardingStatus' | 'readyToWork'
          > & {
              workerJurisdictions: Maybe<
                Array<
                  { __typename: 'Jurisdiction' } & Pick<Jurisdiction, 'state'>
                >
              >;
              user: { __typename: 'User' } & Pick<
                User,
                'id' | 'email' | 'firstName' | 'middleName' | 'lastName'
              >;
            }
        >;
      };
    };
};

export type GetWorkersForJobQueryVariables = Exact<{
  jobId: Scalars['ID'];
  filters: Maybe<WorkerFilterSetInput>;
}>;

export type GetWorkersForJobQuery = { __typename: 'Query' } & {
  jobHireableWorkers: Maybe<
    Array<
      { __typename: 'Worker' } & Pick<
        Worker,
        'id' | 'onboardingStatus' | 'readyToWork'
      > & {
          workerJurisdictions: Maybe<
            Array<{ __typename: 'Jurisdiction' } & Pick<Jurisdiction, 'state'>>
          >;
          user: { __typename: 'User' } & Pick<
            User,
            'id' | 'email' | 'firstName' | 'middleName' | 'lastName'
          >;
        }
    >
  >;
};

export type AccountFragmentFragment = { __typename: 'Account' } & Pick<
  Account,
  | 'id'
  | 'name'
  | 'addressLine1'
  | 'addressLine2'
  | 'city'
  | 'state'
  | 'zip'
  | 'cutOff'
  | 'monthlyCutOff'
  | 'billingWeek'
  | 'consolidateUnprocessedCharges'
  | 'groupBy'
  | 'markupPercent'
  | 'rateType'
  | 'canDisable'
  | 'active'
> & {
    customer: { __typename: 'Customer' } & CustomerItemFragment;
    defaultContact: { __typename: 'CustomerAdmin' } & Pick<
      CustomerAdmin,
      'id' | 'avatarUrl'
    > & {
        user: { __typename: 'User' } & Pick<
          User,
          'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
        >;
      };
  };

export type SimpleCommentFragment = { __typename: 'Comment' } & Pick<
  Comment,
  'id' | 'body' | 'createdAt'
> & {
    actor:
      | ({ __typename: 'CustomerAdmin' } & AdminItemFragment)
      | ({ __typename: 'TenantAdmin' } & TenantAdminItemFragment);
    commentable:
      | { __typename: 'Account' }
      | { __typename: 'Customer' }
      | { __typename: 'CustomerAdmin' }
      | { __typename: 'Job' }
      | { __typename: 'Order' }
      | { __typename: 'Worker' };
  };

export type CurrentAgencyFragment = { __typename: 'Agency' } & Pick<
  Agency,
  | 'id'
  | 'key'
  | 'name'
  | 'checkinWindow'
  | 'maxCheckinDistance'
  | 'email'
  | 'phoneNumber'
> & {
    address: Maybe<
      { __typename: 'Address' } & Pick<
        Address,
        'addressLine1' | 'addressLine2' | 'zip' | 'city' | 'state'
      >
    >;
  };

export type CurrentCustomerAdminFragment = {
  __typename: 'CustomerAdmin';
} & Pick<CustomerAdmin, 'id' | 'avatarUrl' | 'role'> & {
    user: { __typename: 'User' } & Pick<
      User,
      | 'id'
      | 'email'
      | 'firstName'
      | 'language'
      | 'lastName'
      | 'channelId'
      | 'roleId'
    >;
    customer: { __typename: 'Customer' } & Pick<
      Customer,
      'id' | 'name' | 'status' | 'onboardingStatus' | 'ltaAllowed'
    >;
  };

export type CurrentTenantAdminFragment = { __typename: 'TenantAdmin' } & Pick<
  TenantAdmin,
  'id' | 'avatarUrl' | 'role' | 'pendingCustomers'
> & { user: { __typename: 'User' } & UserItemFieldsFragment };

export type CurrentTenantFragment = { __typename: 'Tenant' } & Pick<
  Tenant,
  'id' | 'key' | 'name' | 'logoUrl'
>;

export type ListWorkersForAutocompleteQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<WorkerFilterSetInput>;
}>;

export type ListWorkersForAutocompleteQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      workers: { __typename: 'WorkerPage' } & {
        items: Array<
          { __typename: 'Worker' } & Pick<
            Worker,
            'id' | 'onboardingStatus' | 'readyToWork' | 'avatarUrl'
          > & {
              workerJurisdictions: Maybe<
                Array<
                  { __typename: 'Jurisdiction' } & Pick<Jurisdiction, 'state'>
                >
              >;
              user: { __typename: 'User' } & Pick<
                User,
                | 'id'
                | 'firstName'
                | 'middleName'
                | 'lastName'
                | 'email'
                | 'phoneNumber'
              >;
            }
        >;
      };
    };
};

export type OrderListItemFragment = { __typename: 'Order' } & Pick<
  Order,
  'id' | 'uuid' | 'startAt' | 'endAt' | 'jobsCount' | 'status'
> & {
    account: { __typename: 'Account' } & Pick<Account, 'id' | 'name'>;
    jobs: Array<
      { __typename: 'Job' } & Pick<
        Job,
        'id' | 'filled' | 'cancelled' | 'taxType' | 'approvalStatus'
      > & {
          timesheets: Array<
            { __typename: 'Timesheet' } & Pick<Timesheet, 'id' | 'status'>
          >;
          jobWorkers: Array<
            { __typename: 'JobWorker' } & Pick<JobWorker, 'showed' | 'status'>
          >;
          skill: { __typename: 'Skill' } & Pick<Skill, 'id' | 'imageUrl'>;
        }
    >;
  };

export type SimpleEarningsFragment = {
  __typename: 'PayrollItemEarning';
} & Pick<
  PayrollItemEarning,
  'amount' | 'description' | 'hours' | 'earningType'
> & {
    address: Maybe<
      { __typename: 'Address' } & Pick<
        Address,
        'id' | 'addressLine1' | 'city' | 'state' | 'zip'
      >
    >;
    timesheet: Maybe<
      { __typename: 'Timesheet' } & Pick<
        Timesheet,
        'id' | 'updatedByType' | 'payRate' | 'tipAmount'
      > & {
          updatedBy: Maybe<
            { __typename: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName'>
          >;
          job: { __typename: 'Job' } & Pick<Job, 'id'> & {
              customer: { __typename: 'Customer' } & Pick<
                Customer,
                'id' | 'name'
              >;
              account: { __typename: 'Account' } & Pick<Account, 'id' | 'name'>;
              order: { __typename: 'Order' } & Pick<Order, 'id'>;
            };
        }
    >;
  };

export type SimpleTotalsFragment = { __typename: 'PayrollTotals' } & Pick<
  PayrollTotals,
  | 'companyTaxes'
  | 'companyBenefits'
  | 'postTaxDeductions'
  | 'liability'
  | 'cashRequirement'
  | 'employeeGross'
  | 'employeeTaxes'
  | 'employeeBenefits'
  | 'employeeNet'
  | 'employeeReimbursements'
>;

export type SimpleTaxesFragment = { __typename: 'PayrollItemTax' } & Pick<
  PayrollItemTax,
  'amount' | 'description' | 'payer' | 'checkTaxId'
>;

export type SimpleContractorPaymentsFragment = {
  __typename: 'ContractorPayment';
} & Pick<ContractorPayment, 'id' | 'netPay' | 'paymentMethod' | 'status'> & {
    contractorPaymentItems: Array<
      { __typename: 'ContractorPaymentItem' } & Pick<
        ContractorPaymentItem,
        'amount' | 'description' | 'hours' | 'earningType'
      > & {
          address: Maybe<
            { __typename: 'Address' } & Pick<
              Address,
              'id' | 'addressLine1' | 'city' | 'state' | 'zip'
            >
          >;
          timesheet: Maybe<
            { __typename: 'Timesheet' } & Pick<
              Timesheet,
              'id' | 'updatedByType' | 'payRate' | 'tipAmount'
            > & {
                updatedBy: Maybe<
                  { __typename: 'User' } & Pick<
                    User,
                    'id' | 'firstName' | 'lastName'
                  >
                >;
                job: { __typename: 'Job' } & Pick<Job, 'id'> & {
                    customer: { __typename: 'Customer' } & Pick<
                      Customer,
                      'id' | 'name'
                    >;
                    account: { __typename: 'Account' } & Pick<
                      Account,
                      'id' | 'name'
                    >;
                    order: { __typename: 'Order' } & Pick<Order, 'id'>;
                  };
              }
          >;
        }
    >;
    worker: Maybe<
      { __typename: 'Worker' } & {
        user: { __typename: 'User' } & Pick<
          User,
          'firstName' | 'middleName' | 'lastName'
        >;
      }
    >;
  };

export type SimplePayrollItemFragment = { __typename: 'PayrollItem' } & Pick<
  PayrollItem,
  'id' | 'netPay' | 'paymentMethod' | 'status'
> & {
    earnings: Array<
      { __typename: 'PayrollItemEarning' } & SimpleEarningsFragment
    >;
    worker: Maybe<
      { __typename: 'Worker' } & {
        user: { __typename: 'User' } & Pick<
          User,
          'id' | 'firstName' | 'middleName' | 'lastName'
        >;
      }
    >;
    taxes: Array<{ __typename: 'PayrollItemTax' } & SimpleTaxesFragment>;
    deductions: Maybe<
      Array<
        { __typename: 'PostTaxDeduction' } & Pick<
          PostTaxDeduction,
          'amount' | 'checkPtdId' | 'description' | 'ptdType'
        >
      >
    >;
  };

export type SimpleTagFragment = { __typename: 'Tag' } & Pick<
  Tag,
  'id' | 'name'
>;

export type AddCommentMutationVariables = Exact<{
  commentableId: Scalars['ID'];
  commentableType: CommentableEnum;
  body: Scalars['String'];
}>;

export type AddCommentMutation = { __typename: 'Mutation' } & {
  commentableAddComment: { __typename: 'CommentableAddCommentPayload' } & {
    comments: Array<{ __typename: 'Comment' } & SimpleCommentFragment>;
  };
};

export type GetUploadUrlQueryVariables = Exact<{
  filename: Scalars['String'];
}>;

export type GetUploadUrlQuery = { __typename: 'Query' } & {
  uploadUrl: { __typename: 'UploadURL' } & Pick<UploadUrl, 'key' | 'url'>;
};

export type UserChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;

export type UserChangePasswordMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'userChangePassword'
>;

export type ListAgenciesQueryVariables = Exact<{ [key: string]: never }>;

export type ListAgenciesQuery = { __typename: 'Query' } & {
  agencies: Array<{ __typename: 'Agency' } & CurrentAgencyFragment>;
};

export type ListCustomerAdminsQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type ListCustomerAdminsQuery = { __typename: 'Query' } & {
  customer: { __typename: 'Customer' } & Pick<Customer, 'id'> & {
      admins: { __typename: 'CustomerAdminPage' } & {
        items: Array<
          { __typename: 'CustomerAdmin' } & Pick<
            CustomerAdmin,
            'id' | 'avatarUrl'
          > & {
              user: { __typename: 'User' } & Pick<
                User,
                'id' | 'firstName' | 'lastName' | 'email'
              >;
            }
        >;
      };
    };
};

export type DisableAccountMutationVariables = Exact<{
  accountId: Scalars['ID'];
  active: Scalars['Boolean'];
}>;

export type DisableAccountMutation = { __typename: 'Mutation' } & {
  accountUpdateActive: { __typename: 'AccountUpdateActivePayload' } & {
    account: { __typename: 'Account' } & Pick<Account, 'active' | 'id'>;
  };
};

export type GetAccountQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;

export type GetAccountQuery = { __typename: 'Query' } & {
  account: { __typename: 'Account' } & AccountFragmentFragment;
};

export type GetAccountOrdersQueryVariables = Exact<{
  accountId: Scalars['ID'];
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;

export type GetAccountOrdersQuery = { __typename: 'Query' } & {
  account: { __typename: 'Account' } & Pick<Account, 'id'> & {
      orders: { __typename: 'OrderPage' } & {
        items: Array<{ __typename: 'Order' } & OrderListItemFragment>;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type AttachCardToAccountMutationVariables = Exact<{
  account: Scalars['ID'];
  paymentMethodId: Scalars['String'];
}>;

export type AttachCardToAccountMutation = { __typename: 'Mutation' } & {
  accountAttachCard: { __typename: 'AccountAttachCardPayload' } & Pick<
    AccountAttachCardPayload,
    'attach'
  >;
};

export type DettachPaymentMethodMutationVariables = Exact<{
  account: Scalars['ID'];
  paymentMethodType: PaymentMethodEnum;
  paymentMethodId: Scalars['String'];
}>;

export type DettachPaymentMethodMutation = { __typename: 'Mutation' } & {
  accountDetachPaymentMethod: {
    __typename: 'AccountDetachPaymentMethodPayload';
  } & Pick<AccountDetachPaymentMethodPayload, 'detach'>;
};

export type GetAccountPaymentMethodsQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;

export type GetAccountPaymentMethodsQuery = { __typename: 'Query' } & {
  accountPaymentMethods: { __typename: 'PaymentMethodInfo' } & Pick<
    PaymentMethodInfo,
    'currentPaymentMethod'
  > & {
      cards: Array<
        { __typename: 'StripePaymentMethod' } & Pick<
          StripePaymentMethod,
          'id' | 'created' | 'customer'
        > & {
            card: { __typename: 'StripeCard' } & Pick<
              StripeCard,
              | 'brand'
              | 'country'
              | 'expMonth'
              | 'expYear'
              | 'fingerprint'
              | 'funding'
              | 'last4'
            >;
          }
      >;
      bankAccounts: Array<
        { __typename: 'StripeBankAccount' } & Pick<
          StripeBankAccount,
          | 'accountHolderName'
          | 'routingNumber'
          | 'status'
          | 'accountHolderType'
          | 'accountType'
          | 'bankName'
          | 'country'
          | 'currency'
          | 'customer'
          | 'fingerprint'
          | 'id'
          | 'last4'
        >
      >;
    };
};

export type AttachBankAccountMutationVariables = Exact<{
  account: Scalars['ID'];
  publicToken: Scalars['String'];
  bankAccountId: Scalars['ID'];
}>;

export type AttachBankAccountMutation = { __typename: 'Mutation' } & {
  accountAttachBankAccount: {
    __typename: 'AccountAttachBankAccountPayload';
  } & Pick<AccountAttachBankAccountPayload, 'attach'> & {
      bankAccount: Maybe<
        { __typename: 'StripeBankAccount' } & Pick<StripeBankAccount, 'id'>
      >;
    };
};

export type SetCurrentPaymentMethodMutationVariables = Exact<{
  account: Scalars['ID'];
  paymentMethodId: Scalars['String'];
}>;

export type SetCurrentPaymentMethodMutation = { __typename: 'Mutation' } & {
  accountSetCurrentPaymentMethod: {
    __typename: 'AccountSetCurrentPaymentMethodPayload';
  } & Pick<AccountSetCurrentPaymentMethodPayload, 'transaction'>;
};

export type UpdateAccountMutationVariables = Exact<{
  accountId: Scalars['ID'];
  defaultContactId: Scalars['ID'];
  city: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  zip: Scalars['String'];
  state: Scalars['String'];
  markupPercent: Maybe<Scalars['Float']>;
}>;

export type UpdateAccountMutation = { __typename: 'Mutation' } & {
  accountUpdate: { __typename: 'AccountUpdatePayload' } & {
    account: { __typename: 'Account' } & Pick<
      Account,
      'id' | 'name' | 'updatedAt'
    > & {
        defaultContact: { __typename: 'CustomerAdmin' } & Pick<
          CustomerAdmin,
          'id' | 'avatarUrl'
        > & {
            user: { __typename: 'User' } & Pick<User, 'firstName' | 'lastName'>;
          };
      };
  };
};

export type UpdateAccountCutOffMutationVariables = Exact<{
  accountId: Scalars['ID'];
  billingWeek: Maybe<BillingWeekEnum>;
  invoiceCutOff: Maybe<InvoiceCutOffEnum>;
  monthlyCutOff: Maybe<Scalars['Boolean']>;
  consolidateUnprocessedCharges: Maybe<Scalars['Boolean']>;
  groupBy: Maybe<InvoiceGroupByEnum>;
}>;

export type UpdateAccountCutOffMutation = { __typename: 'Mutation' } & {
  accountCutOffUpdate: { __typename: 'AccountCutOffUpdatePayload' } & {
    account: { __typename: 'Account' } & Pick<Account, 'id'>;
  };
};

export type AuthenticateChatServiceMutationVariables = Exact<{
  [key: string]: never;
}>;

export type AuthenticateChatServiceMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'chatServiceAuthenticate'
>;

export type UpdateUserProfileMutationVariables = Exact<{
  userId: Scalars['ID'];
  firstName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  email: Maybe<Scalars['Email']>;
  phoneNumber: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  roleId: UserRoleEnum;
}>;

export type UpdateUserProfileMutation = { __typename: 'Mutation' } & {
  userUpdate: { __typename: 'UserUpdatePayload' } & {
    user: { __typename: 'User' } & Pick<
      User,
      'id' | 'firstName' | 'middleName' | 'lastName' | 'phoneNumber'
    >;
  };
};

export type ChangeTenantAdminAvatarMutationVariables = Exact<{
  avatarKey: Scalars['String'];
  tenantAdminId: Scalars['ID'];
}>;

export type ChangeTenantAdminAvatarMutation = { __typename: 'Mutation' } & {
  tenantAdminChangeAvatar: { __typename: 'TenantAdminChangeAvatarPayload' } & {
    tenantAdmin: { __typename: 'TenantAdmin' } & Pick<
      TenantAdmin,
      'id' | 'avatarUrl'
    >;
  };
};

export type ChangeStatusUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  active: Scalars['Boolean'];
}>;

export type ChangeStatusUserMutation = { __typename: 'Mutation' } & {
  userChangeActive: { __typename: 'UserChangeActivePayload' } & {
    user: { __typename: 'User' } & Pick<User, 'id' | 'active'>;
  };
};

export type TenantAdminItemFragment = { __typename: 'TenantAdmin' } & Pick<
  TenantAdmin,
  'id' | 'avatarUrl'
> & {
    tenant: { __typename: 'Tenant' } & Pick<Tenant, 'id'>;
    user: { __typename: 'User' } & Pick<
      User,
      'id' | 'active' | 'email' | 'firstName' | 'lastName'
    >;
  };

export type CreateTenantAdminMutationVariables = Exact<{
  tenantId: Scalars['ID'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  password: Scalars['String'];
  roleId: UserRoleEnum;
}>;

export type CreateTenantAdminMutation = { __typename: 'Mutation' } & {
  tenantAdminInvite: { __typename: 'TenantAdminInvitePayload' } & {
    tenantAdmin: { __typename: 'TenantAdmin' } & TenantAdminItemFragment;
  };
};

export type ListCustomerAdminQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<CustomerAdminFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;

export type ListCustomerAdminQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      customerAdmins: { __typename: 'CustomerAdminPage' } & {
        items: Array<
          { __typename: 'CustomerAdmin' } & Pick<
            CustomerAdmin,
            'id' | 'acceptedAt' | 'avatarUrl'
          > & {
              customer: { __typename: 'Customer' } & Pick<
                Customer,
                'id' | 'logoUrl' | 'name'
              >;
              user: { __typename: 'User' } & UserItemFieldsFragment;
            }
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type ListTenantAdminQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<TenantAdminFilterSetInput>;
}>;

export type ListTenantAdminQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      tenant: { __typename: 'Tenant' } & Pick<Tenant, 'id'> & {
          admins: Array<
            { __typename: 'TenantAdmin' } & Pick<
              TenantAdmin,
              'id' | 'avatarUrl' | 'role'
            > & { user: { __typename: 'User' } & UserItemFieldsFragment }
          >;
        };
    };
};

export type AutocompleteCustomersQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  query: Scalars['String'];
  customerStatus: Maybe<
    Array<Scalars['NonNegativeInt']> | Scalars['NonNegativeInt']
  >;
  rateType: Maybe<Array<AccountRateTypeEnum> | AccountRateTypeEnum>;
}>;

export type AutocompleteCustomersQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      customers: { __typename: 'CustomerPage' } & {
        items: Array<
          { __typename: 'Customer' } & Pick<
            Customer,
            'id' | 'name' | 'jobTaxType' | 'status' | 'ltaAllowed'
          > & {
              admins: { __typename: 'CustomerAdminPage' } & {
                items: Array<
                  { __typename: 'CustomerAdmin' } & Pick<
                    CustomerAdmin,
                    'id'
                  > & {
                      user: { __typename: 'User' } & Pick<
                        User,
                        'firstName' | 'lastName' | 'id'
                      >;
                    }
                >;
              };
              accounts: Array<
                { __typename: 'Account' } & Pick<
                  Account,
                  | 'id'
                  | 'name'
                  | 'markupPercent'
                  | 'rateType'
                  | 'active'
                  | 'currentPaymentMethod'
                > & {
                    customer: { __typename: 'Customer' } & Pick<
                      Customer,
                      'id' | 'logoUrl' | 'name'
                    >;
                    defaultContact: { __typename: 'CustomerAdmin' } & Pick<
                      CustomerAdmin,
                      'id' | 'avatarUrl'
                    > & {
                        user: { __typename: 'User' } & Pick<
                          User,
                          | 'id'
                          | 'firstName'
                          | 'lastName'
                          | 'email'
                          | 'phoneNumber'
                        >;
                      };
                  }
              >;
              addresses: Array<
                { __typename: 'Address' } & Pick<
                  Address,
                  | 'id'
                  | 'active'
                  | 'addressLine1'
                  | 'addressLine2'
                  | 'city'
                  | 'name'
                  | 'state'
                  | 'zip'
                > & {
                    coords: { __typename: 'Point' } & Pick<
                      Point,
                      'latitude' | 'longitude'
                    >;
                    rateQuotes: Maybe<
                      Array<
                        { __typename: 'Rate' } & Pick<
                          Rate,
                          'id' | 'pay' | 'cost'
                        > & {
                            account: Maybe<
                              { __typename: 'Account' } & Pick<Account, 'id'>
                            >;
                            address: Maybe<
                              { __typename: 'Address' } & Pick<
                                Address,
                                'id' | 'active'
                              >
                            >;
                            skill: Maybe<
                              { __typename: 'Skill' } & Pick<
                                Skill,
                                'id' | 'name'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
        >;
      };
    };
};

export type ListUniformsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;

export type ListUniformsQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      uniforms: Array<
        { __typename: 'Uniform' } & Pick<
          Uniform,
          'id' | 'name' | 'imageUrl' | 'defaultInstructions'
        >
      >;
    };
};

export type GetRateRangeQueryVariables = Exact<{
  addressId: Scalars['ID'];
  skillId: Scalars['ID'];
}>;

export type GetRateRangeQuery = { __typename: 'Query' } & {
  rateRange: Array<
    { __typename: 'RateRange' } & Pick<RateRange, 'maximum' | 'minimum'>
  >;
};

export type CreateGigOrderMutationVariables = Exact<{
  accountId: Scalars['ID'];
  jobs: Array<JobInput> | JobInput;
}>;

export type CreateGigOrderMutation = { __typename: 'Mutation' } & {
  orderCreate: { __typename: 'OrderCreatePayload' } & Pick<
    OrderCreatePayload,
    'workersHired'
  > & {
      order: { __typename: 'Order' } & Pick<
        Order,
        'id' | 'uuid' | 'jobsCount'
      > & { jobs: Array<{ __typename: 'Job' } & Pick<Job, 'id'>> };
    };
};

export type CreateGroupJobOfferMutationVariables = Exact<{
  jobIds: Array<Scalars['ID']> | Scalars['ID'];
  workerIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type CreateGroupJobOfferMutation = { __typename: 'Mutation' } & {
  jobOfferGroupCreate: { __typename: 'JobOfferGroupCreatePayload' } & Pick<
    JobOfferGroupCreatePayload,
    'status'
  >;
};

export type GetSurgeRatesQueryVariables = Exact<{
  fromDate: Scalars['ISO8601Date'];
}>;

export type GetSurgeRatesQuery = { __typename: 'Query' } & {
  surgeRatesFromDate: Array<
    { __typename: 'SurgeRate' } & Pick<SurgeRate, 'id' | 'date' | 'rate'>
  >;
};

export type GetWorkerRelationshipsByCustomerQueryVariables = Exact<{
  customerId: Scalars['ID'];
  filters: Maybe<WorkerRelationshipFilterSetInput>;
}>;

export type GetWorkerRelationshipsByCustomerQuery = { __typename: 'Query' } & {
  customer: { __typename: 'Customer' } & {
    workerRelationships: Array<
      { __typename: 'WorkerRelationship' } & Pick<
        WorkerRelationship,
        'id' | 'kind'
      > & {
          worker: { __typename: 'Worker' } & Pick<
            Worker,
            'id' | 'avatarUrl'
          > & {
              user: { __typename: 'User' } & Pick<
                User,
                'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
              >;
              skills: Array<{ __typename: 'Skill' } & Pick<Skill, 'id'>>;
            };
        }
    >;
  };
};

export type ListAddressesQueryVariables = Exact<{
  customerId: Scalars['ID'];
  skillId: Scalars['ID'];
}>;

export type ListAddressesQuery = { __typename: 'Query' } & {
  customer: { __typename: 'Customer' } & Pick<Customer, 'id'> & {
      addresses: Array<
        { __typename: 'Address' } & Pick<
          Address,
          | 'id'
          | 'active'
          | 'addressLine1'
          | 'addressLine2'
          | 'city'
          | 'name'
          | 'state'
          | 'zip'
        > & {
            coords: { __typename: 'Point' } & Pick<
              Point,
              'latitude' | 'longitude'
            >;
            rateQuote: Maybe<
              { __typename: 'Rate' } & Pick<Rate, 'id' | 'pay' | 'cost'>
            >;
          }
      >;
    };
};

export type DefaultRateByCityQueryVariables = Exact<{
  skillId: Scalars['ID'];
  addressId: Scalars['ID'];
}>;

export type DefaultRateByCityQuery = { __typename: 'Query' } & {
  defaultRatesBySkill: { __typename: 'DefaultRate' } & Pick<
    DefaultRate,
    'id' | 'costRate' | 'payRate'
  > & {
      skill: Maybe<
        { __typename: 'Skill' } & Pick<
          Skill,
          'id' | 'name' | 'imageUrl' | 'descriptionEn'
        > & {
            category: Maybe<
              { __typename: 'SkillCategory' } & Pick<
                SkillCategory,
                'id' | 'name' | 'imageUrl'
              >
            >;
          }
      >;
    };
};

export type ListSkillCategoriesByDefaultRatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListSkillCategoriesByDefaultRatesQuery = { __typename: 'Query' } & {
  defaultRates: Array<
    { __typename: 'DefaultRate' } & Pick<
      DefaultRate,
      'id' | 'costRate' | 'payRate'
    > & {
        skill: Maybe<
          { __typename: 'Skill' } & Pick<
            Skill,
            'id' | 'name' | 'imageUrl' | 'descriptionEn' | 'gravyTrained'
          > & {
              category: Maybe<
                { __typename: 'SkillCategory' } & Pick<
                  SkillCategory,
                  'id' | 'name' | 'imageUrl'
                >
              >;
            }
        >;
      }
  >;
};

export type ListSkillCategoriesByCustomRatesQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type ListSkillCategoriesByCustomRatesQuery = { __typename: 'Query' } & {
  customer: { __typename: 'Customer' } & Pick<Customer, 'id'> & {
      addresses: Array<
        { __typename: 'Address' } & Pick<Address, 'id' | 'addressLine1'> & {
            rateQuotes: Maybe<
              Array<
                { __typename: 'Rate' } & Pick<Rate, 'id'> & {
                    account: Maybe<
                      { __typename: 'Account' } & Pick<Account, 'id'>
                    >;
                    skill: Maybe<
                      { __typename: 'Skill' } & Pick<
                        Skill,
                        | 'id'
                        | 'descriptionEn'
                        | 'name'
                        | 'imageUrl'
                        | 'gravyTrained'
                      > & {
                          category: Maybe<
                            { __typename: 'SkillCategory' } & Pick<
                              SkillCategory,
                              'id' | 'name' | 'imageUrl'
                            >
                          >;
                        }
                    >;
                  }
              >
            >;
          }
      >;
    };
};

export type FetchNearestRatesQueryVariables = Exact<{
  accountId: Scalars['ID'];
  skillId: Scalars['ID'];
  addressId: Scalars['ID'];
}>;

export type FetchNearestRatesQuery = { __typename: 'Query' } & {
  fetchRates: { __typename: 'FetchRates' } & {
    rates: Maybe<
      Array<{ __typename: 'Rate' } & Pick<Rate, 'cost' | 'id' | 'pay'>>
    >;
    defaultRates: Maybe<
      Array<
        { __typename: 'DefaultRate' } & Pick<
          DefaultRate,
          'costRate' | 'id' | 'payRate'
        >
      >
    >;
  };
};

export type CreateAccountMutationVariables = Exact<{
  customerId: Scalars['ID'];
  defaultContactId: Scalars['ID'];
  name: Scalars['String'];
  city: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  zip: Scalars['String'];
  state: Scalars['String'];
  cutOff: Maybe<InvoiceCutOffEnum>;
  monthlyCutOff: Maybe<Scalars['Boolean']>;
  billingWeek: Maybe<BillingWeekEnum>;
  consolidateUnprocessedCharges: Maybe<Scalars['Boolean']>;
  groupBy: Maybe<InvoiceGroupByEnum>;
  markupPercent: Maybe<Scalars['Float']>;
  rateType: AccountRateTypeEnum;
}>;

export type CreateAccountMutation = { __typename: 'Mutation' } & {
  accountCreate: { __typename: 'AccountCreatePayload' } & {
    account: { __typename: 'Account' } & AccountItemFragment;
  };
};

export type ActiveAddressMutationVariables = Exact<{
  addressId: Scalars['ID'];
}>;

export type ActiveAddressMutation = { __typename: 'Mutation' } & {
  addressValidate: { __typename: 'AddressValidatePayload' } & {
    address: { __typename: 'Address' } & Pick<
      Address,
      | 'id'
      | 'active'
      | 'addressLine1'
      | 'addressLine2'
      | 'city'
      | 'name'
      | 'state'
      | 'zip'
    > & {
        coords: { __typename: 'Point' } & Pick<Point, 'latitude' | 'longitude'>;
      };
  };
};

export type CreateAddressMutationVariables = Exact<{
  customerId: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  neighborhood: Scalars['String'];
  coords: PointInput;
  name: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
}>;

export type CreateAddressMutation = { __typename: 'Mutation' } & {
  addressCreate: { __typename: 'AddressCreatePayload' } & {
    address: { __typename: 'Address' } & AddressItemFragment;
  };
};

export type DeleteAddressMutationVariables = Exact<{
  addressId: Scalars['ID'];
}>;

export type DeleteAddressMutation = { __typename: 'Mutation' } & {
  addressDelete: { __typename: 'AddressDeletePayload' } & Pick<
    AddressDeletePayload,
    'success'
  >;
};

export type UpdateAddressMutationVariables = Exact<{
  addressId: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  neighborhood: Scalars['String'];
  coords: PointInput;
  name: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
}>;

export type UpdateAddressMutation = { __typename: 'Mutation' } & {
  addressUpdate: { __typename: 'AddressUpdatePayload' } & {
    address: { __typename: 'Address' } & Pick<
      Address,
      'id' | 'addressLine1' | 'addressLine2' | 'city' | 'name' | 'state' | 'zip'
    > & {
        coords: { __typename: 'Point' } & Pick<Point, 'latitude' | 'longitude'>;
      };
  };
};

export type GetCustomersAdminsPaginetedQueryVariables = Exact<{
  customerId: Scalars['ID'];
  page: Maybe<Scalars['PositiveInt']>;
  perPage: Maybe<Scalars['PositiveInt']>;
}>;

export type GetCustomersAdminsPaginetedQuery = { __typename: 'Query' } & {
  customer: { __typename: 'Customer' } & Pick<Customer, 'id'> & {
      admins: { __typename: 'CustomerAdminPage' } & {
        items: Array<{ __typename: 'CustomerAdmin' } & AdminItemFragment>;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type InviteAdminMutationVariables = Exact<{
  customerId: Scalars['ID'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  password: Maybe<Scalars['String']>;
}>;

export type InviteAdminMutation = { __typename: 'Mutation' } & {
  customerInviteAdmin: { __typename: 'CustomerInviteAdminPayload' } & {
    customerAdmin: { __typename: 'CustomerAdmin' } & AdminItemFragment;
  };
};

export type CreateRateMutationVariables = Exact<{
  agencyId: Scalars['ID'];
  skillId: Scalars['ID'];
  accountId: Scalars['ID'];
  addressId: Scalars['ID'];
  baseCost: Scalars['PositiveInt'];
  basePay: Scalars['PositiveInt'];
}>;

export type CreateRateMutation = { __typename: 'Mutation' } & {
  rateCreate: { __typename: 'RateCreatePayload' } & {
    rate: { __typename: 'Rate' } & Pick<Rate, 'id' | 'cost' | 'pay'> & {
        account: Maybe<
          { __typename: 'Account' } & Pick<Account, 'id' | 'name'>
        >;
        address: Maybe<
          { __typename: 'Address' } & Pick<
            Address,
            'id' | 'addressLine1' | 'city' | 'state' | 'zip'
          >
        >;
        skill: Maybe<{ __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>>;
      };
  };
};

export type CustomRateCreateBatchMutationVariables = Exact<{
  accountId: Scalars['ID'];
  agencyId: Scalars['ID'];
  baseCost: Scalars['PositiveInt'];
  basePay: Scalars['PositiveInt'];
  skillId: Scalars['ID'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
}>;

export type CustomRateCreateBatchMutation = { __typename: 'Mutation' } & {
  customRateCreateBatch: { __typename: 'CustomRateCreateBatchPayload' } & Pick<
    CustomRateCreateBatchPayload,
    'allCreated' | 'message'
  >;
};

export type DeleteRateMutationVariables = Exact<{
  rateId: Scalars['ID'];
}>;

export type DeleteRateMutation = { __typename: 'Mutation' } & {
  rateDelete: { __typename: 'RateDeletePayload' } & Pick<
    RateDeletePayload,
    'deleted'
  >;
};

export type UpdateRateMutationVariables = Exact<{
  rateId: Scalars['ID'];
  baseCost: Scalars['PositiveInt'];
  basePay: Scalars['PositiveInt'];
}>;

export type UpdateRateMutation = { __typename: 'Mutation' } & {
  rateUpdate: { __typename: 'RateUpdatePayload' } & {
    rate: { __typename: 'Rate' } & Pick<Rate, 'id' | 'cost' | 'pay'> & {
        account: Maybe<
          { __typename: 'Account' } & Pick<Account, 'id' | 'name'>
        >;
        address: Maybe<
          { __typename: 'Address' } & Pick<
            Address,
            'id' | 'addressLine1' | 'city' | 'state' | 'zip'
          >
        >;
        skill: Maybe<{ __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>>;
      };
  };
};

export type AdminItemFragment = { __typename: 'CustomerAdmin' } & Pick<
  CustomerAdmin,
  'id' | 'acceptedAt' | 'avatarUrl' | 'active'
> & {
    user: { __typename: 'User' } & Pick<
      User,
      | 'id'
      | 'active'
      | 'email'
      | 'firstName'
      | 'language'
      | 'lastName'
      | 'emailConfirmed'
      | 'channelId'
    >;
  };

export type AccountItemFragment = { __typename: 'Account' } & Pick<
  Account,
  | 'id'
  | 'name'
  | 'markupPercent'
  | 'rateType'
  | 'currentPaymentMethod'
  | 'active'
  | 'canDisable'
> & {
    defaultContact: { __typename: 'CustomerAdmin' } & Pick<CustomerAdmin, 'id'>;
  };

export type WorkerRelationshipItemFragment = {
  __typename: 'WorkerRelationship';
} & Pick<WorkerRelationship, 'id' | 'kind'> & {
    worker: { __typename: 'Worker' } & Pick<
      Worker,
      'id' | 'avatarUrl' | 'addressLine1' | 'city' | 'state'
    > & {
        user: { __typename: 'User' } & Pick<
          User,
          | 'id'
          | 'firstName'
          | 'middleName'
          | 'lastName'
          | 'email'
          | 'phoneNumber'
        >;
      };
  };

export type AddressItemFragment = { __typename: 'Address' } & Pick<
  Address,
  | 'id'
  | 'addressLine1'
  | 'addressLine2'
  | 'city'
  | 'name'
  | 'state'
  | 'zip'
  | 'active'
  | 'jobsCount'
> & {
    coords: { __typename: 'Point' } & Pick<Point, 'latitude' | 'longitude'>;
    rateQuotes: Maybe<
      Array<
        { __typename: 'Rate' } & Pick<Rate, 'id' | 'cost' | 'pay'> & {
            account: Maybe<
              { __typename: 'Account' } & Pick<Account, 'id' | 'name'>
            >;
            address: Maybe<
              { __typename: 'Address' } & Pick<
                Address,
                'id' | 'addressLine1' | 'city' | 'state' | 'active' | 'zip'
              >
            >;
            skill: Maybe<
              { __typename: 'Skill' } & Pick<Skill, 'id' | 'name' | 'imageUrl'>
            >;
          }
      >
    >;
  };

export type CustomerItemFragment = { __typename: 'Customer' } & Pick<
  Customer,
  | 'id'
  | 'invoiceDueDate'
  | 'logoUrl'
  | 'name'
  | 'jobTaxType'
  | 'status'
  | 'ltaAllowed'
  | 'markupPercent'
  | 'rateType'
> & {
    addresses: Array<{ __typename: 'Address' } & AddressItemFragment>;
    accounts: Array<{ __typename: 'Account' } & AccountItemFragment>;
    admins: { __typename: 'CustomerAdminPage' } & {
      items: Array<{ __typename: 'CustomerAdmin' } & AdminItemFragment>;
    };
    workerRelationships: Array<
      { __typename: 'WorkerRelationship' } & WorkerRelationshipItemFragment
    >;
  };

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['ID'];
  selectNearbyRate?: Maybe<Scalars['Boolean']>;
}>;

export type GetCustomerQuery = { __typename: 'Query' } & {
  customer: { __typename: 'Customer' } & Pick<
    Customer,
    | 'id'
    | 'invoiceDueDate'
    | 'logoUrl'
    | 'name'
    | 'jobTaxType'
    | 'status'
    | 'ltaAllowed'
    | 'markupPercent'
    | 'rateType'
  > & {
      accounts: Array<{ __typename: 'Account' } & AccountItemFragment>;
      admins: { __typename: 'CustomerAdminPage' } & {
        items: Array<{ __typename: 'CustomerAdmin' } & AdminItemFragment>;
      };
      workerRelationships: Array<
        { __typename: 'WorkerRelationship' } & WorkerRelationshipItemFragment
      >;
      addresses: Array<
        { __typename: 'Address' } & Pick<
          Address,
          | 'id'
          | 'addressLine1'
          | 'addressLine2'
          | 'city'
          | 'name'
          | 'state'
          | 'zip'
          | 'active'
          | 'jobsCount'
        > & {
            coords: { __typename: 'Point' } & Pick<
              Point,
              'latitude' | 'longitude'
            >;
            rateQuotes: Maybe<
              Array<
                { __typename: 'Rate' } & Pick<Rate, 'id' | 'cost' | 'pay'> & {
                    account: Maybe<
                      { __typename: 'Account' } & Pick<Account, 'id' | 'name'>
                    >;
                    address: Maybe<
                      { __typename: 'Address' } & Pick<
                        Address,
                        | 'id'
                        | 'addressLine1'
                        | 'city'
                        | 'state'
                        | 'active'
                        | 'zip'
                      >
                    >;
                    skill: Maybe<
                      { __typename: 'Skill' } & Pick<
                        Skill,
                        'id' | 'name' | 'imageUrl'
                      >
                    >;
                  }
              >
            >;
          }
      >;
    };
};

export type GetCustomerInvoiceQueryVariables = Exact<{
  customerId: Scalars['ID'];
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;

export type GetCustomerInvoiceQuery = { __typename: 'Query' } & {
  customer: { __typename: 'Customer' } & Pick<Customer, 'id'> & {
      invoices: { __typename: 'InvoicePage' } & {
        items: Array<
          { __typename: 'Invoice' } & Pick<
            Invoice,
            | 'id'
            | 'number'
            | 'total'
            | 'createdDatetime'
            | 'dueDateDatetime'
            | 'dueDate'
            | 'status'
          > & {
              account: { __typename: 'Account' } & Pick<Account, 'id' | 'name'>;
            }
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type GetCustomerOrdersQueryVariables = Exact<{
  customerId: Scalars['ID'];
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;

export type GetCustomerOrdersQuery = { __typename: 'Query' } & {
  customer: { __typename: 'Customer' } & Pick<Customer, 'id'> & {
      orders: { __typename: 'OrderPage' } & {
        items: Array<{ __typename: 'Order' } & OrderListItemFragment>;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type UpdateCustomerMutationVariables = Exact<{
  customerId: Scalars['ID'];
  invoiceDueDate: Maybe<DefaultDueDateEnum>;
  name: Scalars['String'];
  taxType: TaxTypeEnum;
  ltaAllowed: Scalars['Boolean'];
  markupPercent: Scalars['Float'];
}>;

export type UpdateCustomerMutation = { __typename: 'Mutation' } & {
  customerUpdate: { __typename: 'CustomerUpdatePayload' } & {
    customer: { __typename: 'Customer' } & Pick<
      Customer,
      'id' | 'name' | 'invoiceDueDate'
    >;
  };
};

export type UpdateJobTaxTypeMutationVariables = Exact<{
  taxType: TaxTypeEnum;
  customerId: Scalars['ID'];
}>;

export type UpdateJobTaxTypeMutation = { __typename: 'Mutation' } & {
  customerUpdateJobTaxType: {
    __typename: 'CustomerUpdateJobTaxTypePayload';
  } & {
    customer: { __typename: 'Customer' } & Pick<Customer, 'id' | 'jobTaxType'>;
  };
};

export type CreateCustomerWorkerRelationshipMutationVariables = Exact<{
  customerId: Scalars['ID'];
  kind: RelationshipKindEnum;
  workerId: Scalars['ID'];
}>;

export type CreateCustomerWorkerRelationshipMutation = {
  __typename: 'Mutation';
} & {
  customerRelateWorker: { __typename: 'CustomerRelateWorkerPayload' } & {
    workerRelationship: { __typename: 'WorkerRelationship' } & Pick<
      WorkerRelationship,
      'id' | 'kind'
    > & {
        worker: { __typename: 'Worker' } & Pick<
          Worker,
          'id' | 'avatarUrl' | 'addressLine1' | 'city' | 'state'
        > & {
            user: { __typename: 'User' } & Pick<
              User,
              'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
            >;
          };
      };
  };
};

export type RemoveCustomerWorkerRelationshipMutationVariables = Exact<{
  customerId: Scalars['ID'];
  workerId: Scalars['ID'];
  kind: RelationshipKindEnum;
}>;

export type RemoveCustomerWorkerRelationshipMutation = {
  __typename: 'Mutation';
} & Pick<Mutation, 'customerUnrelateWorker'>;

export type ChangeCustomerLogoMutationVariables = Exact<{
  logoKey: Scalars['String'];
  customerId: Scalars['ID'];
}>;

export type ChangeCustomerLogoMutation = { __typename: 'Mutation' } & {
  customerChangeLogo: { __typename: 'CustomerChangeLogoPayload' } & {
    customer: { __typename: 'Customer' } & Pick<Customer, 'id' | 'logoUrl'>;
  };
};

export type UpdateCustomerAdminProfileMutationVariables = Exact<{
  customerAdminId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Maybe<Scalars['Email']>;
  title: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
}>;

export type UpdateCustomerAdminProfileMutation = { __typename: 'Mutation' } & {
  customerAdminUpdateProfile: {
    __typename: 'CustomerAdminUpdateProfilePayload';
  } & {
    customerAdmin: { __typename: 'CustomerAdmin' } & Pick<
      CustomerAdmin,
      'id' | 'title'
    > & { user: { __typename: 'User' } & UserItemFieldsFragment };
  };
};

export type UserItemFieldsFragment = { __typename: 'User' } & Pick<
  User,
  | 'id'
  | 'active'
  | 'firstName'
  | 'language'
  | 'lastName'
  | 'email'
  | 'emailConfirmed'
  | 'phoneNumber'
  | 'allowedPlatform'
  | 'channelId'
  | 'roleId'
>;

export type GetAdminCustomerQueryVariables = Exact<{
  customerAdminId: Scalars['ID'];
}>;

export type GetAdminCustomerQuery = { __typename: 'Query' } & {
  customerAdmin: { __typename: 'CustomerAdmin' } & Pick<
    CustomerAdmin,
    'id' | 'avatarUrl' | 'title' | 'acceptedAt'
  > & {
      customer: { __typename: 'Customer' } & Pick<
        Customer,
        'id' | 'logoUrl' | 'name'
      >;
      user: { __typename: 'User' } & UserItemFieldsFragment;
    };
};

export type ResendCustomerAdminInvitationMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type ResendCustomerAdminInvitationMutation = {
  __typename: 'Mutation';
} & {
  customerAdminResendInvitation: {
    __typename: 'CustomerAdminResendInvitationPayload';
  } & Pick<CustomerAdminResendInvitationPayload, 'result'>;
};

export type ChangeCustomerAdminAvatarMutationVariables = Exact<{
  avatarKey: Scalars['String'];
  customerAdminId: Scalars['ID'];
}>;

export type ChangeCustomerAdminAvatarMutation = { __typename: 'Mutation' } & {
  customerAdminChangeAvatar: {
    __typename: 'CustomerAdminChangeAvatarPayload';
  } & {
    customerAdmin: { __typename: 'CustomerAdmin' } & Pick<
      CustomerAdmin,
      'id' | 'avatarUrl'
    >;
  };
};

export type CreateCustomerMutationVariables = Exact<{
  agencyId: Scalars['ID'];
  name: Scalars['String'];
}>;

export type CreateCustomerMutation = { __typename: 'Mutation' } & {
  customerCreate: { __typename: 'CustomerCreatePayload' } & {
    customer: { __typename: 'Customer' } & Pick<Customer, 'id'>;
  };
};

export type RegionQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;

export type RegionQuery = { __typename: 'Query' } & {
  region: Array<
    { __typename: 'Region' } & Pick<
      Region,
      'id' | 'isActive' | 'jobTaxType' | 'name'
    >
  >;
};

export type ListCustomersQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<CustomerFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
  sortKey: Maybe<CustomerSortKeyEnum>;
  reverse: Maybe<Scalars['Boolean']>;
}>;

export type ListCustomersQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      customers: { __typename: 'CustomerPage' } & {
        items: Array<
          { __typename: 'Customer' } & Pick<
            Customer,
            | 'id'
            | 'logoUrl'
            | 'name'
            | 'createdAt'
            | 'status'
            | 'onboardingStatus'
            | 'jobTaxType'
          > & {
              accounts: Array<
                { __typename: 'Account' } & Pick<Account, 'state'>
              >;
            }
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type GetCustomerAndSkillsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;

export type GetCustomerAndSkillsQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      customers: { __typename: 'CustomerPage' } & {
        items: Array<
          { __typename: 'Customer' } & Pick<Customer, 'id' | 'name'>
        >;
      };
      skillCategories: Array<
        { __typename: 'SkillCategory' } & Pick<SkillCategory, 'id' | 'name'> & {
            skills: Array<{ __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>>;
          }
      >;
    };
};

export type ListShiftsByWeekQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
  filters: Maybe<JobFilterSetInput>;
  timeZone: Maybe<Scalars['String']>;
}>;

export type ListShiftsByWeekQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      shiftsByWeek: Array<
        { __typename: 'Shift' } & Pick<Shift, 'id' | 'endAt' | 'startAt'> & {
            job: { __typename: 'Job' } & Pick<
              Job,
              | 'id'
              | 'hiredWorkersCount'
              | 'quantity'
              | 'published'
              | 'cancelledAt'
              | 'jobType'
            > & {
                account: { __typename: 'Account' } & Pick<
                  Account,
                  'id' | 'name'
                > & {
                    customer: { __typename: 'Customer' } & Pick<
                      Customer,
                      'id' | 'name'
                    >;
                  };
                address: { __typename: 'Address' } & Pick<
                  Address,
                  'id' | 'addressLine1' | 'city' | 'state' | 'zip'
                >;
                order: { __typename: 'Order' } & Pick<Order, 'id' | 'uuid'>;
                skill: { __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>;
              };
          }
      >;
    };
};

export type GetAgencyByKeyQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  key: Scalars['Key'];
}>;

export type GetAgencyByKeyQuery = { __typename: 'Query' } & {
  agencyByKey: { __typename: 'Agency' } & CurrentAgencyFragment;
};

export type CustomerListingQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  query: Scalars['String'];
  customerStatus: Maybe<
    Array<Scalars['NonNegativeInt']> | Scalars['NonNegativeInt']
  >;
}>;

export type CustomerListingQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      customers: { __typename: 'CustomerPage' } & {
        items: Array<
          { __typename: 'Customer' } & Pick<
            Customer,
            'id' | 'name' | 'jobTaxType' | 'status'
          > & {
              accounts: Array<
                { __typename: 'Account' } & Pick<
                  Account,
                  'id' | 'name' | 'currentPaymentMethod'
                > & {
                    customer: { __typename: 'Customer' } & Pick<
                      Customer,
                      'id' | 'logoUrl' | 'name'
                    >;
                    defaultContact: { __typename: 'CustomerAdmin' } & Pick<
                      CustomerAdmin,
                      'id' | 'avatarUrl'
                    > & {
                        user: { __typename: 'User' } & Pick<
                          User,
                          | 'id'
                          | 'firstName'
                          | 'lastName'
                          | 'email'
                          | 'phoneNumber'
                        >;
                      };
                  }
              >;
            }
        >;
      };
    };
};

export type GenerateInvoiceTimesheetsQueryVariables = Exact<{
  customerId: Scalars['ID'];
  filters: Maybe<TimesheetFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;

export type GenerateInvoiceTimesheetsQuery = { __typename: 'Query' } & {
  generateInvoiceTimesheets: { __typename: 'TimesheetPage' } & {
    items: Array<
      { __typename: 'Timesheet' } & Pick<
        Timesheet,
        | 'id'
        | 'approvedCheckinAt'
        | 'costRate'
        | 'tipAmount'
        | 'netApprovedTime'
        | 'holdByInvoiceId'
      > & {
          invoiceItems: Maybe<
            Array<
              { __typename: 'InvoiceItem' } & Pick<
                InvoiceItem,
                | 'id'
                | 'shortDescription'
                | 'billRate'
                | 'isEdited'
                | 'cancelled'
                | 'isOvertime'
                | 'isHoliday'
                | 'order'
                | 'amount'
                | 'netTime'
                | 'jobId'
                | 'tip'
              > & {
                  skill: Maybe<
                    { __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>
                  >;
                  timesheetInvoice: Maybe<
                    { __typename: 'Invoice' } & Pick<Invoice, 'id' | 'status'>
                  >;
                }
            >
          >;
          jobWorker: { __typename: 'JobWorker' } & {
            worker: { __typename: 'Worker' } & Pick<Worker, 'id'> & {
                user: { __typename: 'User' } & Pick<
                  User,
                  'firstName' | 'middleName' | 'lastName'
                >;
              };
          };
          job: { __typename: 'Job' } & Pick<Job, 'id'> & {
              skill: { __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>;
              address: { __typename: 'Address' } & Pick<
                Address,
                | 'id'
                | 'addressLine1'
                | 'addressLine2'
                | 'city'
                | 'state'
                | 'zip'
              >;
              order: { __typename: 'Order' } & Pick<Order, 'id'>;
            };
        }
    >;
  };
};

export type InvoiceGenerateMutationVariables = Exact<{
  customerId: Scalars['ID'];
  accountId: Scalars['ID'];
  timesheetIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type InvoiceGenerateMutation = { __typename: 'Mutation' } & {
  invoiceGenerate: { __typename: 'InvoiceGeneratePayload' } & {
    invoice: { __typename: 'Invoice' } & Pick<Invoice, 'id'>;
  };
};

export type FinishDraftMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;

export type FinishDraftMutation = { __typename: 'Mutation' } & {
  stripeInvoiceFinalize: { __typename: 'StripeInvoiceFinalizePayload' } & {
    invoice: { __typename: 'Invoice' } & Pick<Invoice, 'status'>;
  };
};

export type VoidInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;

export type VoidInvoiceMutation = { __typename: 'Mutation' } & {
  stripeInvoiceVoid: { __typename: 'StripeInvoiceVoidPayload' } & {
    invoice: { __typename: 'Invoice' } & Pick<Invoice, 'status'>;
  };
};

export type EditInvoiceDetailMutationVariables = Exact<{
  invoiceId: Scalars['String'];
  description: Maybe<Scalars['String']>;
  dueDate: Scalars['ISO8601DateTime'];
}>;

export type EditInvoiceDetailMutation = { __typename: 'Mutation' } & {
  stripeInvoiceUpdate: { __typename: 'StripeInvoiceUpdatePayload' } & {
    invoice: Maybe<
      { __typename: 'Invoice' } & Pick<
        Invoice,
        'id' | 'description' | 'dueDate'
      >
    >;
  };
};

export type GetTimesheetPdfMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;

export type GetTimesheetPdfMutation = { __typename: 'Mutation' } & {
  invoiceTimesheetsPdf: { __typename: 'InvoiceTimesheetsPdfPayload' } & {
    pdfCreated: { __typename: 'UploadURL' } & Pick<UploadUrl, 'key' | 'url'>;
  };
};

export type GetInvoiceDetailsRevampQueryVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;

export type GetInvoiceDetailsRevampQuery = { __typename: 'Query' } & {
  listStripeInvoiceDetail: { __typename: 'StripeInvoiceDetail' } & {
    invoice: { __typename: 'Invoice' } & Pick<
      Invoice,
      | 'id'
      | 'autoApprove'
      | 'stripeInvoiceId'
      | 'amountDue'
      | 'invoicePdf'
      | 'status'
      | 'defaultContactEmail'
      | 'subtotal'
      | 'total'
      | 'created'
      | 'invoiceFinalizedAt'
      | 'createdDatetime'
      | 'description'
      | 'dueDate'
      | 'dueDateDatetime'
      | 'deleted'
      | 'hostedInvoiceUrl'
      | 'number'
      | 'invoiceUid'
    > & {
        customer: { __typename: 'Customer' } & Pick<Customer, 'name' | 'id'>;
        account: { __typename: 'Account' } & Pick<
          Account,
          'currentPaymentMethod'
        > &
          AccountFragmentFragment;
      };
    invoiceItems: Maybe<
      Array<
        { __typename: 'InvoiceItem' } & Pick<
          InvoiceItem,
          | 'id'
          | 'address'
          | 'date'
          | 'order'
          | 'currency'
          | 'stripeCustomerId'
          | 'shortDescription'
          | 'invoice'
          | 'amount'
          | 'billRate'
          | 'isEdited'
          | 'netTime'
          | 'cancelled'
          | 'isOvertime'
          | 'isHoliday'
          | 'timesheetId'
          | 'netTimeMinutes'
          | 'tip'
          | 'jobId'
        > & {
            workerTimesheet: Maybe<
              { __typename: 'Timesheet' } & Pick<
                Timesheet,
                | 'approvedBreakMinutes'
                | 'approvedCheckinAt'
                | 'approvedCheckoutAt'
                | 'updatedByType'
              > & {
                  updatedBy: Maybe<
                    { __typename: 'User' } & Pick<
                      User,
                      'firstName' | 'lastName'
                    >
                  >;
                }
            >;
            skill: Maybe<{ __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>>;
            worker: Maybe<
              { __typename: 'Worker' } & Pick<Worker, 'id'> & {
                  user: { __typename: 'User' } & Pick<
                    User,
                    'id' | 'firstName' | 'lastName'
                  >;
                }
            >;
          }
      >
    >;
  };
};

export type DeleteInvoiceItemMutationVariables = Exact<{
  invoiceItemId: Scalars['ID'];
}>;

export type DeleteInvoiceItemMutation = { __typename: 'Mutation' } & {
  stripeInvoiceItemDelete: {
    __typename: 'StripeInvoiceItemDeletePayload';
  } & Pick<StripeInvoiceItemDeletePayload, 'deleted'>;
};

export type AddInvoiceItemMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
  amount: Scalars['String'];
  description: Scalars['String'];
}>;

export type AddInvoiceItemMutation = { __typename: 'Mutation' } & {
  stripeInvoiceItemCreate: { __typename: 'StripeInvoiceItemCreatePayload' } & {
    invoiceItem: Maybe<{ __typename: 'InvoiceItem' } & Pick<InvoiceItem, 'id'>>;
  };
};

export type EditInvoiceItemMutationVariables = Exact<{
  invoiceItemId: Scalars['ID'];
  description: Maybe<Scalars['String']>;
  amount: Maybe<Scalars['String']>;
}>;

export type EditInvoiceItemMutation = { __typename: 'Mutation' } & {
  stripeInvoiceItemUpdate: { __typename: 'StripeInvoiceItemUpdatePayload' } & {
    invoiceItem: Maybe<{ __typename: 'InvoiceItem' } & Pick<InvoiceItem, 'id'>>;
  };
};

export type ToggleAutoApproveMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;

export type ToggleAutoApproveMutation = { __typename: 'Mutation' } & {
  switchAutoApprove: { __typename: 'SwitchAutoApprovePayload' } & {
    invoice: { __typename: 'Invoice' } & Pick<Invoice, 'id' | 'autoApprove'>;
  };
};

export type GetInvoiceDetailsQueryVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;

export type GetInvoiceDetailsQuery = { __typename: 'Query' } & {
  listStripeInvoiceDetail: { __typename: 'StripeInvoiceDetail' } & {
    invoice: { __typename: 'Invoice' } & Pick<
      Invoice,
      | 'id'
      | 'autoApprove'
      | 'stripeInvoiceId'
      | 'amountDue'
      | 'invoicePdf'
      | 'status'
      | 'subtotal'
      | 'total'
      | 'created'
      | 'createdDatetime'
      | 'description'
      | 'dueDate'
      | 'dueDateDatetime'
      | 'hostedInvoiceUrl'
      | 'number'
    > & { customer: { __typename: 'Customer' } & Pick<Customer, 'name'> };
    invoiceItems: Maybe<
      Array<
        { __typename: 'InvoiceItem' } & Pick<
          InvoiceItem,
          | 'id'
          | 'address'
          | 'date'
          | 'order'
          | 'currency'
          | 'stripeCustomerId'
          | 'shortDescription'
          | 'invoice'
          | 'amount'
          | 'billRate'
          | 'isEdited'
          | 'netTime'
          | 'cancelled'
          | 'isOvertime'
          | 'isHoliday'
          | 'timesheetId'
          | 'tip'
          | 'jobId'
        > & {
            skill: Maybe<{ __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>>;
            worker: Maybe<
              { __typename: 'Worker' } & Pick<Worker, 'id'> & {
                  user: { __typename: 'User' } & Pick<
                    User,
                    'id' | 'firstName' | 'lastName'
                  >;
                }
            >;
          }
      >
    >;
  };
};

export type GetInvoiceQueryVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;

export type GetInvoiceQuery = { __typename: 'Query' } & {
  invoice: { __typename: 'Invoice' } & Pick<
    Invoice,
    | 'id'
    | 'amountDue'
    | 'defaultContactEmail'
    | 'description'
    | 'dueDate'
    | 'invoicePdf'
    | 'number'
    | 'status'
  > & {
      account: { __typename: 'Account' } & Pick<
        Account,
        'currentPaymentMethod'
      > &
        AccountFragmentFragment;
    };
};

export type CreatePaymentIntentMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;

export type CreatePaymentIntentMutation = { __typename: 'Mutation' } & {
  paymentIntentCreate: { __typename: 'PaymentIntentCreatePayload' } & Pick<
    PaymentIntentCreatePayload,
    'clientSecret'
  >;
};

export type InvoicePayMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;

export type InvoicePayMutation = { __typename: 'Mutation' } & {
  invoicePay: { __typename: 'InvoicePayPayload' } & Pick<
    InvoicePayPayload,
    'success'
  >;
};

export type UpdatePaymentIntentAchProcessMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
  clientSecret: Scalars['String'];
  paymentMethodId: Scalars['String'];
}>;

export type UpdatePaymentIntentAchProcessMutation = {
  __typename: 'Mutation';
} & Pick<Mutation, 'paymentIntentAchProcessUpdate'>;

export type ListInvoicesQueryVariables = Exact<{
  page: Maybe<Scalars['PositiveInt']>;
  perPage: Maybe<Scalars['PositiveInt']>;
  filters: Maybe<InvoiceFilterSetInput>;
  sortKey: Maybe<InvoiceSortKeyEnum>;
  reverse: Maybe<Scalars['Boolean']>;
}>;

export type ListInvoicesQuery = { __typename: 'Query' } & {
  listStripeInvoices: { __typename: 'InvoicePage' } & {
    items: Array<
      { __typename: 'Invoice' } & Pick<
        Invoice,
        | 'id'
        | 'total'
        | 'createdDatetime'
        | 'dueDateDatetime'
        | 'dueDate'
        | 'status'
        | 'number'
      > & {
          customer: { __typename: 'Customer' } & Pick<Customer, 'id' | 'name'>;
          account: { __typename: 'Account' } & Pick<Account, 'id' | 'name'>;
        }
    >;
    pageInfo: { __typename: 'PageInfo' } & Pick<
      PageInfo,
      'totalItems' | 'totalPages'
    >;
  };
};

export type AcceptRequestMutationVariables = Exact<{
  jobRequestId: Scalars['ID'];
}>;

export type AcceptRequestMutation = { __typename: 'Mutation' } & {
  jobRequestAccept: { __typename: 'JobRequestAcceptPayload' } & {
    job: { __typename: 'Job' } & Pick<Job, 'id'> & {
        skill: { __typename: 'Skill' } & Pick<Skill, 'name'>;
      };
  };
};

export type CancelJobMutationVariables = Exact<{
  jobId: Scalars['ID'];
  userId: Scalars['ID'];
  reason: Scalars['String'];
}>;

export type CancelJobMutation = { __typename: 'Mutation' } & {
  jobCancel: { __typename: 'JobCancelPayload' } & Pick<
    JobCancelPayload,
    'reason'
  > & {
      cancelUser: { __typename: 'User' } & Pick<
        User,
        'id' | 'firstName' | 'lastName'
      >;
    };
};

export type ListJobCustomerAdminsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type ListJobCustomerAdminsQuery = { __typename: 'Query' } & {
  job: { __typename: 'Job' } & Pick<Job, 'id'> & {
      customer: { __typename: 'Customer' } & Pick<Customer, 'id'> & {
          admins: { __typename: 'CustomerAdminPage' } & {
            items: Array<
              { __typename: 'CustomerAdmin' } & Pick<
                CustomerAdmin,
                'id' | 'avatarUrl'
              > & {
                  user: { __typename: 'User' } & Pick<
                    User,
                    'id' | 'firstName' | 'lastName' | 'email'
                  >;
                }
            >;
          };
        };
    };
};

export type GetJobHistoryQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type GetJobHistoryQuery = { __typename: 'Query' } & {
  jobHistory: { __typename: 'JobHistory' } & {
    createdBy: Maybe<
      { __typename: 'User' } & Pick<
        User,
        'firstName' | 'middleName' | 'lastName'
      >
    >;
  };
};

export type ListAgencyUniformsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;

export type ListAgencyUniformsQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      uniforms: Array<
        { __typename: 'Uniform' } & Pick<
          Uniform,
          'id' | 'name' | 'imageUrl' | 'defaultInstructions'
        >
      >;
    };
};

export type JobOfferItemFragment = { __typename: 'JobOffer' } & Pick<
  JobOffer,
  'id' | 'acceptedAt' | 'costRate' | 'createdAt' | 'payRate' | 'rejectedAt'
> & { worker: { __typename: 'Worker' } & WorkerItemFragment };

export type WorkerItemFragment = { __typename: 'Worker' } & Pick<
  Worker,
  | 'id'
  | 'avatarUrl'
  | 'city'
  | 'state'
  | 'averageRating'
  | 'jobsRatingCount'
  | 'jobsWorked'
  | 'shiftsWorked'
  | 'jobsDropped'
  | 'noShow'
  | 'taxType'
> & {
    activeCertificates: Array<
      { __typename: 'Certificate' } & Pick<
        Certificate,
        'id' | 'name' | 'imageUrl'
      >
    >;
    workerSkills: Array<
      { __typename: 'WorkerSkill' } & Pick<WorkerSkill, 'jobsWorked'> & {
          skill: { __typename: 'Skill' } & Pick<
            Skill,
            'id' | 'imageUrl' | 'name'
          >;
        }
    >;
    user: { __typename: 'User' } & Pick<
      User,
      'id' | 'firstName' | 'middleName' | 'lastName' | 'email' | 'phoneNumber'
    >;
  };

export type JobWorkerItemFragment = { __typename: 'JobWorker' } & Pick<
  JobWorker,
  | 'id'
  | 'costRate'
  | 'payRate'
  | 'dropReason'
  | 'droppedAt'
  | 'dismissReason'
  | 'dismissedAt'
  | 'noShowMarkedAt'
  | 'showed'
  | 'status'
> & {
    noShowMarkedBy: Maybe<
      { __typename: 'User' } & Pick<
        User,
        'firstName' | 'middleName' | 'lastName'
      >
    >;
    dismissedBy: Maybe<
      { __typename: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName'>
    >;
    worker: { __typename: 'Worker' } & WorkerItemFragment;
  };

export type TimesheetItemFragment = { __typename: 'Timesheet' } & Pick<
  Timesheet,
  | 'id'
  | 'tipAmount'
  | 'approvedAt'
  | 'rejectedAt'
  | 'approvedBreakMinutes'
  | 'approvedCheckinAt'
  | 'approvedCheckoutAt'
  | 'approvedMinutes'
  | 'netApprovedTime'
  | 'checkinAt'
  | 'createdAt'
  | 'rating'
  | 'updatedAt'
  | 'updatedByType'
  | 'checkoutAt'
  | 'payRate'
  | 'reportedCheckinAt'
  | 'reportedCheckoutAt'
  | 'reportedBreakMinutes'
  | 'reportComment'
  | 'status'
> & {
    timesheetComments: Array<
      { __typename: 'TimesheetComment' } & Pick<
        TimesheetComment,
        'actorType' | 'body' | 'createdAt'
      > & {
          actor: Maybe<
            { __typename: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName'>
          >;
        }
    >;
    updatedBy: Maybe<
      { __typename: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName'>
    >;
    job: { __typename: 'Job' } & Pick<Job, 'id'>;
    checkinCoords: Maybe<
      { __typename: 'Point' } & Pick<Point, 'latitude' | 'longitude'>
    >;
    checkoutCoords: Maybe<
      { __typename: 'Point' } & Pick<Point, 'latitude' | 'longitude'>
    >;
    jobWorker: { __typename: 'JobWorker' } & Pick<JobWorker, 'id'> & {
        worker: { __typename: 'Worker' } & Pick<Worker, 'id' | 'avatarUrl'> & {
            user: { __typename: 'User' } & Pick<
              User,
              'id' | 'email' | 'firstName' | 'middleName' | 'lastName'
            >;
          };
      };
    shift: { __typename: 'Shift' } & Pick<Shift, 'id' | 'endAt' | 'startAt'>;
  };

export type GetJobQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type GetJobQuery = { __typename: 'Query' } & {
  job: { __typename: 'Job' } & Pick<
    Job,
    | 'id'
    | 'jobType'
    | 'taxType'
    | 'addressInstructions'
    | 'cancelledAt'
    | 'contactInstructions'
    | 'costRate'
    | 'firstShiftStartAt'
    | 'hiredWorkersCount'
    | 'instructions'
    | 'lastShiftEndAt'
    | 'payRate'
    | 'postedAt'
    | 'createdAt'
    | 'markupPercent'
    | 'isRateLock'
    | 'minPayRate'
    | 'published'
    | 'quantity'
    | 'isHoliday'
    | 'mandatoryBreakTime'
    | 'originalCostRate'
    | 'originalPayRate'
    | 'status'
    | 'uniformInstructions'
  > & {
      account: { __typename: 'Account' } & Pick<
        Account,
        'id' | 'name' | 'phoneNumber' | 'rateType' | 'markupPercent'
      > & {
          customer: { __typename: 'Customer' } & Pick<
            Customer,
            'id' | 'name' | 'ltaAllowed' | 'jobTaxType'
          > & {
              workerRelationships: Array<
                { __typename: 'WorkerRelationship' } & Pick<
                  WorkerRelationship,
                  'kind'
                > & { worker: { __typename: 'Worker' } & Pick<Worker, 'id'> }
              >;
              addresses: Array<
                { __typename: 'Address' } & Pick<Address, 'id' | 'addressLine1'>
              >;
            };
        };
      address: { __typename: 'Address' } & Pick<
        Address,
        'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'
      > & {
          coords: { __typename: 'Point' } & Pick<
            Point,
            'latitude' | 'longitude'
          >;
        };
      contact: { __typename: 'CustomerAdmin' } & Pick<
        CustomerAdmin,
        'id' | 'avatarUrl'
      > & {
          user: { __typename: 'User' } & Pick<
            User,
            | 'id'
            | 'email'
            | 'firstName'
            | 'middleName'
            | 'lastName'
            | 'phoneNumber'
          >;
        };
      jobOffers: Array<{ __typename: 'JobOffer' } & JobOfferItemFragment>;
      jobRequests: Array<
        { __typename: 'JobRequest' } & Pick<
          JobRequest,
          'id' | 'createdAt' | 'status'
        > & {
            worker: { __typename: 'Worker' } & Pick<
              Worker,
              'id' | 'avatarUrl'
            > & {
                user: { __typename: 'User' } & Pick<
                  User,
                  'id' | 'email' | 'firstName' | 'middleName' | 'lastName'
                >;
              };
          }
      >;
      jobWorkers: Array<{ __typename: 'JobWorker' } & JobWorkerItemFragment>;
      order: { __typename: 'Order' } & Pick<Order, 'id' | 'markupPercent'>;
      shifts: Array<
        { __typename: 'Shift' } & Pick<Shift, 'id' | 'endAt' | 'startAt'>
      >;
      skill: { __typename: 'Skill' } & Pick<
        Skill,
        'id' | 'name' | 'imageUrl' | 'descriptionEn'
      >;
      timesheets: Array<{ __typename: 'Timesheet' } & TimesheetItemFragment>;
      uniform: { __typename: 'Uniform' } & Pick<
        Uniform,
        'id' | 'name' | 'imageUrl' | 'defaultInstructions'
      >;
    };
};

export type JobRemoveWorkerMutationVariables = Exact<{
  jobWorkerId: Scalars['ID'];
  reason: Maybe<Scalars['String']>;
}>;

export type JobRemoveWorkerMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'jobDismissWorker'
>;

export type MarkShowedMutationVariables = Exact<{
  jobWorkerId: Scalars['ID'];
  showed: Scalars['Boolean'];
}>;

export type MarkShowedMutation = { __typename: 'Mutation' } & {
  adminMarkWorkerAsShowed: { __typename: 'AdminMarkWorkerAsShowedPayload' } & {
    jobWorker: { __typename: 'JobWorker' } & Pick<JobWorker, 'showed' | 'id'>;
  };
};

export type UpdatePaymentJobMutationVariables = Exact<{
  jobId: Scalars['ID'];
  costRate: Scalars['PositiveInt'];
  payRate: Scalars['PositiveInt'];
  originalCostRate: Scalars['PositiveInt'];
  originalPayRate: Scalars['PositiveInt'];
  markupPercent: Maybe<Scalars['Float']>;
  isRateLock: Maybe<Scalars['Boolean']>;
}>;

export type UpdatePaymentJobMutation = { __typename: 'Mutation' } & {
  adminEditRateOfJob: { __typename: 'AdminEditRateOfJobPayload' } & {
    job: { __typename: 'Job' } & Pick<
      Job,
      | 'id'
      | 'costRate'
      | 'payRate'
      | 'originalCostRate'
      | 'originalPayRate'
      | 'markupPercent'
      | 'isRateLock'
    >;
  };
};

export type PublishJobMutationVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type PublishJobMutation = { __typename: 'Mutation' } & {
  jobPublish: { __typename: 'JobPublishPayload' } & {
    job: { __typename: 'Job' } & Pick<Job, 'id' | 'published'>;
  };
};

export type RejectRequestMutationVariables = Exact<{
  jobRequestId: Scalars['ID'];
}>;

export type RejectRequestMutation = { __typename: 'Mutation' } & {
  jobRequestReject: { __typename: 'JobRequestRejectPayload' } & {
    job: { __typename: 'Job' } & Pick<Job, 'id'> & {
        skill: { __typename: 'Skill' } & Pick<Skill, 'name'>;
      };
  };
};

export type AddTimesheetCommentMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
  body: Scalars['String'];
}>;

export type AddTimesheetCommentMutation = { __typename: 'Mutation' } & {
  timesheetCommentsAdd: { __typename: 'TimesheetCommentsAddPayload' } & {
    timesheet: { __typename: 'Timesheet' } & Pick<
      Timesheet,
      'id' | 'status' | 'rejectedAt'
    > & {
        timesheetComments: Array<
          { __typename: 'TimesheetComment' } & Pick<
            TimesheetComment,
            'actorType' | 'body' | 'createdAt'
          > & {
              actor: Maybe<
                { __typename: 'User' } & Pick<
                  User,
                  'id' | 'firstName' | 'lastName'
                >
              >;
            }
        >;
      };
  };
};

export type WorkerUpdateRatingMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
  rating: Maybe<Scalars['Rating']>;
  body: Maybe<Scalars['String']>;
}>;

export type WorkerUpdateRatingMutation = { __typename: 'Mutation' } & {
  workerUpdateRating: { __typename: 'WorkerUpdateRatingPayload' } & {
    timesheet: { __typename: 'Timesheet' } & Pick<
      Timesheet,
      'id' | 'rating'
    > & {
        jobWorker: { __typename: 'JobWorker' } & {
          worker: { __typename: 'Worker' } & Pick<
            Worker,
            'averageRating' | 'jobsRatingCount' | 'jobsWorked'
          >;
        };
        timesheetComments: Array<
          { __typename: 'TimesheetComment' } & Pick<
            TimesheetComment,
            'actorType' | 'body' | 'createdAt'
          > & {
              actor: Maybe<
                { __typename: 'User' } & Pick<
                  User,
                  'id' | 'firstName' | 'lastName'
                >
              >;
            }
        >;
      };
  };
};

export type CreateTimesheetMutationVariables = Exact<{
  jobId: Scalars['ID'];
  workerId: Scalars['ID'];
}>;

export type CreateTimesheetMutation = { __typename: 'Mutation' } & {
  timesheetCreate: { __typename: 'TimesheetCreatePayload' } & {
    timesheet: { __typename: 'Timesheet' } & Pick<Timesheet, 'id'> & {
        job: { __typename: 'Job' } & Pick<Job, 'id'>;
      };
  };
};

export type ApproveTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
  approvedBreakMinutes: Scalars['NonNegativeInt'];
  approvedCheckinAt: Scalars['ISO8601DateTime'];
  approvedCheckoutAt: Scalars['ISO8601DateTime'];
  ratingComment: Maybe<Scalars['String']>;
  tipAmount: Maybe<Scalars['NonNegativeInt']>;
}>;

export type ApproveTimesheetMutation = { __typename: 'Mutation' } & {
  timesheetApprove: { __typename: 'TimesheetApprovePayload' } & {
    timesheet: { __typename: 'Timesheet' } & Pick<Timesheet, 'id'>;
  };
};

export type RejectTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
  ratingComment: Maybe<Scalars['String']>;
}>;

export type RejectTimesheetMutation = { __typename: 'Mutation' } & {
  timesheetReject: { __typename: 'TimesheetRejectPayload' } & {
    timesheet: { __typename: 'Timesheet' } & Pick<
      Timesheet,
      'id' | 'status' | 'rejectedAt'
    >;
  };
};

export type SaveTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
  reportedBreakMinutes: Scalars['NonNegativeInt'];
  reportedCheckinAt: Scalars['ISO8601DateTime'];
  reportedCheckoutAt: Scalars['ISO8601DateTime'];
  ratingComment: Maybe<Scalars['String']>;
  tipAmount: Maybe<Scalars['NonNegativeInt']>;
}>;

export type SaveTimesheetMutation = { __typename: 'Mutation' } & {
  timesheetUpdate: { __typename: 'TimesheetUpdatePayload' } & {
    timesheet: { __typename: 'Timesheet' } & Pick<Timesheet, 'id'>;
  };
};

export type SaveTimesheetTipMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
  tipAmount: Scalars['NonNegativeInt'];
}>;

export type SaveTimesheetTipMutation = { __typename: 'Mutation' } & {
  timesheetTipUpdate: { __typename: 'TimesheetTipUpdatePayload' } & {
    timesheet: { __typename: 'Timesheet' } & Pick<Timesheet, 'id'>;
  };
};

export type UnrejectTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
}>;

export type UnrejectTimesheetMutation = { __typename: 'Mutation' } & {
  timesheetUnreject: { __typename: 'TimesheetUnrejectPayload' } & {
    timesheet: { __typename: 'Timesheet' } & Pick<
      Timesheet,
      'id' | 'status' | 'rejectedAt'
    >;
  };
};

export type UnapproveTimesheetMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
}>;

export type UnapproveTimesheetMutation = { __typename: 'Mutation' } & {
  timesheetUnapprove: { __typename: 'TimesheetUnapprovePayload' } & {
    timesheet: { __typename: 'Timesheet' } & Pick<
      Timesheet,
      'id' | 'approvedAt' | 'status' | 'updatedAt'
    >;
  };
};

export type UnPublishJobMutationVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type UnPublishJobMutation = { __typename: 'Mutation' } & {
  jobUnpublish: { __typename: 'JobUnpublishPayload' } & {
    job: { __typename: 'Job' } & Pick<Job, 'id' | 'published' | 'postedAt'>;
  };
};

export type UpdateGravyWorkersMutationVariables = Exact<{
  jobId: Scalars['ID'];
  numberWorkers: Scalars['Int'];
}>;

export type UpdateGravyWorkersMutation = { __typename: 'Mutation' } & {
  adminEditNumberWorkersJob: {
    __typename: 'AdminEditNumberWorkersJobPayload';
  } & {
    job: { __typename: 'Job' } & Pick<Job, 'quantity'> & {
        jobWorkers: Array<{ __typename: 'JobWorker' } & JobWorkerItemFragment>;
      };
  };
};

export type UpdateJobMutationVariables = Exact<{
  jobId: Scalars['ID'];
  addressId: Scalars['ID'];
  addressInstructions: Scalars['String'];
  contactId: Scalars['ID'];
  contactInstructions: Scalars['String'];
  instructions: Scalars['String'];
  uniformId: Scalars['ID'];
  uniformInstructions: Scalars['String'];
}>;

export type UpdateJobMutation = { __typename: 'Mutation' } & {
  jobUpdate: { __typename: 'JobUpdatePayload' } & {
    job: { __typename: 'Job' } & Pick<
      Job,
      | 'id'
      | 'addressInstructions'
      | 'contactInstructions'
      | 'instructions'
      | 'uniformInstructions'
    > & {
        contact: { __typename: 'CustomerAdmin' } & Pick<
          CustomerAdmin,
          'id' | 'avatarUrl'
        > & {
            user: { __typename: 'User' } & Pick<
              User,
              'id' | 'email' | 'firstName' | 'lastName'
            >;
          };
        uniform: { __typename: 'Uniform' } & Pick<
          Uniform,
          'id' | 'imageUrl' | 'name'
        >;
      };
  };
};

export type UpdateJobScheduleMutationVariables = Exact<{
  jobId: Scalars['ID'];
  firstShiftStartAt: Scalars['ISO8601DateTime'];
  lastShiftEndAt: Scalars['ISO8601DateTime'];
  timeZoneOffset: Scalars['Float'];
  mandatoryBreakTime: Maybe<Scalars['Minutes']>;
}>;

export type UpdateJobScheduleMutation = { __typename: 'Mutation' } & {
  adminEditTimeOfJob: { __typename: 'AdminEditTimeOfJobPayload' } & {
    job: { __typename: 'Job' } & Pick<
      Job,
      'id' | 'firstShiftStartAt' | 'lastShiftEndAt'
    > & {
        shifts: Array<
          { __typename: 'Shift' } & Pick<Shift, 'id' | 'startAt' | 'endAt'>
        >;
      };
  };
};

export type ListWorkersForJobQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<WorkerFilterSetInput>;
}>;

export type ListWorkersForJobQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      workers: { __typename: 'WorkerPage' } & {
        items: Array<
          { __typename: 'Worker' } & Pick<
            Worker,
            'id' | 'onboardingStatus' | 'readyToWork'
          > & {
              workerJurisdictions: Maybe<
                Array<
                  { __typename: 'Jurisdiction' } & Pick<Jurisdiction, 'state'>
                >
              >;
              user: { __typename: 'User' } & Pick<
                User,
                'id' | 'email' | 'firstName' | 'lastName'
              >;
            }
        >;
      };
    };
};

export type JobOfferCreateMutationVariables = Exact<{
  jobId: Scalars['ID'];
  workerId: Scalars['ID'];
  forceInvite: Maybe<Scalars['Boolean']>;
}>;

export type JobOfferCreateMutation = { __typename: 'Mutation' } & {
  jobOfferCreate: { __typename: 'JobOfferCreatePayload' } & {
    jobOffer: { __typename: 'JobOffer' } & JobOfferItemFragment;
  };
};

export type JobHireWorkerMutationVariables = Exact<{
  jobId: Scalars['ID'];
  payRate: Maybe<Scalars['PositiveInt']>;
  workerId: Scalars['ID'];
  forceHire: Maybe<Scalars['Boolean']>;
}>;

export type JobHireWorkerMutation = { __typename: 'Mutation' } & {
  jobHireWorker: { __typename: 'JobHireWorkerPayload' } & {
    job: { __typename: 'Job' } & Pick<Job, 'id' | 'hiredWorkersCount'> & {
        jobWorkers: Array<{ __typename: 'JobWorker' } & JobWorkerItemFragment>;
      };
  };
  workerAssignJobWorkplace: {
    __typename: 'WorkerAssignJobWorkplacePayload';
  } & {
    worker: { __typename: 'Worker' } & Pick<
      Worker,
      'onboardingStatus' | 'id' | 'active' | 'gender' | 'heardFrom' | 'ownsCar'
    > & {
        user: { __typename: 'User' } & Pick<
          User,
          'firstName' | 'lastName' | 'language' | 'secondaryLanguage'
        >;
      };
  };
};

export type BulkEditTimesheetMutationVariables = Exact<{
  jobId: Scalars['ID'];
  jobWorkerIds: Array<Scalars['ID']> | Scalars['ID'];
  action: TimesheetsBulkEditActionEnum;
  editInfo: Maybe<BulkEditTimesheetsInput>;
}>;

export type BulkEditTimesheetMutation = { __typename: 'Mutation' } & {
  timesheetsBulkEdit: { __typename: 'TimesheetsBulkEditPayload' } & Pick<
    TimesheetsBulkEditPayload,
    'message'
  >;
};

export type GetWorkerProfileQueryVariables = Exact<{
  workerId: Scalars['ID'];
}>;

export type GetWorkerProfileQuery = { __typename: 'Query' } & {
  worker: { __typename: 'Worker' } & Pick<
    Worker,
    | 'id'
    | 'avatarUrl'
    | 'city'
    | 'state'
    | 'averageRating'
    | 'jobsRatingCount'
    | 'jobsWorked'
    | 'shiftsWorked'
    | 'jobsDropped'
    | 'noShow'
    | 'taxType'
  > & {
      activeCertificates: Array<
        { __typename: 'Certificate' } & Pick<
          Certificate,
          'id' | 'name' | 'imageUrl'
        >
      >;
      workerSkills: Array<
        { __typename: 'WorkerSkill' } & Pick<WorkerSkill, 'jobsWorked'> & {
            skill: { __typename: 'Skill' } & Pick<
              Skill,
              'id' | 'imageUrl' | 'name'
            >;
          }
      >;
      user: { __typename: 'User' } & Pick<
        User,
        'id' | 'firstName' | 'middleName' | 'lastName' | 'email' | 'phoneNumber'
      >;
    };
};

export type GetJobWorkersQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type GetJobWorkersQuery = { __typename: 'Query' } & {
  job: { __typename: 'Job' } & Pick<Job, 'id'> & {
      jobWorkers: Array<
        { __typename: 'JobWorker' } & Pick<
          JobWorker,
          'status' | 'checkInStatus'
        > & {
            timesheets: Array<
              { __typename: 'Timesheet' } & Pick<
                Timesheet,
                | 'checkinAt'
                | 'checkoutAt'
                | 'reportedCheckinAt'
                | 'reportedCheckoutAt'
                | 'approvedCheckinAt'
                | 'approvedCheckoutAt'
              >
            >;
            worker: { __typename: 'Worker' } & Pick<
              Worker,
              'avatarUrl' | 'id'
            > & {
                user: { __typename: 'User' } & Pick<
                  User,
                  'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
                >;
              };
          }
      >;
    };
};

export type ListJobsForMonitorQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
  filters: Maybe<JobFilterSetInput>;
  timeZone: Maybe<Scalars['String']>;
}>;

export type ListJobsForMonitorQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      shiftsByDay: Array<
        { __typename: 'Shift' } & Pick<Shift, 'id' | 'endAt' | 'startAt'> & {
            job: { __typename: 'Job' } & Pick<
              Job,
              | 'id'
              | 'hiredWorkersCount'
              | 'quantity'
              | 'published'
              | 'lastShiftEndAt'
              | 'firstShiftStartAt'
              | 'status'
              | 'checkinWorkerCount'
              | 'hasNoShow'
            > & {
                account: { __typename: 'Account' } & Pick<
                  Account,
                  'id' | 'name'
                > & {
                    customer: { __typename: 'Customer' } & Pick<
                      Customer,
                      'id' | 'name'
                    >;
                  };
                address: { __typename: 'Address' } & Pick<
                  Address,
                  'id' | 'addressLine1' | 'city' | 'state' | 'zip'
                >;
                order: { __typename: 'Order' } & Pick<Order, 'id'>;
                skill: { __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>;
              };
          }
      >;
    };
};

export type GetJobSubscribersQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;

export type GetJobSubscribersQuery = { __typename: 'Query' } & {
  jobSubscriber: Array<
    { __typename: 'JobSubscriber' } & Pick<
      JobSubscriber,
      'id' | 'jobId' | 'subscriberEmail'
    >
  >;
};

export type JobAddSubscriberMutationVariables = Exact<{
  agencyId: Scalars['ID'];
  jobId: Scalars['ID'];
  orderId: Scalars['ID'];
  shiftId: Scalars['ID'];
  subscriberEmail: Scalars['String'];
}>;

export type JobAddSubscriberMutation = { __typename: 'Mutation' } & {
  jobAddSubscriber: { __typename: 'JobAddSubscriberPayload' } & {
    job: { __typename: 'Job' } & Pick<Job, 'id'>;
  };
};

export type JobRemoveSubscriberMutationVariables = Exact<{
  agencyId: Scalars['ID'];
  jobSubscriberId: Scalars['Int'];
  tenantId: Scalars['ID'];
}>;

export type JobRemoveSubscriberMutation = { __typename: 'Mutation' } & {
  jobRemoveSubscriber: { __typename: 'JobRemoveSubscriberPayload' } & {
    agency: { __typename: 'Agency' } & Pick<Agency, 'id'>;
  };
};

export type ListJobsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;

export type ListJobsQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      jobs: { __typename: 'JobPage' } & {
        items: Array<
          { __typename: 'Job' } & Pick<
            Job,
            | 'id'
            | 'quantity'
            | 'hiredWorkersCount'
            | 'firstShiftStartAt'
            | 'lastShiftEndAt'
          > & { skill: { __typename: 'Skill' } & Pick<Skill, 'id' | 'name'> }
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type GetMyTeamQueryVariables = Exact<{
  customerId: Scalars['ID'];
  filters: Maybe<CustomerAdminFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;

export type GetMyTeamQuery = { __typename: 'Query' } & {
  customer: { __typename: 'Customer' } & Pick<Customer, 'id'> & {
      admins: { __typename: 'CustomerAdminPage' } & {
        items: Array<
          { __typename: 'CustomerAdmin' } & Pick<
            CustomerAdmin,
            'id' | 'acceptedAt' | 'avatarUrl'
          > & {
              user: { __typename: 'User' } & Pick<
                User,
                | 'id'
                | 'active'
                | 'firstName'
                | 'lastName'
                | 'email'
                | 'phoneNumber'
              >;
            }
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type GetOrderQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type GetOrderQuery = { __typename: 'Query' } & {
  order: { __typename: 'Order' } & Pick<
    Order,
    'id' | 'createdAt' | 'cancelledAt' | 'startAt' | 'endAt' | 'jobsCount'
  > & {
      account: { __typename: 'Account' } & Pick<Account, 'id' | 'name'> & {
          customer: { __typename: 'Customer' } & Pick<
            Customer,
            'id' | 'logoUrl' | 'name' | 'jobTaxType' | 'ltaAllowed'
          >;
        };
      jobs: Array<
        { __typename: 'Job' } & Pick<
          Job,
          | 'id'
          | 'approvalStatus'
          | 'costRate'
          | 'mandatoryBreakTime'
          | 'payRate'
          | 'quantity'
          | 'taxType'
          | 'hiredWorkersCount'
          | 'firstShiftStartAt'
          | 'lastShiftEndAt'
          | 'cancelledAt'
          | 'published'
          | 'reason'
          | 'jobType'
        > & {
            jobWorkers: Array<
              { __typename: 'JobWorker' } & Pick<
                JobWorker,
                'showed' | 'status'
              > & { worker: { __typename: 'Worker' } & OrderWorkerItemFragment }
            >;
            address: { __typename: 'Address' } & Pick<Address, 'id' | 'state'>;
            shifts: Array<
              { __typename: 'Shift' } & Pick<Shift, 'id' | 'startAt' | 'endAt'>
            >;
            jobOffers: Array<
              { __typename: 'JobOffer' } & Pick<
                JobOffer,
                'id' | 'acceptedAt'
              > & { worker: { __typename: 'Worker' } & OrderWorkerItemFragment }
            >;
            timesheets: Array<
              { __typename: 'Timesheet' } & Pick<Timesheet, 'id' | 'status'>
            >;
            skill: { __typename: 'Skill' } & Pick<
              Skill,
              'id' | 'name' | 'imageUrl' | 'gravyTrained'
            >;
            cancelUser: Maybe<
              | ({ __typename: 'CustomerAdmin' } & Pick<
                  CustomerAdmin,
                  'id' | 'avatarUrl' | 'role'
                > & {
                    customer: { __typename: 'Customer' } & Pick<Customer, 'id'>;
                    user: { __typename: 'User' } & Pick<
                      User,
                      'id' | 'email' | 'firstName' | 'lastName'
                    >;
                  })
              | ({ __typename: 'TenantAdmin' } & Pick<
                  TenantAdmin,
                  'id' | 'avatarUrl' | 'role'
                > & {
                    user: { __typename: 'User' } & Pick<
                      User,
                      'id' | 'email' | 'firstName' | 'lastName'
                    >;
                  })
            >;
          }
      >;
    };
};

export type OrderWorkerItemFragment = { __typename: 'Worker' } & Pick<
  Worker,
  | 'id'
  | 'avatarUrl'
  | 'city'
  | 'state'
  | 'averageRating'
  | 'jobsRatingCount'
  | 'jobsWorked'
  | 'shiftsWorked'
  | 'jobsDropped'
  | 'noShow'
  | 'taxType'
> & {
    user: { __typename: 'User' } & Pick<
      User,
      'id' | 'firstName' | 'middleName' | 'lastName' | 'email' | 'phoneNumber'
    >;
  };

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
  reason: Scalars['String'];
}>;

export type CancelOrderMutation = { __typename: 'Mutation' } & {
  jobCancelBatch: { __typename: 'JobCancelBatchPayload' } & {
    order: { __typename: 'Order' } & Pick<Order, 'id'> & {
        jobs: Array<
          { __typename: 'Job' } & Pick<
            Job,
            'id' | 'status' | 'reason' | 'cancelledAt' | 'cancelled'
          >
        >;
      };
  };
};

export type ListOrdersQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<OrderFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
  sortKey: Maybe<OrderSortKeyEnum>;
  reverse: Maybe<Scalars['Boolean']>;
}>;

export type ListOrdersQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      orders: { __typename: 'OrderPage' } & {
        items: Array<
          { __typename: 'Order' } & Pick<
            Order,
            'id' | 'uuid' | 'startAt' | 'endAt' | 'jobsCount' | 'status'
          > & {
              jobs: Array<
                { __typename: 'Job' } & Pick<
                  Job,
                  'id' | 'filled' | 'cancelled' | 'taxType' | 'approvalStatus'
                > & {
                    timesheets: Array<
                      { __typename: 'Timesheet' } & Pick<
                        Timesheet,
                        'id' | 'status'
                      >
                    >;
                    jobWorkers: Array<
                      { __typename: 'JobWorker' } & Pick<
                        JobWorker,
                        'showed' | 'status'
                      >
                    >;
                    skill: { __typename: 'Skill' } & Pick<
                      Skill,
                      'id' | 'imageUrl'
                    >;
                  }
              >;
              account: { __typename: 'Account' } & Pick<
                Account,
                'id' | 'name'
              > & {
                  customer: { __typename: 'Customer' } & Pick<
                    Customer,
                    'id' | 'logoUrl' | 'name'
                  >;
                };
            }
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type ListPayrollsQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  page: Maybe<Scalars['PositiveInt']>;
  perPage: Maybe<Scalars['PositiveInt']>;
}>;

export type ListPayrollsQuery = { __typename: 'Query' } & {
  allPayrolls: { __typename: 'PayrollPage' } & {
    items: Array<
      { __typename: 'Payroll' } & Pick<
        Payroll,
        | 'id'
        | 'status'
        | 'approvedAt'
        | 'approvalDeadline'
        | 'payday'
        | 'periodStart'
        | 'periodEnd'
      >
    >;
    pageInfo: { __typename: 'PageInfo' } & Pick<
      PageInfo,
      'totalItems' | 'totalPages'
    >;
  };
};

export type CreateNewPayrollMutationVariables = Exact<{
  weekDay: Scalars['ISO8601Date'];
  payday: Scalars['ISO8601Date'];
}>;

export type CreateNewPayrollMutation = { __typename: 'Mutation' } & {
  payrollCreate: { __typename: 'PayrollCreatePayload' } & {
    payroll: { __typename: 'Payroll' } & Pick<
      Payroll,
      | 'id'
      | 'payday'
      | 'periodEnd'
      | 'periodStart'
      | 'approvalDeadline'
      | 'approvedAt'
    >;
  };
};

export type ApprovePreviewPayrollMutationVariables = Exact<{
  payrollId: Scalars['ID'];
}>;

export type ApprovePreviewPayrollMutation = { __typename: 'Mutation' } & {
  payrollApprove: { __typename: 'PayrollApprovePayload' } & {
    payroll: { __typename: 'Payroll' } & Pick<
      Payroll,
      | 'id'
      | 'approvalDeadline'
      | 'approvedAt'
      | 'payday'
      | 'periodEnd'
      | 'periodStart'
      | 'status'
    > & {
        payrollItems: Maybe<
          { __typename: 'PayrollItemPage' } & {
            items: Array<
              { __typename: 'PayrollItem' } & Pick<
                PayrollItem,
                'id' | 'netPay' | 'paymentMethod' | 'status'
              > & {
                  earnings: Array<
                    {
                      __typename: 'PayrollItemEarning';
                    } & SimpleEarningsFragment
                  >;
                  worker: Maybe<
                    { __typename: 'Worker' } & {
                      user: { __typename: 'User' } & Pick<
                        User,
                        'id' | 'firstName' | 'lastName'
                      >;
                    }
                  >;
                  taxes: Array<
                    { __typename: 'PayrollItemTax' } & SimpleTaxesFragment
                  >;
                }
            >;
          }
        >;
        totals: { __typename: 'PayrollTotals' } & SimpleTotalsFragment;
      };
  };
};

export type DeletePayrollMutationVariables = Exact<{
  payrollId: Scalars['ID'];
}>;

export type DeletePayrollMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'payrollDelete'
>;

export type GetPayrollDetailQueryVariables = Exact<{
  payrollId: Scalars['ID'];
}>;

export type GetPayrollDetailQuery = { __typename: 'Query' } & {
  payroll: { __typename: 'Payroll' } & Pick<
    Payroll,
    | 'id'
    | 'approvalDeadline'
    | 'approvedAt'
    | 'payday'
    | 'periodEnd'
    | 'periodStart'
    | 'status'
  > & {
      payrollItems: Maybe<
        { __typename: 'PayrollItemPage' } & {
          items: Array<
            { __typename: 'PayrollItem' } & SimplePayrollItemFragment
          >;
        }
      >;
      contractorPayments: Maybe<
        { __typename: 'ContractorPaymentPage' } & {
          items: Array<
            {
              __typename: 'ContractorPayment';
            } & SimpleContractorPaymentsFragment
          >;
        }
      >;
      totals: { __typename: 'PayrollTotals' } & SimpleTotalsFragment;
    };
};

export type GetPreviewPayrollQueryVariables = Exact<{
  payrollId: Scalars['ID'];
}>;

export type GetPreviewPayrollQuery = { __typename: 'Query' } & {
  payrollPreview: { __typename: 'Payroll' } & Pick<
    Payroll,
    | 'id'
    | 'approvalDeadline'
    | 'approvedAt'
    | 'payday'
    | 'periodEnd'
    | 'periodStart'
    | 'status'
  > & {
      payrollItems: Maybe<
        { __typename: 'PayrollItemPage' } & {
          items: Array<
            { __typename: 'PayrollItem' } & SimplePayrollItemFragment
          >;
        }
      >;
      contractorPayments: Maybe<
        { __typename: 'ContractorPaymentPage' } & {
          items: Array<
            {
              __typename: 'ContractorPayment';
            } & SimpleContractorPaymentsFragment
          >;
        }
      >;
      totals: { __typename: 'PayrollTotals' } & SimpleTotalsFragment;
    };
};

export type ListPayrollJournalReportQueryVariables = Exact<{
  payrollId: Scalars['ID'];
}>;

export type ListPayrollJournalReportQuery = { __typename: 'Query' } & Pick<
  Query,
  'payrollJournalReport'
>;

export type ListActiveUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListActiveUsersQuery = { __typename: 'Query' } & Pick<
  Query,
  'activeUserListReport'
>;

export type ListActiveUsersBySkilQueryVariables = Exact<{
  skills: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type ListActiveUsersBySkilQuery = { __typename: 'Query' } & {
  activeUsersBySkillReport: Array<
    { __typename: 'ActiveUsersBySkillReport' } & Pick<
      ActiveUsersBySkillReport,
      | 'email'
      | 'firstName'
      | 'middleName'
      | 'lastName'
      | 'jobsWorked'
      | 'phone'
      | 'skill'
      | 'city'
      | 'state'
    >
  >;
};

export type ListCustomersReportQueryVariables = Exact<{ [key: string]: never }>;

export type ListCustomersReportQuery = { __typename: 'Query' } & {
  customerListReport: Maybe<
    Array<
      { __typename: 'CustomerListReport' } & Pick<
        CustomerListReport,
        | 'accountAgeDays'
        | 'accountId'
        | 'accountName'
        | 'accountRepEmail'
        | 'accountRepName'
        | 'accountRepPhone'
        | 'city'
        | 'clientUrl'
        | 'customerId'
        | 'customerName'
        | 'latestOrder'
        | 'ordersFiveMonthsAgo'
        | 'ordersFourMonthsAgo'
        | 'ordersLastMonth'
        | 'ordersSixMonthsAgo'
        | 'ordersThisMonth'
        | 'ordersThreeMonthsAgo'
        | 'ordersTwoMonthsAgo'
        | 'state'
        | 'totalJobs'
        | 'zip'
      >
    >
  >;
};

export type ListInvoicesReportQueryVariables = Exact<{
  from: Maybe<Scalars['ISO8601DateTime']>;
  to: Maybe<Scalars['ISO8601DateTime']>;
}>;

export type ListInvoicesReportQuery = { __typename: 'Query' } & {
  invoicingReport: Maybe<
    Array<
      { __typename: 'InvoicingReport' } & Pick<
        InvoicingReport,
        | 'companyNumber'
        | 'companyName'
        | 'orderNumber'
        | 'invoiceNumber'
        | 'invoiceDate'
        | 'invoiceStatus'
        | 'jobDate'
        | 'unapprovedHours'
        | 'invoiced'
        | 'invoicePaid'
        | 'openInvoice'
      >
    >
  >;
};

export type RevenueByRangeQueryVariables = Exact<{
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
}>;

export type RevenueByRangeQuery = { __typename: 'Query' } & {
  revenueReport: Maybe<
    Array<
      { __typename: 'RevenueReport' } & Pick<
        RevenueReport,
        | 'company'
        | 'region'
        | 'totalHours'
        | 'totalRevenue'
        | 'totalPayrollExpense'
        | 'netProfit'
      >
    >
  >;
};

export type TimesheetsByPayPeriodQueryVariables = Exact<{
  payPeriod: Maybe<Scalars['ISO8601Date']>;
}>;

export type TimesheetsByPayPeriodQuery = { __typename: 'Query' } & Pick<
  Query,
  'timesheetReport'
>;

export type WorkerJobWorkersFragment = { __typename: 'JobWorkerPage' } & {
  items: Array<
    { __typename: 'JobWorker' } & Pick<
      JobWorker,
      'id' | 'costRate' | 'payRate'
    > & {
        order: { __typename: 'Order' } & Pick<Order, 'id'>;
        job: { __typename: 'Job' } & Pick<
          Job,
          'id' | 'cancelled' | 'firstShiftStartAt' | 'lastShiftEndAt'
        > & {
            address: { __typename: 'Address' } & Pick<
              Address,
              'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'
            >;
            customer: { __typename: 'Customer' } & Pick<
              Customer,
              'id' | 'name' | 'logoUrl'
            >;
            skill: { __typename: 'Skill' } & Pick<
              Skill,
              'id' | 'name' | 'imageUrl'
            >;
          };
      }
  >;
  pageInfo: { __typename: 'PageInfo' } & Pick<
    PageInfo,
    'totalItems' | 'totalPages'
  >;
};

export type WorkerSkillFragment = { __typename: 'Skill' } & Pick<
  Skill,
  'id' | 'imageUrl' | 'name'
>;

export type WorkerCoordsFragment = { __typename: 'Point' } & Pick<
  Point,
  'latitude' | 'longitude'
>;

export type GetWorkerQueryVariables = Exact<{
  filterDate: Scalars['ISO8601DateTime'];
  workerId: Scalars['ID'];
}>;

export type GetWorkerQuery = { __typename: 'Query' } & {
  worker: { __typename: 'Worker' } & Pick<
    Worker,
    | 'id'
    | 'active'
    | 'hasSsn'
    | 'reenableDate'
    | 'disableReason'
    | 'addressLine1'
    | 'addressLine2'
    | 'avatarUrl'
    | 'city'
    | 'interviewStatus'
    | 'checkEmployeeId'
    | 'candidateStatus'
    | 'onboardingStatus'
    | 'backgroundStatus'
    | 'i9Status'
    | 'onboardingDocsStatus'
    | 'gender'
    | 'heardFrom'
    | 'ownsCar'
    | 'state'
    | 'status'
    | 'zip'
    | 'taxType'
    | 'averageRating'
    | 'jobsDropped'
    | 'jobsWorked'
    | 'shiftsWorked'
    | 'jobsRatingCount'
  > & {
      deactivatedByUser: Maybe<
        { __typename: 'User' } & Pick<
          User,
          'id' | 'firstName' | 'lastName' | 'middleName'
        >
      >;
      comments: Array<{ __typename: 'Comment' } & SimpleCommentFragment>;
      coords: Maybe<{ __typename: 'Point' } & WorkerCoordsFragment>;
      skillRequests: Array<
        { __typename: 'SkillRequest' } & Pick<SkillRequest, 'id' | 'status'> & {
            skill: { __typename: 'Skill' } & Pick<
              Skill,
              'id' | 'imageUrl' | 'displayNameEn' | 'name'
            >;
          }
      >;
      jobWorkers: { __typename: 'JobWorkerPage' } & WorkerJobWorkersFragment;
      pendingJobOffers: Array<
        { __typename: 'JobOffer' } & Pick<
          JobOffer,
          'id' | 'costRate' | 'createdAt' | 'payRate'
        > & {
            job: { __typename: 'Job' } & Pick<
              Job,
              'id' | 'lastShiftEndAt' | 'firstShiftStartAt' | 'cancelled'
            > & {
                order: { __typename: 'Order' } & Pick<Order, 'id'>;
                address: { __typename: 'Address' } & Pick<
                  Address,
                  | 'id'
                  | 'addressLine1'
                  | 'addressLine2'
                  | 'city'
                  | 'state'
                  | 'zip'
                >;
                customer: { __typename: 'Customer' } & Pick<
                  Customer,
                  'id' | 'name' | 'logoUrl'
                >;
                skill: { __typename: 'Skill' } & Pick<
                  Skill,
                  'id' | 'name' | 'imageUrl'
                >;
              };
          }
      >;
      pendingJobRequests: Array<
        { __typename: 'JobRequest' } & Pick<JobRequest, 'id' | 'createdAt'> & {
            job: { __typename: 'Job' } & Pick<
              Job,
              'id' | 'lastShiftEndAt' | 'firstShiftStartAt' | 'cancelled'
            > & {
                order: { __typename: 'Order' } & Pick<Order, 'id'>;
                address: { __typename: 'Address' } & Pick<
                  Address,
                  | 'id'
                  | 'addressLine1'
                  | 'addressLine2'
                  | 'city'
                  | 'state'
                  | 'zip'
                >;
                customer: { __typename: 'Customer' } & Pick<
                  Customer,
                  'id' | 'name' | 'logoUrl'
                >;
                skill: { __typename: 'Skill' } & Pick<
                  Skill,
                  'id' | 'name' | 'imageUrl'
                >;
              };
          }
      >;
      references: Array<
        { __typename: 'WorkerReference' } & Pick<
          WorkerReference,
          | 'id'
          | 'city'
          | 'duration'
          | 'employerName'
          | 'name'
          | 'phoneNumber'
          | 'summary'
          | 'title'
        >
      >;
      skills: Array<{ __typename: 'Skill' } & WorkerSkillFragment>;
      activeCertificates: Array<
        { __typename: 'Certificate' } & Pick<Certificate, 'id' | 'name'>
      >;
      user: { __typename: 'User' } & Pick<
        User,
        | 'id'
        | 'email'
        | 'firstName'
        | 'middleName'
        | 'language'
        | 'lastName'
        | 'phoneNumber'
        | 'secondaryLanguage'
        | 'smartphoneType'
        | 'dateOfBirth'
      >;
    };
};

export type GetWorkerJobsQueryVariables = Exact<{
  filters: Maybe<JobWorkerFilterSetInput>;
  workerId: Scalars['ID'];
  page: Maybe<Scalars['PositiveInt']>;
  perPage: Maybe<Scalars['PositiveInt']>;
}>;

export type GetWorkerJobsQuery = { __typename: 'Query' } & {
  worker: { __typename: 'Worker' } & Pick<Worker, 'id'> & {
      jobWorkers: { __typename: 'JobWorkerPage' } & {
        items: Array<
          { __typename: 'JobWorker' } & Pick<
            JobWorker,
            'showed' | 'id' | 'status'
          > & {
              job: { __typename: 'Job' } & Pick<
                Job,
                | 'status'
                | 'id'
                | 'cancelled'
                | 'firstShiftStartAt'
                | 'lastShiftEndAt'
              > & {
                  order: { __typename: 'Order' } & Pick<Order, 'id'>;
                  address: { __typename: 'Address' } & Pick<
                    Address,
                    | 'id'
                    | 'addressLine1'
                    | 'addressLine2'
                    | 'city'
                    | 'state'
                    | 'zip'
                  >;
                  customer: { __typename: 'Customer' } & Pick<
                    Customer,
                    'id' | 'name' | 'logoUrl'
                  >;
                  skill: { __typename: 'Skill' } & Pick<
                    Skill,
                    'id' | 'name' | 'imageUrl'
                  >;
                };
            }
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type ResetWorkerPasswordMutationVariables = Exact<{
  workerId: Scalars['ID'];
}>;

export type ResetWorkerPasswordMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'adminResetWorkerPassword'
>;

export type GetShowcaseLinkQueryVariables = Exact<{
  workerId: Scalars['ID'];
  typeInterview: WilloWorkerInterviewStatusEnum;
}>;

export type GetShowcaseLinkQuery = { __typename: 'Query' } & {
  willoWorkerInterview: Maybe<
    { __typename: 'WilloWorkerInterview' } & Pick<
      WilloWorkerInterview,
      'id' | 'showcaseLink'
    >
  >;
};

export type ChangeWorkerAddressMutationVariables = Exact<{
  workerId: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  coords: PointInput;
}>;

export type ChangeWorkerAddressMutation = { __typename: 'Mutation' } & {
  workerChangeAddress: { __typename: 'WorkerChangeAddressPayload' } & {
    worker: { __typename: 'Worker' } & Pick<
      Worker,
      'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'
    > & {
        coords: Maybe<
          { __typename: 'Point' } & Pick<Point, 'latitude' | 'longitude'>
        >;
      };
  };
};

export type UpdateBackgroundStatusMutationVariables = Exact<{
  backgroundStatus: BackgroundStatusEnum;
  workerId: Scalars['ID'];
}>;

export type UpdateBackgroundStatusMutation = { __typename: 'Mutation' } & {
  workerUpdateBackgroundCheck: {
    __typename: 'WorkerUpdateBackgroundCheckPayload';
  } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id' | 'backgroundStatus'>;
  };
};

export type AddWorkerCertificationMutationVariables = Exact<{
  certificateId: Scalars['ID'];
  workerId: Scalars['ID'];
}>;

export type AddWorkerCertificationMutation = { __typename: 'Mutation' } & {
  workerAddCertificate: { __typename: 'WorkerAddCertificatePayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'> & {
        activeCertificates: Array<
          { __typename: 'Certificate' } & Pick<Certificate, 'id' | 'name'>
        >;
      };
  };
};

export type ListAgencyCertificateQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;

export type ListAgencyCertificateQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      certificates: Array<
        { __typename: 'Certificate' } & Pick<Certificate, 'id' | 'name'>
      >;
    };
};

export type RemoveWorkerCertificateMutationVariables = Exact<{
  certificateId: Scalars['ID'];
  workerId: Scalars['ID'];
}>;

export type RemoveWorkerCertificateMutation = { __typename: 'Mutation' } & {
  workerRemoveCertificate: { __typename: 'WorkerRemoveCertificatePayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'> & {
        activeCertificates: Array<
          { __typename: 'Certificate' } & Pick<Certificate, 'id' | 'name'>
        >;
      };
  };
};

export type AddWorkerSkillMutationVariables = Exact<{
  skillId: Scalars['ID'];
  workerId: Scalars['ID'];
}>;

export type AddWorkerSkillMutation = { __typename: 'Mutation' } & {
  workerAddSkill: { __typename: 'WorkerAddSkillPayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'> & {
        skills: Array<{ __typename: 'Skill' } & WorkerSkillFragment>;
      };
  };
};

export type ListAgencySkillsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;

export type ListAgencySkillsQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      skillCategories: Array<
        { __typename: 'SkillCategory' } & Pick<SkillCategory, 'id' | 'name'> & {
            skills: Array<{ __typename: 'Skill' } & WorkerSkillFragment>;
          }
      >;
    };
};

export type RemoveWorkerSkillMutationVariables = Exact<{
  skillId: Scalars['ID'];
  workerId: Scalars['ID'];
}>;

export type RemoveWorkerSkillMutation = { __typename: 'Mutation' } & {
  workerRemoveSkill: { __typename: 'WorkerRemoveSkillPayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'> & {
        skills: Array<{ __typename: 'Skill' } & WorkerSkillFragment>;
      };
  };
};

export type WorkerSignatureRequestsQueryVariables = Exact<{
  workerId: Scalars['ID'];
  type?: Maybe<SignatureRequestTypeEnum>;
}>;

export type WorkerSignatureRequestsQuery = { __typename: 'Query' } & {
  workerSignatureRequestsTenant: Array<
    { __typename: 'WorkerSignatureRequest' } & Pick<
      WorkerSignatureRequest,
      'id' | 'documentUrl'
    >
  >;
};

export type UpdateTaxTypeMutationVariables = Exact<{
  taxType: TaxTypeEnum;
  workerId: Scalars['ID'];
  hasSsn: Maybe<Scalars['Boolean']>;
}>;

export type UpdateTaxTypeMutation = { __typename: 'Mutation' } & {
  workerUpdateTaxType: { __typename: 'WorkerUpdateTaxTypePayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'>;
  };
};

export type WorkerDisableMutationVariables = Exact<{
  workerId: Scalars['ID'];
  reason: Maybe<Scalars['String']>;
  reenableDate: Maybe<Scalars['ISO8601Date']>;
}>;

export type WorkerDisableMutation = { __typename: 'Mutation' } & {
  workerDisable: { __typename: 'WorkerDisablePayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'>;
  };
};

export type WorkerEnableMutationVariables = Exact<{
  workerId: Scalars['ID'];
}>;

export type WorkerEnableMutation = { __typename: 'Mutation' } & {
  workerEnable: { __typename: 'WorkerEnablePayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'>;
  };
};

export type WorkerUpdateProfileMutationVariables = Exact<{
  workerId: Scalars['ID'];
  firstName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  dateOfBirth: Scalars['ISO8601DateTime'];
  email: Maybe<Scalars['Email']>;
  gender: Maybe<GenderEnum>;
  heardFrom: Maybe<Scalars['String']>;
  language: Maybe<LanguageEnum>;
  ownsCar: Maybe<Scalars['Boolean']>;
  secondaryLanguage: Maybe<LanguageEnum>;
  smartphoneType: Maybe<SmartphoneTypeEnum>;
  hasSsn: Maybe<Scalars['Boolean']>;
}>;

export type WorkerUpdateProfileMutation = { __typename: 'Mutation' } & {
  workerUpdateProfile: { __typename: 'WorkerUpdateProfilePayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'>;
  };
};

export type UpdateInterviewStatusMutationVariables = Exact<{
  status: InterviewStatusEnum;
  workerInterviewId: Scalars['ID'];
}>;

export type UpdateInterviewStatusMutation = { __typename: 'Mutation' } & {
  workerInterviewUpdateV2: { __typename: 'WorkerInterviewUpdateV2Payload' } & {
    willoWorkerInterview: { __typename: 'WilloWorkerInterview' } & Pick<
      WilloWorkerInterview,
      'id'
    >;
  };
};

export type ChangeWorkerAvatarMutationVariables = Exact<{
  avatarKey: Scalars['String'];
  workerId: Scalars['ID'];
}>;

export type ChangeWorkerAvatarMutation = { __typename: 'Mutation' } & {
  workerChangeAvatar: { __typename: 'WorkerChangeAvatarPayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id' | 'avatarUrl'>;
  };
};

export type BlockWorkerMutationVariables = Exact<{
  active: Scalars['Boolean'];
  workerId: Scalars['ID'];
  reason: Scalars['String'];
}>;

export type BlockWorkerMutation = { __typename: 'Mutation' } & {
  workerChangeActive: { __typename: 'WorkerChangeActivePayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'>;
  };
};

export type AddWorkerReferenceMutationVariables = Exact<{
  city: Scalars['String'];
  duration: DurationEnum;
  email: Maybe<Scalars['Email']>;
  employerName: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  title: Scalars['String'];
  workerId: Scalars['ID'];
}>;

export type AddWorkerReferenceMutation = { __typename: 'Mutation' } & {
  workerAddReference: { __typename: 'WorkerAddReferencePayload' } & {
    reference: { __typename: 'WorkerReference' } & Pick<WorkerReference, 'id'>;
  };
};

export type CreateWorkerMutationVariables = Exact<{
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  agencyId: Scalars['ID'];
  city: Scalars['String'];
  coords: PointInput;
  email: Scalars['Email'];
  firstName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  dateOfBirth: Maybe<Scalars['ISO8601DateTime']>;
  gender: GenderEnum;
  language: LanguageEnum;
  lastName: Scalars['String'];
  ownsCar: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  secondaryLanguage: LanguageEnum;
  skillIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  state: Scalars['String'];
  zip: Scalars['String'];
}>;

export type CreateWorkerMutation = { __typename: 'Mutation' } & {
  workerCreate: { __typename: 'WorkerCreatePayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'>;
  };
};

export type UpdateApplicantMutationVariables = Exact<{
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  candidateStatus: CandidateStatusEnum;
  city: Maybe<Scalars['String']>;
  email: Scalars['Email'];
  firstName: Maybe<Scalars['String']>;
  middleName: Maybe<Scalars['String']>;
  language: LanguageEnum;
  lastName: Maybe<Scalars['String']>;
  ownsCar: Scalars['Boolean'];
  dateOfBirth: Maybe<Scalars['DateOrEmptyString']>;
  phoneNumber: Maybe<Scalars['String']>;
  secondaryLanguage: Maybe<LanguageEnum>;
  skillIds: Array<Scalars['ID']> | Scalars['ID'];
  smartphoneType: SmartphoneTypeEnum;
  state: Maybe<Scalars['String']>;
  taxType: TaxTypeEnum;
  workerId: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
  hasSsn: Maybe<Scalars['Boolean']>;
}>;

export type UpdateApplicantMutation = { __typename: 'Mutation' } & {
  workerUpdateApplicant: { __typename: 'WorkerUpdateApplicantPayload' } & {
    worker: { __typename: 'Worker' } & Pick<Worker, 'id'>;
  };
};

export type UpdateCandidateMutationVariables = Exact<{
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  candidateStatus: CandidateStatusEnum;
  city: Maybe<Scalars['String']>;
  email: Scalars['Email'];
  firstName: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  language: Maybe<LanguageEnum>;
  dateOfBirth: Maybe<Scalars['ISO8601DateTime']>;
  lastName: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  secondaryLanguage: Maybe<LanguageEnum>;
  smartphoneType: Maybe<SmartphoneTypeEnum>;
  state: Maybe<Scalars['String']>;
  workerId: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
}>;

export type UpdateCandidateMutation = { __typename: 'Mutation' } & {
  workerUpdateCandidate: { __typename: 'WorkerUpdateCandidatePayload' } & {
    worker: { __typename: 'Worker' } & Pick<
      Worker,
      'id' | 'candidateStatus'
    > & {
        user: { __typename: 'User' } & Pick<
          User,
          'firstName' | 'middleName' | 'lastName'
        >;
      };
  };
};

export type ListCandidatesQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<CandidateFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;

export type ListCandidatesQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      candidates: { __typename: 'WorkerPage' } & {
        items: Array<
          { __typename: 'Worker' } & Pick<
            Worker,
            | 'id'
            | 'avatarUrl'
            | 'active'
            | 'deactivatedAt'
            | 'deactivatedBy'
            | 'disableReason'
            | 'candidateStatus'
            | 'backgroundStatus'
            | 'onboardingStatus'
            | 'interviewStatus'
            | 'addressLine1'
            | 'addressLine2'
            | 'city'
            | 'state'
            | 'zip'
            | 'status'
            | 'hasSsn'
            | 'ownsCar'
            | 'taxType'
            | 'createdAt'
            | 'updatedAt'
          > & {
              deactivatedByUser: Maybe<
                { __typename: 'User' } & Pick<
                  User,
                  'firstName' | 'id' | 'lastName' | 'middleName'
                >
              >;
              coords: Maybe<
                { __typename: 'Point' } & Pick<Point, 'latitude' | 'longitude'>
              >;
              user: { __typename: 'User' } & Pick<
                User,
                | 'id'
                | 'email'
                | 'firstName'
                | 'middleName'
                | 'lastName'
                | 'dateOfBirth'
                | 'language'
                | 'phoneNumber'
                | 'secondaryLanguage'
                | 'smartphoneType'
              >;
              references: Array<
                { __typename: 'WorkerReference' } & Pick<
                  WorkerReference,
                  | 'id'
                  | 'email'
                  | 'name'
                  | 'city'
                  | 'employerName'
                  | 'phoneNumber'
                  | 'summary'
                  | 'duration'
                  | 'title'
                >
              >;
              skills: Array<
                { __typename: 'Skill' } & Pick<
                  Skill,
                  'id' | 'imageUrl' | 'name'
                >
              >;
              comments: Array<
                { __typename: 'Comment' } & Pick<Comment, 'body'>
              >;
            }
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type ListWorkersQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<WorkerFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;

export type ListWorkersQuery = { __typename: 'Query' } & {
  agency: { __typename: 'Agency' } & Pick<Agency, 'id'> & {
      workers: { __typename: 'WorkerPage' } & {
        items: Array<
          { __typename: 'Worker' } & Pick<
            Worker,
            | 'id'
            | 'candidateStatus'
            | 'active'
            | 'deactivatedAt'
            | 'deactivatedBy'
            | 'disableReason'
            | 'interviewStatus'
            | 'status'
            | 'avatarUrl'
            | 'city'
            | 'addressLine1'
            | 'gender'
            | 'state'
            | 'averageRating'
            | 'jobsRatingCount'
            | 'createdAt'
            | 'updatedAt'
          > & {
              deactivatedByUser: Maybe<
                { __typename: 'User' } & Pick<
                  User,
                  'firstName' | 'id' | 'lastName' | 'middleName'
                >
              >;
              skills: Array<
                { __typename: 'Skill' } & Pick<Skill, 'id' | 'name'>
              >;
              activeCertificates: Array<
                { __typename: 'Certificate' } & Pick<Certificate, 'id' | 'name'>
              >;
              user: { __typename: 'User' } & Pick<
                User,
                | 'id'
                | 'email'
                | 'firstName'
                | 'middleName'
                | 'lastName'
                | 'language'
                | 'phoneNumber'
              >;
            }
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          PageInfo,
          'totalItems' | 'totalPages'
        >;
      };
    };
};

export type GetCurrentActorQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentActorQuery = { __typename: 'Query' } & {
  currentActor:
    | ({ __typename: 'CustomerAdmin' } & CurrentCustomerAdminFragment)
    | ({ __typename: 'TenantAdmin' } & CurrentTenantAdminFragment)
    | { __typename: 'Worker' };
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['Email'];
  app: TypeAppEnum;
}>;

export type ForgotPasswordMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'userForgotPassword'
>;

export type AdminLoginMutationVariables = Exact<{
  tenantId: Scalars['ID'];
  email: Scalars['Email'];
  password: Scalars['String'];
}>;

export type AdminLoginMutation = { __typename: 'Mutation' } & {
  adminLogin: { __typename: 'AdminLoginPayload' } & Pick<
    AdminLoginPayload,
    'accessToken' | 'refreshToken' | 'resetPassword' | 'disabled'
  > & {
      tenantAdmin: Maybe<
        { __typename: 'TenantAdmin' } & CurrentTenantAdminFragment
      >;
      customerAdmin: Maybe<
        { __typename: 'CustomerAdmin' } & CurrentCustomerAdminFragment
      >;
    };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;

export type ResetPasswordMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'userResetPassword'
>;

export type UserSetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  repeatedPassword: Scalars['String'];
}>;

export type UserSetPasswordMutation = { __typename: 'Mutation' } & {
  userAuthResetPassword: { __typename: 'UserAuthResetPasswordPayload' } & {
    user: { __typename: 'User' } & Pick<
      User,
      'id' | 'firstName' | 'lastName' | 'email' | 'resetPassword'
    >;
  };
};

export type GetActiveRegionStatesQueryVariables = Exact<{
  tenantId: Scalars['ID'];
}>;

export type GetActiveRegionStatesQuery = { __typename: 'Query' } & Pick<
  Query,
  'activeRegionState'
>;

export type GetTenantByKeyQueryVariables = Exact<{
  key: Scalars['Key'];
}>;

export type GetTenantByKeyQuery = { __typename: 'Query' } & {
  tenantByKey: Maybe<{ __typename: 'Tenant' } & CurrentTenantFragment>;
};

export type CustomerAdminSignUpMutationVariables = Exact<{
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  agencyId: Scalars['ID'];
  city: Scalars['String'];
  coords: PointInput;
  customerName: Scalars['String'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  referral?: Maybe<ReferralEnum>;
  state: Scalars['String'];
  zip: Scalars['String'];
  neighborhood: Scalars['String'];
}>;

export type CustomerAdminSignUpMutation = { __typename: 'Mutation' } & {
  customerSignup: { __typename: 'CustomerSignupPayload' } & Pick<
    CustomerSignupPayload,
    'success'
  > & { customer: Maybe<{ __typename: 'Customer' } & Pick<Customer, 'id'>> };
};

export type CheckDuplicateCustomerNameQueryVariables = Exact<{
  clientName: Scalars['String'];
}>;

export type CheckDuplicateCustomerNameQuery = { __typename: 'Query' } & Pick<
  Query,
  'customerNameDuplicate'
>;

export type CheckExistingEmailQueryVariables = Exact<{
  email: Scalars['Email'];
}>;

export type CheckExistingEmailQuery = { __typename: 'Query' } & Pick<
  Query,
  'existingUser'
>;

export type UserResendTokenMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserResendTokenMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'userResendToken'
>;
