import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import {
  faEllipsisV,
  faEyeSlash,
  faTimesCircle,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import { parseISO, format, differenceInHours } from 'date-fns';
import qs from 'query-string';
import { ReactNode, useMemo, useState } from 'react';

import ShiftsCard from '../ShiftsCard';
import UpdateShiftModal from '../ShiftsCard/UpdateShiftDate';
import { JobStatus, getShiftIntervals } from '../util';

import {
  CalendarIcon,
  ClockIcon,
  EditJobIcon,
  MarkerIcon,
  ToolBoxIcon,
} from '@/assets/icons';
import Avatar from '@/components/Avatar';
import Stack from '@/components/Stack';
import Icon from '@/elements/Icon';
import { getTimeinHoursAndMinutes } from '@/hooks/useCountdown';
import useMediaQuery from '@/hooks/useMediaQuery';
import PermissionComponent from '@/routes/PermissionComponent';
import { Role } from '@/routes/PrivateRoute';
import styled from '@/styles';
import { GetJobQuery, JobStatusEnum, JobTypeEnum } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';
import { DAY_HOURS } from '@/util/constants';
import { isWithin24Hours } from '@/util/date';

const MIN_LIMIT_HOURS = DAY_HOURS;
const MAX_LIMIT_HOURS = DAY_HOURS * 2;

type Props = {
  job: GetJobQuery['job'];
  displayStatus: ReactNode[];
  jobStatus: string;
  isJobPublished: boolean;
  handlePublishClick: () => void;
  handleCloseClick: (job: any) => void;
};
const HeaderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  position: 'relative',
  background: 'linear-gradient(179.85deg, #EFFFED -6.41%, #FFFFFF 135.71%)',
  boxShadow: '0px 4px 14.6px -8px #00000040',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
  width: '100%',
  '@phoneOnly': {
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
  },
});

const ShiftTiming = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

const AvatarWrap = styled('div', {
  background:
    'radial-gradient(85.59% 85.59% at 50% 50%, #02AF95 0%, #144901 100%)',
  height: '60px',
  width: '60px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
});

const Item = styled('div', {
  position: 'relative',
  '@phoneOnly': {},
});

const HeadingNew = styled('h2', {
  color: '#0E1E40',
  fontSize: '28px',
  fontWeight: '700',
  lineHeight: 'normal',
  '@phoneOnly': {
    fontSize: '20px',
  },
});

const TimeComp = styled('p', {
  color: '#424242',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: 'normal',
  marginBottom: '9px',
  display: 'flex',

  '@phoneOnly': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
    fontWeight: 'normal',
    fontSize: '13px',
    color: '#000',
  },
});

const LocationHeading = styled('p', {
  color: '#52C241',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: 'normal',
  cursor: 'pointer',
  display: 'flex',
  '@phoneOnly': {
    fontSize: '13px',
    fontWeight: '500',
    color: '#000',
    alignItems: 'center',
  },
});

const NameHeading = styled('p', {
  color: '#000',
  fontSize: '14px',
  fontWeight: '500',
  display: 'flex',
  lineHeight: 'normal',
  '@phoneOnly': {
    fontSize: '13px',
    alignItems: 'center',
  },
});

const TimeChip = styled('span', {
  borderRadius: '100px',
  background: '#3171E1',
  whiteSpace: 'nowrap',
  padding: '4px 8px',
  marginRight: '8px',
  alignItems: 'center',
  gap: '2px',
  color: '#FFF',
  borderColor: '#3171E1',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: 'normal',
  '@phoneOnly': {
    fontSize: '11px',
    marginBottom: '8px',
  },
});

const IconButtonMenu = styled('button', {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  border: '1px solid #E7E8EA',
  background: 'white',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@phoneOnly': {
    background: 'transparent',
    border: 'none',
  },
});

const MenuList = styled('ul', {
  background: '#FFF',
  '@phoneOnly': {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
});

const MenuItemRight = styled('li', {
  padding: '8px 20px',
  fontSize: '14px',
  fontWeight: '400',
  cursor: 'pointer',
  '@phoneOnly': {
    color: '#262626',
    padding: '0',
    fontSize: '16px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    svg: {
      minWidth: '20px',
    },
  },
});

const DropdownWrapper = styled('div', {
  position: 'relative',
});

const HeaderDialog = styled('span', {
  padding: '10px 20px 15px 25px',
  background: 'linear-gradient(180deg, #EEFFEC 38.16%, #FFF 107.94%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleDialog = styled('span', {
  color: '#45A735',
  fontSize: '16px',
  fontWeight: '600',
});

const JobHeader = ({
  job,
  displayStatus,
  isJobPublished,
  jobStatus,
  handleCloseClick,
  handlePublishClick,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const { address } = job;
  const shiftIntervals = useMemo(
    () => getShiftIntervals(job.shifts),
    [job.shifts]
  );
  const currentAdmin = useReactiveVar(currentAdminVar);
  const isTenantAdmin = currentAdmin!.role === Role.TENANT_ADMIN;
  const { canEditSchedule, limitOfHoursOnSelectedDays } = useMemo(() => {
    const quantityOfJobsHired = job.jobWorkers.filter(
      (jobWorker) => jobWorker.status === 'HIRED'
    ).length;
    const limitOfHoursOnSelectedDays = isTenantAdmin
      ? 0
      : quantityOfJobsHired > 0
      ? MAX_LIMIT_HOURS
      : MIN_LIMIT_HOURS;

    const firstShiftStartAt = parseISO(job.firstShiftStartAt);
    const differenceHours = differenceInHours(firstShiftStartAt, new Date());
    const jobStatusIsUpComing = job.status === JobStatusEnum.UPCOMING;
    return {
      canEditSchedule: isTenantAdmin
        ? jobStatusIsUpComing && job.timesheets.length === 0
        : differenceHours >= DAY_HOURS && jobStatusIsUpComing,

      limitOfHoursOnSelectedDays,
    };
  }, [job]);

  const [anchorEl, setAnchorEl] = useState(null);
  const { account } = job;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseJob = () => {
    handleCloseModal();
    setAnchorEl(null);
    setTimeout(() => {
      handleCloseClick(job);
    }, 400);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showEditModal, hideEditModal] = useModal(
    () => (
      <UpdateShiftModal
        hideModal={hideEditModal}
        job={job}
        limitOfHours={limitOfHoursOnSelectedDays}
      />
    ),
    [job]
  );

  const getMinuteString = (hours, minutes) => {
    if (hours === 0 && minutes === 0) {
      return 'a minute';
    } else if (minutes === 0) {
      return '';
    }
    if (minutes < 10) {
      return `0${minutes}m`;
    }
    return `${minutes}m`;
  };

  const getTimer = () => {
    const startAtIso = parseISO(job.shifts[0].startAt);
    if (isWithin24Hours(startAtIso) && jobStatus !== JobStatus.CANCELLED) {
      const countDownTime = startAtIso.getTime() - new Date().getTime();
      const [hours, minutes] = getTimeinHoursAndMinutes(countDownTime);
      const Hours = hours === 0 ? '' : hours < 10 ? `0${hours}h` : `${hours}h`;

      return (
        <>
          <span style={{ display: 'flex', flexWrap: 'wrap' }}>
            <TimeChip>
              Starts in{' '}
              <strong>
                {' '}
                {Hours} {getMinuteString(hours, minutes)}
              </strong>
            </TimeChip>
            {displayStatus}
          </span>
        </>
      );
    } else {
      return <span>{displayStatus}</span>;
    }
  };

  const mapsParams = {
    query: `${address.addressLine1},${address.city},${address.state}`,
  };
  const mapsUrl = `https://www.google.com/maps/search/?api=1&${qs.stringify(
    mapsParams
  )}`;

  const onClickMap = () => {
    window.open(mapsUrl, '_blank', 'noreferrer');
  };

  const addressLine = `${address.addressLine1 ? address.addressLine1 : ''} ${
    address.addressLine2 ? address.addressLine2 : ''
  }`;

  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const GetMenu = () => {
    const css = {
      width: '14px',
      height: '14px',
      marginRight: phoneOnly ? '0px' : '15px',
    };
    return (
      <MenuList>
        {canEditSchedule && (
          <MenuItemRight>
            <EditJobIcon style={css} />
            <ShiftsCard
              handleEditWeb={() => {
                handleClose();
                setTimeout(() => {
                  showEditModal();
                }, 400);
              }}
              job={job}
              limitOfHoursOnSelectedDays={limitOfHoursOnSelectedDays}
            />
          </MenuItemRight>
        )}
        {job.jobType !== JobTypeEnum.LTA && (
          <MenuItemRight
            style={{ color: '#44A735' }}
            onClick={handlePublishClick}
          >
            <Icon css={css} icon={isJobPublished ? faEyeSlash : faEye} />
            Mark as {isJobPublished ? 'Private' : 'Public'}
          </MenuItemRight>
        )}
        {jobStatus !== JobStatus.IN_PROGRESS && (
          <MenuItemRight style={{ color: '#E94E46' }} onClick={handleCloseJob}>
            <Icon css={css} icon={faTimesCircle} />
            Cancel Job
          </MenuItemRight>
        )}
      </MenuList>
    );
  };

  const locationOnNewline =
    phoneOnly || (isTenantAdmin && account?.customer?.name?.length > 50);
  return (
    <>
      {phoneOnly ? (
        <HeaderWrapper>
          <Stack
            align="start"
            style={{ padding: '14px 27px 0 27px' }}
            vertical="true"
          >
            <Stack
              align="start"
              gap="20px"
              style={{
                padding: '0 0 13px',
                borderBottom: '1px solid #79C0A7',
              }}
            >
              <Stack gap="12px">
                <AvatarWrap>
                  <Avatar src={job.skill.imageUrl} />
                </AvatarWrap>
                <Item>
                  <Stack gap="6px" vertical="true">
                    <HeadingNew>{job.skill.name}</HeadingNew>
                    {getTimer()}
                  </Stack>
                </Item>
              </Stack>
              <DropdownWrapper>
                {jobStatus === JobStatus.CANCELLED ||
                jobStatus === JobStatus.COMPLETED ||
                (job.jobType === JobTypeEnum.LTA &&
                  jobStatus === JobStatus.IN_PROGRESS) ? (
                  ''
                ) : (
                  <PermissionComponent id="job-menu-button">
                    <Item>
                      <IconButtonMenu
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        id="job-menu-button"
                        onClick={handleOpenModal}
                      >
                        <Icon icon={faEllipsisV} style={{ color: '#808b9d' }} />
                      </IconButtonMenu>
                    </Item>
                  </PermissionComponent>
                )}
              </DropdownWrapper>
            </Stack>
            <Stack
              align="start"
              gap="0px"
              style={{ padding: '11px 4px 19px 4px' }}
              vertical="true"
            >
              {isTenantAdmin && (
                <Stack style={{ flexWrap: 'wrap', marginBottom: '8px' }}>
                  <NameHeading>
                    <ToolBoxIcon
                      style={{
                        marginRight: '8px',
                        width: '14px',
                        height: '16px',
                      }}
                    />
                    {`${account?.customer?.name}`}
                  </NameHeading>
                </Stack>
              )}
              <ShiftTiming>
                {shiftIntervals.map((interval, index) => (
                  <TimeComp
                    key={index}
                    style={{
                      flexDirection:
                        format(new Date(job.firstShiftStartAt), 'PP') ===
                        format(new Date(job.lastShiftEndAt), 'PP')
                          ? 'row'
                          : 'column',
                      gap:
                        format(new Date(job.firstShiftStartAt), 'PP') ===
                        format(new Date(job.lastShiftEndAt), 'PP')
                          ? '16px'
                          : '12px',
                      alignItems: 'flex-start',
                    }}
                  >
                    <span
                      style={{
                        color: '#000',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CalendarIcon
                        style={{
                          marginRight: '8px',
                          width: '14px',
                          height: '16px',
                        }}
                      />
                      {format(new Date(job.firstShiftStartAt), 'PP') ===
                      format(new Date(job.lastShiftEndAt), 'PP')
                        ? format(new Date(job.firstShiftStartAt), 'PP')
                        : `${format(
                            new Date(job.firstShiftStartAt),
                            'PP'
                          )} - ${format(new Date(job.lastShiftEndAt), 'PP')}`}
                    </span>

                    <span
                      style={{
                        color: '#000',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ClockIcon
                        style={{
                          marginRight: '8px',
                          width: '14px',
                          height: '16px',
                        }}
                      />
                      {format(interval[0].startAt, 'p')} -
                      {format(interval[interval.length - 1].endAt, 'p')}
                    </span>
                  </TimeComp>
                ))}
              </ShiftTiming>
              <Stack style={{ flexWrap: 'wrap' }}>
                <LocationHeading onClick={onClickMap}>
                  <MarkerIcon
                    style={{
                      marginRight: '8px',
                      width: '14px',
                      height: '16px',
                    }}
                  />
                  {addressLine} {address.city}, {address.state} {address.zip}
                </LocationHeading>
              </Stack>
            </Stack>
          </Stack>
        </HeaderWrapper>
      ) : (
        <HeaderWrapper>
          <Stack align="start" vertical="true">
            <Stack
              align="start"
              gap="20px"
              style={{
                padding: '10px 50px 10px 38px',
                borderBottom: '1px solid #79C0A7',
              }}
            >
              <Stack gap="12px">
                <AvatarWrap>
                  <Avatar src={job.skill.imageUrl} />
                </AvatarWrap>
                <Item>
                  <Stack gap="24px">
                    <HeadingNew>{job.skill.name}</HeadingNew>
                    {getTimer()}
                  </Stack>
                </Item>
              </Stack>
              <DropdownWrapper style={{ margin: 'auto' }}>
                {jobStatus === JobStatus.CANCELLED ||
                jobStatus === JobStatus.COMPLETED ||
                (job.jobType === JobTypeEnum.LTA &&
                  jobStatus === JobStatus.IN_PROGRESS) ? (
                  ''
                ) : (
                  <PermissionComponent id="job-menu-button">
                    <Item>
                      <IconButtonMenu
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        id="job-menu-button"
                        onClick={handleClick}
                      >
                        <Icon icon={faEllipsisV} style={{ color: '#808b9d' }} />
                      </IconButtonMenu>
                      <Menu
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          horizontal: 'right',
                          vertical: 'bottom',
                        }}
                        id="basic-menu"
                        open={open}
                        sx={{
                          border: '1px solid #ECECEC',
                          filter:
                            'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
                        }}
                        transformOrigin={{
                          horizontal: 'right',
                          vertical: 'top',
                        }}
                        onClose={handleClose}
                      >
                        <GetMenu />
                      </Menu>
                    </Item>
                  </PermissionComponent>
                )}
              </DropdownWrapper>
            </Stack>
            <Stack
              align="start"
              gap={locationOnNewline ? '10px' : '0px'}
              style={{ padding: '31px 40px 35px' }}
              vertical={locationOnNewline}
            >
              <Stack align="start" gap={'24px'} style={{ width: 'auto' }}>
                {isTenantAdmin && (
                  <NameHeading>
                    <ToolBoxIcon
                      style={{
                        marginRight: '8px',
                        width: '14px',
                        height: '16px',
                      }}
                    />
                    {account?.customer?.name}
                  </NameHeading>
                )}
                {shiftIntervals.map((interval, index) => (
                  <>
                    <TimeComp
                      key={index}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                      }}
                    >
                      <CalendarIcon
                        style={{
                          marginRight: '8px',
                          width: '14px',
                          height: '16px',
                        }}
                      />
                      {format(new Date(job.firstShiftStartAt), 'PP') ===
                      format(new Date(job.lastShiftEndAt), 'PP')
                        ? format(new Date(job.firstShiftStartAt), 'PP')
                        : `${format(
                            new Date(job.firstShiftStartAt),
                            'PP'
                          )} - ${format(new Date(job.lastShiftEndAt), 'PP')}`}
                    </TimeComp>
                    <TimeComp
                      key={index}
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      <ClockIcon
                        style={{
                          marginRight: '8px',
                          width: '14px',
                          height: '16px',
                        }}
                      />
                      {format(interval[0].startAt, 'p')} -
                      {format(interval[interval.length - 1].endAt, ' p')}
                    </TimeComp>
                  </>
                ))}
              </Stack>
              <Stack
                style={{
                  width: 'auto',
                  marginLeft: locationOnNewline ? '0px' : '20px',
                }}
              >
                <LocationHeading onClick={onClickMap}>
                  <MarkerIcon
                    style={{
                      marginRight: '8px',
                      width: '14px',
                      height: '16px',
                    }}
                  />
                  {addressLine} {address.city}, {address.state} {address.zip}
                </LocationHeading>
              </Stack>
            </Stack>
          </Stack>
        </HeaderWrapper>
      )}
      {modalOpen && (
        <Dialog
          PaperProps={{
            style: {
              height: 'fit-content',
              borderRadius: '15px',
            },
          }}
          aria-labelledby="options-dialog"
          fullScreen={true}
          open={true}
          sx={{
            '& .MuiDialog-container': {
              alignItems: 'end',
              marginBottom: '2px',
            },
          }}
          onClose={handleCloseModal}
        >
          <HeaderDialog>
            <TitleDialog>{job.skill.name}</TitleDialog>
            <IconButton aria-label="close" onClick={handleCloseModal}>
              <CloseOutlined />
            </IconButton>
          </HeaderDialog>
          <DialogContent style={{ padding: '25px 25px 55px' }}>
            <GetMenu />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default JobHeader;
