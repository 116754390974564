import { SubmitHelpers } from '@area2k/use-form';
import { useCallback, useState } from 'react';

import {
  getBodyAndTitleForCancelOrDismiss,
  getOptionValueForCancelOrDismiss,
} from '../../util';

import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import ItemSelect from '@/components/ItemSelect';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { ContextCancelRemoveModal } from '@/constants/job';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import Form from '@/form';
import TextAreaField from '@/form/TextAreaField';
import {
  useCancelJobMutation,
  useCancelOrderMutation,
  useJobRemoveWorkerMutation,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import { GetJobQuery, GetOrderQuery, Scalars } from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';

type JobWorkerItem = GetJobQuery['job']['jobWorkers'][0];

type Props = {
  job?: GetJobQuery['job'] | {};
  context: ContextCancelRemoveModal;
  hideModal: () => Scalars['Void'];
  jobWorkerId?: JobWorkerItem['id'];
  order?: GetOrderQuery['order'];
  updatedUi?: boolean;
};

type FormValues = {
  reason: Scalars['String'];
  otherReason: Scalars['String'];
};

const CancellationDissmissModal = ({
  hideModal,
  job,
  order,
  context,
  jobWorkerId,
  updatedUi,
}: Props) => {
  const { currentAdmin } = useAuth();
  const [formValues, setFormValues] = useState<FormValues>({
    reason: '',
    otherReason: '',
  });

  const content = getBodyAndTitleForCancelOrDismiss(context);
  const optionsValues = getOptionValueForCancelOrDismiss(context);
  const { title, body, otherValue, sizeModal, textButton } = content;

  const [cancelJob, { loading: cancelJobLoading }] = useCancelJobMutation();
  const [cancelOrder, { loading: cancelOrderLoading }] =
    useCancelOrderMutation();
  const [removeWorker, { loading: removeWorkerLoading }] =
    useJobRemoveWorkerMutation();

  const loading = cancelJobLoading || cancelOrderLoading || removeWorkerLoading;

  const errorMaps = {
    [ContextCancelRemoveModal.CancelJob]: {
      JOB_STARTED: () => ({
        title: 'Job already in progress',
        message: `Job #${
          job!.id
        } could not be cancelled because it is already in progress.`,
      }),
      REPORTED_CHECKIN: () => ({
        title: 'Job pending timesheet approval',
        message: `Job #${
          job!.id
        } could not be cancelled because it has a timesheet pending approval.`,
      }),
      all: () => ({
        title: 'An error has occurred',
        message: UNKNOWN_ERROR_TEXT,
      }),
    },
    [ContextCancelRemoveModal.CancelOrder]: {
      ORDER_STARTED: () => ({
        title: 'Order already in progress',
        message: `Order #${
          order!.id
        } could not be cancelled because it is already in progress.`,
      }),
      ORDER_COMPLETED: () => ({
        title: 'Order already completed',
        message: `Order #${
          order!.id
        } could not be cancelled because it is already completed.`,
      }),
      REPORTED_CHECKIN: () => ({
        title: 'Order pending timesheet approval',
        message: `Order #${
          order!.id
        } could not be cancelled because it has a timesheet pending approval.`,
      }),
      all: () => ({
        title: 'An error has occurred',
        message: UNKNOWN_ERROR_TEXT,
      }),
    },

    [ContextCancelRemoveModal.RemoveWorker]: {
      INACTIVE_WORKER: (gqlError) => ({
        title: gqlError.name,
        message: gqlError.message,
      }),
      JOB_ALREADY_STARTED: (gqlError) => ({
        title: 'An error has occurred',
        message: gqlError.message,
      }),
      ALREADY_CHECKED_IN: (gqlError) => ({
        title: 'An error has occurred',
        message: gqlError.message,
      }),
      all: () => ({
        title: 'An error has occurred',
        message: UNKNOWN_ERROR_TEXT,
      }),
    },
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        if (job) {
          if (context === ContextCancelRemoveModal.CancelJob) {
            await cancelJob({
              variables: {
                jobId: job.id,
                reason:
                  formValues.reason === otherValue
                    ? formValues.otherReason
                    : formValues.reason,
                userId: currentAdmin!.user.id,
              },
              update: (cache) => {
                cache.modify({
                  id: cache.identify(job),
                  fields: {
                    cancelledAt() {},
                    cancelUser() {},
                  },
                });
              },
            });
          } else if (
            context === ContextCancelRemoveModal.RemoveWorker &&
            jobWorkerId
          ) {
            await removeWorker({
              variables: {
                jobWorkerId,
                reason:
                  formValues.reason === otherValue
                    ? formValues.otherReason
                    : formValues.reason,
              },
              update: (cache) => {
                cache.modify({
                  id: cache.identify(job),
                  fields: {
                    jobWorkers() {},
                  },
                });
              },
            });
          }
        } else if (order) {
          await cancelOrder({
            variables: {
              orderId: order.id,
              reason: `Order #${order.id} cancelled due to: ${
                formValues.reason === otherValue
                  ? formValues.otherReason
                  : formValues.reason
              }`,
            },
            update: (cache) => {
              cache.modify({
                id: cache.identify(order),
                fields: {
                  cancelledAt() {},
                  jobs() {},
                },
              });
            },
          });
        }

        return hideModal();
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: errorMaps[context],
        });
      }
    },
    [formValues]
  );

  return (
    <Modal
      size={sizeModal}
      title={title}
      wrapperBackground={!!updatedUi}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Stack vertical>
          <Body>{body}</Body>
          <Form
            initialValues={formValues}
            style={{ width: '100%' }}
            onSubmit={handleSubmit}
          >
            <FormElement
              htmlFor={'reason-option-toggle-button'}
              label={'Reason'}
            >
              <ItemSelect
                required
                id={'reason-option'}
                itemToKey={(item) => item.id}
                itemToString={(item) => (item ? item.label : '')}
                items={optionsValues}
                placeholder={'Select an option'}
                selectedItem={
                  optionsValues.find(
                    (item) => item.label === formValues.reason
                  ) || null
                }
                onSelectedItemChange={({ selectedItem }) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    reason: selectedItem?.label || '',
                  }));
                }}
              />
            </FormElement>
            {formValues.reason === otherValue && (
              <TextAreaField
                autoFocus
                required
                callback={(value) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    otherReason: value.value,
                  }));
                }}
                fieldId={'otherReason'}
                placeholder="Additional comments box"
              />
            )}
            <Stack justify="end">
              {context === ContextCancelRemoveModal.RemoveWorker && (
                <Button
                  a11yLabel="deny"
                  appearance="outline"
                  label="Cancel"
                  status={updatedUi ? 'theme' : 'danger'}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    hideModal();
                  }}
                />
              )}
              <Button
                a11yLabel={'Dismiss Worker'}
                disabled={
                  formValues.reason === '' ||
                  (formValues.reason === otherValue &&
                    formValues.otherReason === '')
                }
                id="dismiss-worker-btn"
                isLoading={loading}
                label={textButton}
                type="submit"
              />
            </Stack>
          </Form>
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default CancellationDissmissModal;
